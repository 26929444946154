import React, { useCallback, useMemo } from 'react';
import { OrderPromptType, useOrders } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import DomEmptyOrder from './domEmptyOrder';
import DomOrder from './domOrder';
import classNames from 'classnames';
import { useSettings } from '@/contexts/SettingsContext';

interface OrderCellProps {
  price: number;
  orderType: OrderPromptType;
  canTrade: boolean;
}

const OrderCell: React.FC<OrderCellProps> = ({ price, orderType, canTrade }): JSX.Element => {
  const { orders, low, high, position, positionEntryPrice, highlightGroup, setHighlightGroup, linkedOrderMap } = useDom();
  const { changeOrderPrice, cancelOrder, cancelOrders } = useOrders();

  const isBracket = useMemo(() => {
    if (!position) return false;

    let show = false;
    const { stopLoss, takeProfit, positionSize } = position;

    if (stopLoss) {
      if (positionSize > 0) {
        show = price >= stopLoss && price <= positionEntryPrice;
      } else {
        show = price <= stopLoss && price >= positionEntryPrice;
      }
    }

    if (takeProfit) {
      if (positionSize > 0) {
        show ||= price >= positionEntryPrice && price <= takeProfit;
      } else {
        show ||= price <= positionEntryPrice && price >= takeProfit;
      }
    }

    return show;
  }, [position, price, positionEntryPrice]);

  const isTopBracket = useMemo(() =>
    isBracket && price >= positionEntryPrice,
  [isBracket, price, positionEntryPrice]);

  const isBottomBracket = useMemo(() =>
    isBracket && price <= positionEntryPrice,
  [isBracket, price, positionEntryPrice]);

  const showBracketType = useMemo(() => {
    if (!isBracket || !position) return '';

    const { positionSize } = position;

    if (positionSize < 0 && orderType === OrderPromptType.Buy) {
      if (price < positionEntryPrice) {
        return [styles.leftBracketLine, styles.greenLine];
      } else if (price === positionEntryPrice) {
        return position?.stopLoss
          ? [styles.leftBracketLine, styles.redLine]
          : [styles.leftBracketLine, styles.greenLine];
      } else {
        return [styles.leftBracketLine, styles.redLine];
      }
    } else if (positionSize > 0 && orderType === OrderPromptType.Sell) {
      if (price > positionEntryPrice) {
        return [styles.rightBracketLine, styles.greenLine];
      } else if (price === positionEntryPrice) {
        return position?.takeProfit
          ? [styles.rightBracketLine, styles.greenLine]
          : [styles.rightBracketLine, styles.redLine];
      } else {
        return [styles.rightBracketLine, styles.redLine];
      }
    }

    return '';
  }, [isBracket, position, orderType, price, positionEntryPrice]);

  const priceOrders = useMemo(() =>
    orders.get(price) || [],
  [orders, price]);

  const { buyOrders, sellOrders, orderGroupId } = useMemo(() => {
    const buyOrders = priceOrders.filter(x => x.positionSize > 0);
    const sellOrders = priceOrders.filter(x => x.positionSize < 0);

    const orderGroupIds = priceOrders
      .map(y => linkedOrderMap.get(y.id) || null)
      .filter(y => y != null);

    const orderGroupId = orderGroupIds.length > 0 ? orderGroupIds[0] : null;

    return { buyOrders, sellOrders, orderGroupId };
  }, [priceOrders, linkedOrderMap]);

  const isSltp = useMemo(() => {
    if (!position) return false;
    const { stopLossOrderId, takeProfitOrderId } = position;
    const relevantOrders = orderType === OrderPromptType.Buy ? buyOrders : sellOrders;

    return relevantOrders.some(order =>
      order.id === stopLossOrderId || order.id === takeProfitOrderId
    );
  }, [position, buyOrders, sellOrders, orderType]);

  const order = useMemo(() => {
    if (orderType === OrderPromptType.Buy) {
      if (buyOrders.length === 0) {
        return (
          <DomEmptyOrder
            changeOrderPrice={changeOrderPrice}
            type={orderType}
            price={price}
            high={high}
            low={low}
          />
        );
      }

      return (
        <DomOrder
          orderGroupId={orderGroupId}
          isHighlight={!!orderGroupId && orderGroupId === highlightGroup}
          setHighlightId={setHighlightGroup}
          canTrade={canTrade}
          leftSide={true}
          cancelOrders={cancelOrders}
          orders={buyOrders}
          price={price}
          high={high}
          low={low}
          isSltp={isSltp}
          changeOrderPrice={changeOrderPrice}
          positionEntryPrice={positionEntryPrice}
          type={orderType}
        />
      );
    } else {
      if (sellOrders.length === 0) {
        return (
          <DomEmptyOrder
            changeOrderPrice={changeOrderPrice}
            type={orderType}
            price={price}
            high={high}
            low={low}
          />
        );
      }

      return (
        <DomOrder
          orderGroupId={orderGroupId}
          isHighlight={!!orderGroupId && orderGroupId === highlightGroup}
          setHighlightId={setHighlightGroup}
          canTrade={canTrade}
          leftSide={false}
          cancelOrders={cancelOrders}
          orders={sellOrders}
          price={price}
          high={high}
          low={low}
          isSltp={isSltp}
          changeOrderPrice={changeOrderPrice}
          positionEntryPrice={positionEntryPrice}
          type={orderType}
        />
      );
    }
  }, [
    orderType,
    buyOrders,
    sellOrders,
    orderGroupId,
    highlightGroup,
    canTrade,
    cancelOrders,
    price,
    high,
    low,
    isSltp,
    changeOrderPrice,
    positionEntryPrice,
    setHighlightGroup
  ]);

  return (
    <div className={classNames(
      showBracketType,
      isTopBracket && styles.topBracket,
      isBottomBracket && styles.bottomBracket
    )}>
      {order}
    </div>
  );
};

export default React.memo(OrderCell);
