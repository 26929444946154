import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragType, useDragDrop } from '@/contexts/DragDropContext';
import { OrderPromptType } from '@/contexts/OrdersContext';
import styles from './dom.module.scss';
import { OrderModel } from '@/api/userApi';

interface DomEmptyOrderProps {
  price: number;
  high: number;
  low: number;
  changeOrderPrice: (id: number, price: number) => Promise<boolean>;
  sellBracket?: boolean;
  buyBracket?: boolean;
  type: OrderPromptType;
}

const DomEmptyOrder: React.FC<DomEmptyOrderProps> = ({ price, high, low, type, changeOrderPrice }): JSX.Element => {
  const { dragData, dragType, setDragData } = useDragDrop();
  const [allowDrop, setAllowDrop] = useState(false);

  const isLod = useMemo(() => {
    return price == low;
  }, [price, low]);
  const isHod = useMemo(() => {
    return price == high;
  }, [price, high]);

  useEffect(() => {
    if (allowDrop && dragType == DragType.None) {
      setAllowDrop(false);
    }
  }, [dragType, allowDrop]);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setAllowDrop(true);
      }
    },
    [dragType]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      setAllowDrop(false);
    },
    [dragType]
  );

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }
    },
    [price, dragType]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      const data = dragData as OrderModel[];
      for (const o of data) {
        const res = await changeOrderPrice(o.id, price);
      }
      setDragData();
    },
    [price, dragData]
  );

  return (
    <div onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
      <div
        className={styles.domOrderEntryBox}
        style={{
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: allowDrop ? 'green' : 'transparent',
          width: '100%',
          height: '100%',
          zIndex: 1000
        }}
      >
        &nbsp;
      </div>
      {isLod && type == OrderPromptType.Sell && <div className={styles.redHeader}>LOD</div>}
      {isHod && type == OrderPromptType.Buy && <div className={styles.greenHeader}>HOD</div>}
    </div>
  );
};

export default React.memo(DomEmptyOrder);
