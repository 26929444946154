import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    Button,
  } from "@mui/material";
  import React, { useEffect, useMemo, useState } from "react";
  import Modal, { ModalAction } from "./modal";
  import { ButtonType } from "./button";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import advancedFormat from "dayjs/plugin/advancedFormat";
  import { LocalizationProvider } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { IExtendedTradingAccountModel } from "@/contexts/TradingAccountContext";
  import { useApi } from "@/contexts/ApiContext";
  import { PersonalLockoutModel } from "@/api/userApi";
  import { toast } from "react-toastify";
  import TimePicker, { PickedTime } from "./timePicker";
  import { StyledButton } from "../styledComponents";
  import { useTradingAccount } from "@/contexts/TradingAccountContext";
  import accountBalances from "@/views/home/accountBalances/accountBalances";
import CountdownTimer from "./countdown";
import { getCurrentTime } from "@/helpers/Utils";
import { getCurrentMarketTime, getCurrentTradeDay, getCurrentTradeDayStart, marketCloseHour, marketOpenHour } from "@/helpers/timeHelper";
import { start } from "repl";

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(advancedFormat);

  // Get user's local time zone
  const userTimeZone = dayjs.tz.guess();

  type SessionOption = {
    label: string;
    startTime: dayjs.Dayjs; // Start time as a dayjs object in UTC
    endTime: dayjs.Dayjs; // End time as a dayjs object in UTC
    selected: boolean;
    disabled: boolean;
    desc: string;
  };

  type PersonalLockoutModalProps = {
    // account: IExtendedTradingAccountModel;
    onConfirm: () => void;
    onCancel: () => void;
    mini? : boolean;
  };

  const PersonalLockoutModal = (props: PersonalLockoutModalProps) => {
    const { activeTradingAccount } = useTradingAccount();
    useEffect(() => {
      lockoutApi.getAll(activeTradingAccount.accountId).then((result) => {
        console.log(result);

        if (!result || result.length === 0) {
          return;
        }

        // check if standard option
        const standard = getStandardSession(result[0].startsAt, result[0].expiresAt);

        if (standard) {
          // set to selected
          setSessionOptions(
            sessionOptions.map((option) => {
              if (
                result.find(
                  (data) =>
                    data.startsAt.isSame(option.startTime) &&
                    data.expiresAt.isSame(option.endTime)
                )
              ) {
                return { ...option, selected: true };
              }
              return option;
            })
          );

          setCustomTimeSelected(false);
          setCustomEndTime(null);
        } else {
          setSessionOptions(sessionOptions.map((option) => ({ ...option, selected: false })));

          // set custom time
          setCustomTimeSelected(true);
          setCustomEndTime(result[0].expiresAt);
        }
      });
    }, [activeTradingAccount]);

    const now = () => dayjs();
    const nowCT = () => now().tz("America/Chicago");

    // First get current time in CT
    const currentTimeCT = nowCT();

    // Create today's cutoff time in CT (4 PM)
    const todayCutoff = currentTimeCT.hour(16).minute(0).second(0).millisecond(0);

    // Check if current time is before today's cutoff
    const beforeTodayCutoff = currentTimeCT.isBefore(todayCutoff);

    // Tomorrow's cutoff is simply today's cutoff + 1 day
    const tomorrowCutoff = todayCutoff.add(1, 'day');

    // Use the appropriate cutoff time
    const cutoffTime = beforeTodayCutoff ? todayCutoff : tomorrowCutoff;

    const getStandardSession = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
      return sessionOptions.find((session) => {
        return session.startTime.isSame(start) && session.endTime.isSame(end);
      });
    };

    const getSessionTimes = () => {
      const sessions = [
        {
          label: "New York Session",
          desc: "(7am-4pm CT)",
          startHourUTC: 13, // 8:00 AM ET is 13:00 PM UTC
          endHourUTC: 22, // 5:00 PM ET is 22:00 UTC
          startHour: 7,
          endHour: 16,
          startAfterCutoff: false,
          endAfterCutoff: false,
          enabledUtcHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23]
        },
        {
          label: "London Session",
          desc: "(2am-11am CT)",
          startHour: 2,
          endHour: 11,
          startHourUTC: 8, // 3:00 AM ET is 8:00 AM UTC
          endHourUTC: 17, // 12:00 PM ET is 17:00 UTC
          startAfterCutoff: false,
          endAfterCutoff: false,
          enabledUtcHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24]
        },
        {
          label: "Tokyo Session",
          desc: "(6pm-3am CT)",
          startHourUTC: 0, // 7:00 PM ET is 00:00 UTC
          endHourUTC: 9, // 4:00 AM ET is 9:00 UTC
          startHour: 18,
          endHour: 3,
          startAfterCutoff: true,
          endAfterCutoff: true,
          enabledUtcHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 23, 24]
        },
        {
          label: "Sydney Session",
          desc: "(4pm-1am CT)",
          startHourUTC: 22, // 5:00 PM ET is 21:00 UTC
          endHourUTC: 7, // 2:00 AM ET is 6:00 UTC (next day)
          startHour: 16,
          endHour: 1,
          startAfterCutoff: true,
          endAfterCutoff: true,
          enabledUtcHours: [0, 1, 2, 3, 4, 5, 6, 23, 24]
        },
      ];

      return sessions.map((session) => {

        let startTime: dayjs.Dayjs;
        let endTime: dayjs.Dayjs;

        var currentMarketTime = getCurrentMarketTime();

        if(session.startHour >= marketCloseHour) {
          //if session starts at 19:00 for example, we would need to subtract 5 hours from the trade day (which is at midnight of next day)
          const startHour = 24 - session.startHour;
          startTime = getCurrentTradeDay().add(-startHour, 'hour');
        } else {
          startTime = getCurrentTradeDay().add(session.startHour, 'hour');
        }

        if(session.endHour > marketCloseHour) {
          //if session ends at 19:00 for example, we would need to subtract 5 hours from the trade day (which is at midnight of next day)
          const endHour = 24 - session.endHour;
          endTime = getCurrentTradeDay().add(-endHour, 'hour');
        } else {
          endTime = getCurrentTradeDay().add(session.endHour, 'hour');
        }

        const disabled = endTime < currentMarketTime;

        if(startTime < currentMarketTime) {
          startTime = currentMarketTime;
        }

        return {
          label: session.label,
          desc: session.desc,
          startTime: startTime,
          endTime: endTime,
          selected: false,
          disabled,
        } as SessionOption;
      });
    };

    const [sessionOptions, setSessionOptions] = useState<SessionOption[]>(getSessionTimes());

    const [customTimeSelected, setCustomTimeSelected] = useState(false);
    const [customEndTime, setCustomEndTime] = useState<dayjs.Dayjs | null>(null);
    const [error, setError] = useState("");

    const [quickOptionSelected, setQuickOptionSelected] = useState<string | null>(null);

    const { lockoutApi } = useApi();

    const handleCustomTimeSelect = (time: PickedTime) => {

        let selectedTime = now().hour(time.hour).minute(time.minute).second(0).millisecond(0);
        if (selectedTime.isBefore(now())) {
          selectedTime = selectedTime.add(1, "day");
        }

        setCustomEndTime(selectedTime);
    }

    const handleSelectSession = (index: number) => {
      const updatedOptions = sessionOptions.map((option, idx) => ({
        ...option,
        selected: idx === index ? !option.selected : option.selected,
      }));
      setSessionOptions(updatedOptions);
      setCustomTimeSelected(false);
      setQuickOptionSelected(null);
    };

    const handleSelectCustomTime = () => {
      setCustomTimeSelected(!customTimeSelected);

      setSessionOptions(sessionOptions.map((option) => ({ ...option, selected: false })));
      setQuickOptionSelected(null);

      // Set custom time to now if not already set
      if (customTimeSelected) {
        setCustomEndTime(now());
      }
    };

    const handleQuickOptionSelect = (option: string) => {
      setQuickOptionSelected(option === quickOptionSelected ? null : option);
      setCustomTimeSelected(false);
      setSessionOptions(sessionOptions.map((opt) => ({ ...opt, selected: false })));
    };

    const calculateMaxEndTime = () => {
      const time = cutoffTime.isAfter(nowCT()) ? cutoffTime : cutoffTime.add(1, "day");
      console.log(time);
      return time;
    };

    const handleConfirm = () => {
      const selectedTimes = sessionOptions
        .filter((option) => option.selected && !option.disabled)
        .map((option) => ({
          start: option.startTime,
          end: option.endTime,
        }));

      if (quickOptionSelected) {
        let calculatedEndTimeCT = nowCT();

        if (quickOptionSelected === "15") {
          calculatedEndTimeCT = calculatedEndTimeCT.add(15, "minute");
        } else if (quickOptionSelected === "30") {
          calculatedEndTimeCT = calculatedEndTimeCT.add(30, "minute");
        } else if (quickOptionSelected === "60") {
          calculatedEndTimeCT = calculatedEndTimeCT.add(60, "minute");
        } else if (quickOptionSelected === "allDay") {
          // Calculate next 4:40 PM CST
          if (beforeTodayCutoff) {
            calculatedEndTimeCT = todayCutoff.add(40, 'minute');
          } else {
            calculatedEndTimeCT = tomorrowCutoff.add(40, 'minute');
          }
        }

        selectedTimes.push({
          start: nowCT(),
          end: calculatedEndTimeCT,
        });
      }

      if (customTimeSelected && customEndTime) {
        if (customEndTime.isBefore(now())) {
          setError("Custom end time must be in the future.");
          return;
        }

        // Convert custom end time to UTC
        const customEndTimeCT = customEndTime.tz("America/Chicago");

        //if (customEndTimeUTC.isAfter(cutoffTimeUTC)) {
        //  setError("Custom end time must be before the trading day cutoff (22:00 UTC).");
        //  return;
        //}

        selectedTimes.push({
          start: nowCT(),
          end: customEndTimeCT,
        });
      }

      if (selectedTimes.length === 0) {
        setError("Please select at least one valid session or a custom end time.");
        return;
      }

      const promise = new Promise<void>((resolve, reject) => {
        lockoutApi
          .add(
            selectedTimes.map(
              (time) =>
                ({
                  tradingAccountId: activeTradingAccount.accountId,
                  userId: activeTradingAccount.userId,
                  createdAt: nowCT(),
                  startsAt: time.start,
                  expiresAt: time.end,
                } as PersonalLockoutModel)
            )
          )
          .then((result) => {
            if (!result.success) {
              reject(result.message);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

      toast.promise(promise, {
        pending: "Setting lockout...",
        success: "Lockout applied",
        error: {
          render({ data }) {
            return <span>{data.toString()}</span>;
          },
        },
      });

      props.onConfirm();
    };

    const actions: ModalAction[] = [
      { label: "Cancel", onClick: props.onCancel },
      {
        label: "Yes, lock me out",
        props: {
          buttonType: ButtonType.red,
          disabled:
            sessionOptions.every((opt) => !opt.selected) &&
            !customTimeSelected &&
            !quickOptionSelected,
        },
        onClick: handleConfirm,
      },
    ];

    const header = useMemo(
      () => (
        <Typography variant="h4" style={{ color: "#fff" }}>
          Lockout Account: {activeTradingAccount.accountName}
        </Typography>
      ),
      [activeTradingAccount]
    );

    const quickOptions = () => {
        return (
            <Card variant="outlined" style={{ marginTop: 5, backgroundColor: props.mini ? 'transparent' : undefined, borderWidth: props.mini ? 0 : undefined }}>
              <CardContent sx={{ padding: "1em", paddingBottom: "1em !important" }}>
                <Button
                  variant="outlined"
                  onClick={handleSelectCustomTime}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  {customTimeSelected ? "Quick Options" : "Custom"}
                </Button>

                {!customTimeSelected ? (
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant={quickOptionSelected === "15" ? "contained" : "outlined"}
                      onClick={() => handleQuickOptionSelect("15")}
                      sx={{ borderRadius: "4px 0 0 4px", flex: 1 }}
                    >
                      15 Min
                    </Button>
                    <Button
                      variant={quickOptionSelected === "30" ? "contained" : "outlined"}
                      onClick={() => handleQuickOptionSelect("30")}
                      sx={{ flex: 1, borderRadius: 0 }}
                    >
                      30 Min
                    </Button>
                    <Button
                      variant={quickOptionSelected === "60" ? "contained" : "outlined"}
                      onClick={() => handleQuickOptionSelect("60")}
                      sx={{ flex: 1, borderRadius: 0 }}
                    >
                      1 Hour
                    </Button>
                    <Button
                      variant={quickOptionSelected === "allDay" ? "contained" : "outlined"}
                      onClick={() => handleQuickOptionSelect("allDay")}
                      sx={{ borderRadius: "0 4px 4px 0", flex: 1 }}
                    >
                      All Day
                    </Button>
                  </Box>
                ) : (
                  <Box mt={2}>
                    <TimePicker
                      startTime={customEndTime ? customEndTime : now()}
                      onChange={handleCustomTimeSelect}
                    />
                    {customEndTime && (
                      <div style={{textAlign: 'center', paddingTop: '1em'}}>
                        <CountdownTimer targetDate={customEndTime} />
                        {customEndTime.isAfter(now().endOf('day')) ? <p style={{color: 'yellow'}}>Selected end time is in next day</p> : null}
                      </div>
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>
        )
    };

    if (activeTradingAccount.lockoutExpiration) {
      return <Typography variant="body1">This account is already locked out.</Typography>;
    }

    if (props.mini) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {quickOptions()}
                {error && (
                    <Typography color="error" variant="body2" mt={2}>
                        {error}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '1em', justifyContent: 'center' }}>
                    <StyledButton color='neutral' onClick={props.onCancel} style={{ marginRight: '1em' }}>
                        No, Cancel
                    </StyledButton>
                    <StyledButton
                        disabled={quickOptionSelected === null && !customTimeSelected}
                        color='error'
                        onClick={() => {
                            handleConfirm()
                            props.onConfirm()
                        }}
                    >
                        YES, LOCK ME OUT
                    </StyledButton>
                </Box>
            </LocalizationProvider>
        );
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal actions={actions} header={header}>
          <Box>
            <Typography variant="h6" style={{ color: "#fff", marginBottom: "1em" }}>
              Session Timeframes:
            </Typography>
            {sessionOptions.map((option, index) => (
              <Card key={option.label} variant="outlined" style={{ marginBottom: 8 }}>
                <CardContent sx={{ padding: "1em", paddingBottom: "1em !important" }}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={option.selected}
                        onChange={() => handleSelectSession(index)}
                        disabled={option.disabled}
                      />
                    }
                    label={`${option.label} ${option.disabled ? "(Unavailable)" : ""}`}
                  />
                  <Typography
                    sx={{ marginLeft: "3em" }}
                    fontSize={12}
                    variant="body2"
                    color="textSecondary"
                  >
                    {option.desc}
                  </Typography>
                </CardContent>
              </Card>
            ))}

            {quickOptions()}

            {error && (
              <Typography color="error" variant="body2" mt={2}>
                {error}
              </Typography>
            )}
          </Box>
        </Modal>
      </LocalizationProvider>
    );
  };

  export default PersonalLockoutModal;
