import { OrderType, SymbolMetadata, UserContractModel } from '@/api/userApi';
import { OrderPromptType } from '@/contexts/OrdersContext';
import { useCallback, useMemo } from 'react';
import Modal, { ModalAction } from './modal';
import { ButtonType } from './button';
import { useModal } from '@/contexts/ModalContext';
import styles from './confirmOrderModal.module.scss';
import Heading from './heading';
import { orderTypeMap } from '@/data/enumTypeMaps';
import React from 'react';
import { useSymbol } from '@/contexts/SymbolContext';
import { formatContractPrice } from '@/helpers/formatter';

type ConfirmCancelOpenProps = {
  type?: OrderType;
  side?: OrderPromptType;
  onConfirm: () => void;
  orderAmount?: number;
  contract: UserContractModel | undefined;
  limitPrice?: number | undefined;
  stopPrice?: number | undefined;
  trailDistance?: number | undefined;
  numOrders?: number;
};

const ConfirmCancelOrderModal = ({ numOrders, orderAmount, contract, type, side, limitPrice, stopPrice, trailDistance, onConfirm }: ConfirmCancelOpenProps) => {
  const { hideModal } = useModal();

  const isSell = side == OrderPromptType.Sell;
  const actions: ModalAction[] = [
    {
      label: 'Cancel',
      props: { buttonType: ButtonType.outline, className: styles.modalButton },
      onClick: hideModal
    },
    {
      label: numOrders > 1 ? `Cancel orders` : `Cancel Order`,
      props: {
        buttonType: isSell ? ButtonType.red : ButtonType.green,
        className: styles.modalButton
      },
      onClick: () => {
        hideModal();
        onConfirm();
      }
    }
  ];

  const getPriceText = useCallback(() => {
    if (type === OrderType.Market) {
      return 'at market price';
    } else if (type === OrderType.Limit) {
      return 'at limit price ' + formatContractPrice(limitPrice, contract);
    } else if (type === OrderType.TrailingStop) {
      return 'at ' + trailDistance + ' ticks';
    } else if (type === OrderType.Stop) {
      return 'at ' + formatContractPrice(stopPrice, contract);
    }
  }, [contract]);

  return (
    <Modal header={<Heading className={isSell ? styles.redHeader : styles.greenHeader}>Confirm Cancel Order</Heading>} actions={actions}>
      {!numOrders || (numOrders < 2) ? (
        <div>
          I want to cancel my {isSell ? '-' : '+'}
          {Math.abs(orderAmount)} {orderTypeMap[type]} order{' '}
          <span className={styles.bold}>
            {contract.productName} ({contract.contractName})
          </span>{' '}
          {getPriceText()}
        </div>
      ) : (
        <div>
          I want to cancel my {numOrders} orders (total 
          {Math.abs(orderAmount)}) {orderTypeMap[type]}{' '}
          <span className={styles.bold}>
            {contract.productName} ({contract.contractName})
          </span>{' '}
          {getPriceText()}
        </div>
      )}
    </Modal>
  );
};

export default ConfirmCancelOrderModal;
