import { LayoutBase } from 'rc-dock';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useApi } from './ApiContext';
import { IUserSettingsModel, JsonDocument, RootElement, UserSettingsModel } from '../api/userApi';
import { networkSpeed, chartPlotSide, domFontSize, domCellPadding, topNavTextSize, salesFontSize } from 'src/data/enumTypeMaps';
import { Hotkeys } from '@/components/topstep/hotkeySettings';
import { handleAxiosErrorWithCallback } from '@/helpers/axiosHelper';
export interface LayoutInfo {
  name: string;
  layout: LayoutBase;
}

export enum ChartDisplayType {
  Dollar,
  Tick,
  Percent,
  Point
}

export enum DomLayoutType {
  Modern,
  Classic
}

export let Settings: ISettingsContext = {
  risk: 0,
  toMake: 0,
  autoCenter: false,
  showConfirmations: false,
  autoApply: false,
  soundNotifications: true,
  customSettings: {},
  saveCustomSettings: (customSettings: Partial<CustomSettings>) => {},
  save: (settings: IUserSettingsModel) => Promise.resolve(false)
};

export interface ISettingsContext {
  risk: number | null;
  toMake: number | null;
  autoCenter: boolean | null;
  showConfirmations: boolean | null;
  showConfirmationsRef: React.MutableRefObject<boolean | null>;
  autoApply: boolean | null;
  soundNotifications: boolean | null;
  customSettings: CustomSettings | null;
  saveCustomSettings: (customSettings: Partial<CustomSettings>) => void;
  save(settings: IUserSettingsModel): Promise<boolean>;
}

export const SettingsContext = React.createContext<ISettingsContext>({} as any);
export const useSettings = () => React.useContext<ISettingsContext>(SettingsContext);

//Update this interface with all the custom settings you wish to use
export interface CustomSettings {
  someColor?: string;
  executionColor?: string;
  defaultOrderType?: string;
  defaultOrderQty?: number;
  volumeFilter?: number;
  networkSpeedNew?: networkSpeed;
  whiteLink?: string;
  redLink?: string;
  blueLink?: string;
  yellowLink?: string;
  purpleLink?: string;
  orangeLink?: string;
  supressAlerts?: boolean | null;
  audioAlerts?: boolean | null;
  hidePositionPlots?: boolean | null;
  hideEconomicEvents?: boolean | null;
  positionPlotsSide?: chartPlotSide;
  streamerModeBalance?: boolean | null;
  streamerModeMLL?: boolean | undefined;
  streamerModeRPL?: boolean | undefined;
  streamerModeUPL?: boolean | undefined;
  streamerModeDLL?: boolean | undefined;
  streamerModeAccountName?: boolean | undefined;
  domHeaderVolProfileHide?: boolean | null;
  domHeaderJoinButtonHide?: boolean | null;
  domHeaderClosePositionButtonHide?: boolean | null;
  domHeaderReversePositionButtonHide?: boolean | null;
  domHeaderCancelOrdersButtonHide?: boolean | null;
  domHeaderCancelAllButtonHide?: boolean | null;
  domHeaderFlattenAllButtonHide?: boolean | null;
  domHeaderBuySellButtonHide?: boolean | null;
  domHeaderShowMyBidAlways?: boolean | null;
  domHeaderShowMyAskAlways?: boolean | null;
  domHeaderHidePNLColumn?: boolean | null;
  hideNonFavorites?: boolean | null;
  liquidateOnly?: boolean | null;
  liquidateAndBlockTrading?: boolean | null;
  domFontSize?: domFontSize;
  domLayoutType?: DomLayoutType;
  domCellPadding?: domCellPadding;
  addedChartLink?: boolean;
  topNavTextSize?: topNavTextSize;
  salesFontSize?: salesFontSize;
  shownIntercom?: boolean;
  chartDisplayType?: ChartDisplayType;
  showChartExecutions?: boolean;
  favoriteContracts?: string[];
  domBidBGColor?: string;
  domAskBGColor?: string;
  domBidBGColorFriendly?: string;
  domAskBGColorFriendly?: string;
  hotkeyBindings?: Hotkeys;
  audioPack?: string;
  useDistanceMLL?: boolean | null;
  RPnLPositiveColor?: string;
  RPnLNegativeColor?: string;
  UPnLPositiveColor?: string;
  UPnLNegativeColor?: string;
  confirmAccountChange?: boolean | null;
}

function SettingsContextProvider({ children }: any) {
  const { userSettingsApi, retryApiCall } = useApi();
  const [risk, setRisk] = useState<number | null>(0);
  const [toMake, setToMake] = useState<number | null>(0);
  const [autoCenter, setAutoCenter] = useState<boolean | null>(false);
  const [showConfirmations, setShowConfirmations] = useState<boolean | null>(false);
  const showConfirmationsRef = useRef<boolean | null>(false);
  const [autoApply, setAutoApply] = useState<boolean | null>(false);
  const [liquidateOnly, setLiquidateOnly] = useState<boolean | null>(false);
  const [liquidateAndBlockTrading, setLiquidateAndBlockTrading] = useState<boolean | null>(false);
  const [soundNotifications, setSoundNotifications] = useState<boolean | null>(true);
  const [customSettings, setCustomSettings] = useState<CustomSettings>({});

  const componentDisposedSignal = useRef(new AbortController());
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    retryApiCall(() => userSettingsApi.get(), componentDisposedSignal.current).then((res) => {
      //ignore API call if component is disposed
      if (componentDisposedSignal.current.signal.aborted) return;

      setRisk(res.risk);
      setToMake(res.toMake);
      setAutoCenter(res.autoCenter);
      setShowConfirmations(res.showConfirmations);
      showConfirmationsRef.current = res.showConfirmations;
      setAutoApply(res.autoApply);
      // setLiquidateOnly(res.liquidateOnly);
      // setLiquidateAndBlockTrading(res.liquidateAndBlockTrading);
      setSoundNotifications(res.soundNotifications);
      setCustomSettings((res.custom?.rootElement as CustomSettings) || {});
    });

    return () => {
      componentDisposedSignal.current.abort();
    };
  }, []);

  const saveCustomSettings = useCallback(
    (newSettings: Partial<CustomSettings>) => {
      const doc = new JsonDocument({ rootElement: new RootElement({ ...customSettings, ...newSettings }) });
      save({
        custom: doc,
        risk,
        toMake,
        autoCenter,
        showConfirmations,
        autoApply,
        //  liquidateOnly,
        //  liquidateAndBlockTrading,
        soundNotifications
      });
    },
    [customSettings, risk, toMake, autoCenter, showConfirmations, autoApply, soundNotifications, liquidateOnly, liquidateAndBlockTrading]
  );

  const debounceSaveCustomSettings = useCallback(
    (newSettings: Partial<CustomSettings>) => {
      setCustomSettings((prev) => ({ ...prev, ...newSettings }));

      if (debounceRef.current) clearTimeout(debounceRef.current);

      debounceRef.current = setTimeout(() => {
        saveCustomSettings(newSettings);
        debounceRef.current = null;
      }, 1000);
    },
    [saveCustomSettings]
  );

  //this call usually needs to succeed in case the user browses away from a page and it called a save
  const save = useCallback((model: IUserSettingsModel) => {
    return new Promise<boolean>((resolve, reject) => {
      retryApiCall(() => userSettingsApi.post(new UserSettingsModel(model)))
        .then((res) => {
          // If the component is disposed, don't update the state, but we still want the call to run
          if (componentDisposedSignal.current.signal.aborted) return;

          setRisk(model.risk);
          setToMake(model.toMake);
          setAutoCenter(model.autoCenter);
          setShowConfirmations(model.showConfirmations);
          showConfirmationsRef.current = model.showConfirmations;
          setAutoApply(model.autoApply);
          setSoundNotifications(model.soundNotifications);
          // setLiquidateOnly(model.liquidateOnly);
          // setLiquidateAndBlockTrading(model.liquidateAndBlockTrading);
          setCustomSettings((model.custom?.rootElement as CustomSettings) || {});
          resolve(true);
        })
        .catch((e) =>
          handleAxiosErrorWithCallback(e, (ex) => {
            resolve(false);
          })
        );
    });
  }, []);

  const values = useMemo<ISettingsContext>(() => {
    const settings = {
      risk,
      toMake,
      autoCenter,
      showConfirmations,
      showConfirmationsRef,
      autoApply,
      liquidateOnly,
      liquidateAndBlockTrading,
      soundNotifications,
      customSettings,
      saveCustomSettings: debounceSaveCustomSettings,
      save
    };
    Settings = settings;
    return settings;
  }, [risk, toMake, autoCenter, showConfirmations, autoApply, soundNotifications, customSettings, debounceSaveCustomSettings]);

  return <SettingsContext.Provider value={values}>{children}</SettingsContext.Provider>;
}

export default SettingsContextProvider;
