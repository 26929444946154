import { OrderType, SymbolMetadata, UserContractModel } from '@/api/userApi';
import { OrderPromptType } from '@/contexts/OrdersContext';
import { useCallback, useMemo } from 'react';
import Modal, { ModalAction } from './modal';
import { ButtonType } from './button';
import { useModal } from '@/contexts/ModalContext';
import styles from './confirmOrderModal.module.scss';
import Heading from './heading';
import { orderTypeMap } from '@/data/enumTypeMaps';
import React from 'react';
import { useSymbol } from '@/contexts/SymbolContext';
import { formatContractPrice } from '@/helpers/formatter';

type ConfirmCancelOpenProps = {
  onConfirm: () => void;
  amount?: number;
  contract: UserContractModel | undefined;
};

const ConfirmCancelPositionModal = ({ amount, contract, onConfirm }: ConfirmCancelOpenProps) => {
  const { hideModal } = useModal();

  const actions: ModalAction[] = [
    {
      label: 'Cancel',
      props: { buttonType: ButtonType.outline, className: styles.modalButton },
      onClick: hideModal
    },
    {
      label: `Close Position`,
      props: {
        buttonType: amount < 0 ? ButtonType.red : ButtonType.green,
        className: styles.modalButton
      },
      onClick: () => {
        hideModal();
        onConfirm();
      }
    }
  ];

  return (
    <Modal header={<Heading className={amount < 0 ? styles.redHeader : styles.greenHeader}>Confirm Close Position</Heading>} actions={actions}>
      
        <div>
          I want to close my {amount > 0 ? '+' : ''}
          {amount} position of{' '}
          <span className={styles.bold}>
            {contract.productName} ({contract.contractName})
          </span>{' '}
        </div>
    </Modal>
  );
};

export default ConfirmCancelPositionModal;
