import { OrderStatus, OrderType, TradingAccountStatus, TradingAccountType, TradingRuleType, ViolationType } from '@api/userApi';

export const orderStatusMap = {
  [OrderStatus.Cancelled]: 'Cancelled',
  [OrderStatus.Expired]: 'Expired',
  [OrderStatus.Filled]: 'Filled',
  [OrderStatus.Open]: 'Open',
  [OrderStatus.Rejected]: 'Rejected'
};

export const tradingAccountStatusMap = {
  [TradingAccountStatus.Active]: 'Active',
  [TradingAccountStatus.Inactive]: 'Inactive',
  [TradingAccountStatus.TemporaryViolation]: 'Temp. Liquidation',
  [TradingAccountStatus.Violation]: 'Ineligible',
  [TradingAccountStatus.Ineligible]: 'Ineligible',
  [TradingAccountStatus.Closed]: 'Closed',
  [TradingAccountStatus.GoalAchieved]: 'Goal Achieved',
  [TradingAccountStatus.Paused]: 'Paused',
};

export const violationTypeMap = {
  [ViolationType.DailyLossLimit]: 'Temp. Liquidation',
  [ViolationType.MaximumLossLimit]: 'Ineligible',
  [ViolationType.PersonalDailyProfitTarget]: 'Lockout - Personal Profit Target',
  [ViolationType.PersonalDailyLossLimit]: 'Lockout - Personal Daily Loss',
  [ViolationType.PersonalStop]: 'Manual Lockout',
  [ViolationType.Custom]: 'Custom Violation',
  [ViolationType.TrailingPersonalDailyLossLimit]: 'Lockout - Trailing Personal Daily Loss',

}

export const orderTypeMap = {
  [OrderType.Limit]: 'Limit',
  [OrderType.Market]: 'Market',
  [OrderType.Stop]: 'Stop Market',
  [OrderType.StopLimit]: 'Stop Limit',
  [OrderType.TrailingStop]: 'Trailing Stop'
};

export const accountTypeMap = {
  [TradingAccountType.Express]: 'Express',
  [TradingAccountType.Live]: 'Live',
  [TradingAccountType.None]: 'None',
  [TradingAccountType.Practice]: 'Practice',
  [TradingAccountType.Sim]: 'Simulated',

}

export const enum networkSpeed {
  High,
  Medium,
  Low
}

export const enum chartPlotSide {
  Left,
  Right
}

export const enum domFontSize {
  Small,
  Medium,
  Large
}

export enum domCellPadding {
  Small,
  Large,
  XLarge
}

export enum topNavTextSize {
  Regular,
  Large,
  XLarge
}

export enum salesFontSize {
  Regular,
  Large,
  XLarge
}

