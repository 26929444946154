import { useModal } from '@/contexts/ModalContext';
import Modal, { ModalAction } from './modal';
import { ButtonType } from './button';
import Heading from './heading';
import React, { useEffect } from 'react';
import { useApi } from '@/contexts/ApiContext';
import dayjs from 'dayjs';
import { handleAxiosError } from '@/helpers/axiosHelper';

const TimeWarningModal = () => {
  const { hideModal } = useModal();

  const actions: ModalAction[] = [
    {
      label: `Proceed`,
      props: {
        buttonType: ButtonType.outline
      },
      onClick: () => {
        hideModal();
      }
    }
  ];

  return (
    <Modal style={{ maxWidth: 400 }} header={<Heading>Time Sync Warning</Heading>} actions={actions}>
      <div>Your computer clock is not synchronized with the correct time. This may affect your trading accuracy. Please update your system clock to match the current time.</div>
    </Modal>
  );
};

const TimeSyncChecker = () => {
  const { showModal, hideModal } = useModal();
  const { userApi, retryApiCall } = useApi();

  useEffect(() => {
    retryApiCall(() => userApi.getCurrentTimeUtc())
      .then((res) => {
        // Compare the server time with the local time
        const localUtc = dayjs.utc();
        const diff = res.diff(localUtc, 'millisecond');

        if (diff > 10000) {
          showModal(<TimeWarningModal />);
        }

        console.log('Time difference:', diff, 'ms');
      })
      .catch(handleAxiosError);
  }, []);

  return <></>;
};

export default TimeSyncChecker;
