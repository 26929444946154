import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from './ApiContext';
import { useCqg } from './CqgContext';
import { MarketStatus } from '@api/dataApi';
import { ExchangeStatus } from '@/api/userApi';
import { useAuth } from './AuthContext';
import { handleAxiosError } from '@/helpers/axiosHelper';
export interface IMarketStatusContext {
  getStatus: (symbolId: string) => MarketStatus;
  isOpen: (symbolId: string) => boolean | null | undefined;
}

export interface MarketStatusMap {
  [symbolId: string]: MarketStatus;
}

export const MarketStatusContext = React.createContext<IMarketStatusContext>({} as any);

export const useMarketStatus = () => React.useContext<IMarketStatusContext>(MarketStatusContext);

function MarketStatusContextProvider({ children }: any) {
  const { userId } = useAuth();
  const { marketStatusApi, retryApiCall } = useApi();
  const { subscribeMarketStatus, unsubscribeMarketStatus, subscribeOnUserReconnect, unsubscribeOnUserReconnect, subscribeMarketDataSubscriptionChange, unsubscribeMarketDataSubscriptionChange } = useCqg();
  const [marketStatus, setMarketStatus] = useState<MarketStatus[]>([]);
  const componentDisposedSignal = useRef(new AbortController());
  const statusMap = useMemo<MarketStatusMap>(() => {
    return marketStatus?.reduce((map, status) => {
      map[status.symbolId] = status;
      return map;
    }, {});
  }, [marketStatus]);

  useEffect(() => {
    const signal = componentDisposedSignal.current;

    subscribeMarketDataSubscriptionChange(userId);
    const id = subscribeMarketStatus((data) => {
      if (signal.signal.aborted) return;
      setMarketStatus((existing) => {
        let found = false;
        for (const status of existing) {
          if (status.symbolId === data.symbolId) {
            found = true;
            status.status = data.status;
            status.autoLiquidate = data.autoLiquidate;
            status.isOpen = data.isOpen;
            status.simStatus = data.simStatus;
            break;
          }
        }

        if (!found) {
          existing.push(data);
        }
        return [...existing];
      });
    });

    retryApiCall(() => marketStatusApi.getMarkets(), componentDisposedSignal.current)
      .then((data) => {
        if (signal.signal.aborted) return;
        setMarketStatus(data);
      })
      .catch(handleAxiosError);

    const onReconnect = () => {
      retryApiCall(() => marketStatusApi.getMarkets(), componentDisposedSignal.current)
        .then((data) => {
          if (signal.signal.aborted) return;
          setMarketStatus(data);
        })
        .catch(handleAxiosError);
    };

    subscribeOnUserReconnect(onReconnect);

    return () => {
      componentDisposedSignal.current.abort();
      unsubscribeMarketStatus(id);
      unsubscribeMarketDataSubscriptionChange(userId);
      unsubscribeOnUserReconnect(onReconnect);
    };
  }, []);

  const isOpen = useCallback(
    (symbolId: string) => {
      const status = statusMap[symbolId];
      if (!status) {
        return undefined;
      }
      return status.simStatus !== ExchangeStatus.Open && status.status !== ExchangeStatus.Open;
    },
    [statusMap]
  );

  const getStatus = useCallback(
    (symbolId: string) => {
      return statusMap[symbolId] || undefined;
    },
    [statusMap]
  );

  const values = useMemo(() => {
    const res: IMarketStatusContext = {
      getStatus,
      isOpen
    };

    return res;
  }, [getStatus, isOpen]);

  return <MarketStatusContext.Provider value={values}>{children}</MarketStatusContext.Provider>;
}

export default MarketStatusContextProvider;
