import classNames from 'classnames';
import React, { useMemo, useEffect, useState } from 'react';
import styles from './copyTrading.module.scss';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { useApi } from '../../contexts/ApiContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingArrowLoopLeft } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useSettings } from '@contexts/SettingsContext';

const CopyTradingIndicator: React.FC = (): JSX.Element => {
  const [showLeader, setShowLeader] = useState<boolean>(false);
  const [showFollower, setShowFollower] = useState<boolean>(false);
  const { customSettings } = useSettings();
  const { tradingAccounts, setActiveTradingAccount, activeTradingAccount } = useTradingAccount();

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  useEffect(() => {
      if(activeTradingAccount.isLeader) {
        setShowLeader(true);
        setShowFollower(false);
      } else if(activeTradingAccount.isFollower) {
        setShowFollower(true);
        setShowLeader(false);
      } else {
        setShowLeader(false);
        setShowFollower(false);
      }
  }, [tradingAccounts, activeTradingAccount.isLeader, activeTradingAccount.isFollower]);

  const followerAccounts = useMemo(() => {
    return tradingAccounts.filter((x) => x.isFollower);
  }, [tradingAccounts]);


  return useMemo(
    () => (
      <>
        {showLeader && (
          <>
            <Tooltip title={`This account is a Leader for ${followerAccounts.length} accounts currently Copy Trading`}>
              <div className={classNames(styles.leader)} style={{ fontSize: renderFontSize }}>
                <FontAwesomeIcon icon={faPersonWalkingArrowRight} style={{ marginRight: 3 }} />
                <span style={{whiteSpace: 'nowrap'}}>Leader {`+${followerAccounts.length}`}</span>
              </div>
            </Tooltip>
          </>
        )}
        {showFollower && (
          <>
            <Tooltip title='This account is a Follower Account for Copy Trading'>
              <div className={classNames(styles.follower)} style={{ fontSize: renderFontSize }}>
                <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} style={{ marginRight: 3 }} />
                <span>Follower</span>
              </div>
            </Tooltip>
          </>
        )}
      </>
    ),
    [showLeader, showFollower, customSettings.topNavTextSize, followerAccounts]
  );
};

export default React.memo(CopyTradingIndicator);
