import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  ReactNode,
} from "react";
import { toast } from "react-toastify";
import { ExchangeStatus, MarketStatus } from "@/api/userApi";
import { useCqg } from "@/contexts/CqgContext";
import { useSymbol } from "./SymbolContext";
import { SoundType, useSoundNotifications } from "@/hooks/useSoundNotifications";
import { useApi } from "./ApiContext";

export interface IMarketNotificationsContext {
  subscribeMarketNotifications: (symbolId: string, lockId: string) => void;
  unsubscribeMarketNotifications: (symbolId: string, lockId: string) => void;
}

export const MarketNotificationsContext = createContext<
  IMarketNotificationsContext
>({} as IMarketNotificationsContext);

export const useMarketNotifications = () =>
  React.useContext<IMarketNotificationsContext>(MarketNotificationsContext);

interface MarketNotificationsProviderProps {
  children: ReactNode;
}

function MarketNotificationsProvider({
  children,
}: MarketNotificationsProviderProps) {
  const {
    subscribeMarketStatus,
    unsubscribeMarketStatus,
    subscribeOnUserReconnect,
    unsubscribeOnUserReconnect,
  } = useCqg();
  const { getContractByProductId } = useSymbol();
  const { play } = useSoundNotifications();
  const { marketStatusApi } = useApi();

  // Used to debounce sound notifications
  const lastSoundTimeRef = useRef<number>(0);

  type MarketOpenStatus = {
    status: ExchangeStatus;
    lastUpdated: number;
    lastTimeout: NodeJS.Timeout | null;
  }

  // Map of productId to market status.
  const statusMapRef = useRef<Map<string, MarketOpenStatus>>(new Map());

  // Map of productId to locks.
  const symbolSubscriptions = useRef<Map<string, string[]>>(new Map());

  const setInitialMarketStatus = (data: MarketStatus[]) => {
    data.forEach((data) => {
      const existingStatus = statusMapRef.current.get(data.symbolId);
      // Only use the REST API data if we don't have real-time data yet or it's older
      if (!existingStatus || !existingStatus.lastUpdated) {

        let status = data.status;
        if (data.simStatus === ExchangeStatus.Closed) { // If the sim status is closed, use that
          status = ExchangeStatus.Closed;
        }

        const openStatus: MarketOpenStatus = {
          status,
          lastUpdated: Date.now(),
          lastTimeout: null,
        };

        statusMapRef.current.set(data.symbolId, openStatus);
      }
    });
  };

  useEffect(() => {
    const onReconnect = () => {
      marketStatusApi
        .getMarkets()
        .then((data) => {
          setInitialMarketStatus(data);
        })
        .catch((e) => {
          console.log("Failed to get market status", e);
        });
    };

    subscribeOnUserReconnect(onReconnect);

    return () => unsubscribeOnUserReconnect(onReconnect);
  }, []);

  const subscribeMarketNotifications = useCallback(
    (productId: string, lockId: string) => {
      const locks = symbolSubscriptions.current.get(productId) || [];
      if (!locks.includes(lockId)) {
        console.log(
          `Adding notification subscription for ${productId} with lock ${lockId}`
        );
        locks.push(lockId);
        symbolSubscriptions.current.set(productId, locks);
      }
    },
    []
  );

  const playNotificationSound = (sound: SoundType) => {
    // Check if it's been a second since the last sound
    const currentTime = new Date().getTime();
    if (currentTime - lastSoundTimeRef.current < 1000) {
      console.log("Skipping duplicate sound notification");
      return;
    }

    console.log("Playing sound notification");
    play(sound);
    lastSoundTimeRef.current = currentTime;
  };

  const unsubscribeMarketNotifications = useCallback(
    (productId: string, lockId: string) => {
      const locks = symbolSubscriptions.current.get(productId) || [];
      const index = locks.indexOf(lockId);
      if (index !== -1) {
        console.log(
          `Removing notification subscription for ${productId} with lock ${lockId}`
        );
        locks.splice(index, 1);
        if (locks.length === 0) {
          symbolSubscriptions.current.delete(productId);
        } else {
          symbolSubscriptions.current.set(productId, locks);
        }
      }
    },
    []
  );

  const isSymbolSubscribed = useCallback((symbolId: string) => {
    return symbolSubscriptions.current.has(symbolId);
  }, []);

  useEffect(() => {
    const marketStatusCb = (data: MarketStatus) => {
      
      const oldStatus = statusMapRef.current.get(data.symbolId);

      let status = data.status;
      if (data.simStatus === ExchangeStatus.Closed) {
        status = ExchangeStatus.Closed;
      }

      const changed = oldStatus ? oldStatus.status !== status : true;

      console.log("Market Status Update: ", data);

      const contract = getContractByProductId(data.symbolId);
      const friendlyName = contract?.productName;

      // Store the new status with a timestamp
      statusMapRef.current.set(data.symbolId, {
        status,
        lastUpdated: Date.now(), // Add timestamp for freshness check
        lastTimeout: oldStatus?.lastTimeout || null,
      });

      if (changed && isSymbolSubscribed(data.symbolId)) {

        // Cancel old timeout if it exists
        if (oldStatus.lastTimeout) {
          clearTimeout(oldStatus.lastTimeout);
        }

        // Handle changes in quick succession
        const newTimeout = setTimeout(() => {
          const currentStatus = statusMapRef.current.get(data.symbolId);

          // Use most current status from ref
          if (currentStatus) {
            if (status === ExchangeStatus.PreOpen) {
              toast.info(
                `${friendlyName} temporarily moved to pre-open status`
              );
              playNotificationSound(SoundType.MarketsPreOpen);
            } else if (status === ExchangeStatus.Open) {
              toast.success(`${friendlyName} moved to open status`);
              playNotificationSound(SoundType.MarketsOpen);
            } else if (status === ExchangeStatus.Closed) {
              toast.error(`${friendlyName} moved to closed status`);
              playNotificationSound(SoundType.MarketsClosed);
            }
          }
        }, 100);

        statusMapRef.current.get(data.symbolId)!.lastTimeout = newTimeout;
      }
    };

    console.log("Subscribing to market status updates for notifications");

    // First subscribe to real-time updates
    const id = subscribeMarketStatus(marketStatusCb);

    // Then get initial data
    marketStatusApi
      .getMarkets()
      .then((data) => {
        setInitialMarketStatus(data);
      })
      .catch((e) => {
        console.log("Failed to get market status", e);
      });

    return () => {
      unsubscribeMarketStatus(id);
    };
  }, []);

  const values = useMemo(
    () => ({
      subscribeMarketNotifications,
      unsubscribeMarketNotifications,
    }),
    [subscribeMarketNotifications, unsubscribeMarketNotifications]
  );

  return (
    <MarketNotificationsContext.Provider value={values}>
      {children}
    </MarketNotificationsContext.Provider>
  );
}

export default MarketNotificationsProvider;
