import React, { useEffect } from 'react';
import { config } from '@/config';

const FreshChat = ({ isSupportPage }) => {
  useEffect(() => {
    if (!config.freshchatToken || !config.freshchatHost || !config.freshchatUUID) return;

    let fcWidget = (window as any).fcWidget;

    const initFreshChat = () => {
      if (fcWidget) {
        fcWidget.init({
          token: config.freshchatToken,
          host: config.freshchatHost,
          widgetUuid: config.freshchatUUID
        });
        fcWidget.show();
      }
    };

    if (!isSupportPage) {
      try { fcWidget.hide(); } catch (e) { console.log(e); }
      return;
    } else{
      if (!document.getElementById('freshchat-js-sdk')) {
        const script = document.createElement('script');
        script.id = 'freshchat-js-sdk';
        script.src = `${config.freshchatHost}/js/widget.js`;
        script.async = true;
        script.onload = () => {
          fcWidget = (window as any).fcWidget;
          initFreshChat();
        };
        document.body.appendChild(script);
      } else {
        initFreshChat();
      }
    }

    return () => {
      fcWidget = (window as any).fcWidget;
      try { if (fcWidget) fcWidget.hide(); } catch (e) { console.log(e); }
    };
  }, [config.freshchatToken, isSupportPage]);

  return <></>;
};

export default React.memo(FreshChat);
