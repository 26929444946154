import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDragDrop, DragType } from '@/contexts/DragDropContext';
import styles from './dom.module.scss';
import classNames from 'classnames';
import { formatPrice } from 'src/helpers/formatter';
interface SltpDomOrder {
  price: number;
  pnl: number;
  stopLoss?: number;
  takeProfit?: number;
  posSize: number;
  posEntryPrice: number;
  editSltp: (stopLoss?: number, takeProfit?: number) => void;
}

const SltpDomOrder: React.FC<SltpDomOrder> = ({ price, pnl, stopLoss, takeProfit, posSize, posEntryPrice, editSltp }): JSX.Element => {
  const { dragData, dragType, setDragData } = useDragDrop();
  const [allowDrop, setAllowDrop] = useState(false);

  const bracketBorderStyle = useMemo(() => {
    const isStopLossBracket = stopLoss && (posSize > 0 ? price >= stopLoss && price <= posEntryPrice : price >= stopLoss && price <= posEntryPrice);
    const isProfitBracket = takeProfit && (posSize > 0 ? price >= posEntryPrice && price <= takeProfit : price >= takeProfit && price <= posEntryPrice);

    if (isStopLossBracket && isProfitBracket) {
      if (posSize > 0) {
        return styles.sltpLongCenter;
      } else {
        return styles.sltpShortCenter;
      }
    }

    if (isStopLossBracket) {
      return styles.bracketStopLossLine;
    }

    if (isProfitBracket) {
      return styles.bracketTakeProfitLine;
    }

    return '';
  }, [stopLoss, takeProfit, posSize, posEntryPrice, price]);
  const isStopLoss = price === stopLoss;
  const isTakeProfit = price === takeProfit;

  const handleDragStart = useCallback(
    (e: React.DragEvent) => {
      if (isStopLoss) {
        setDragData(stopLoss, DragType.StopLoss);
      } else if (isTakeProfit) {
        setDragData(takeProfit, DragType.TakeProfit);
      }
    },
    [isStopLoss, isTakeProfit, stopLoss, takeProfit]
  );

  useEffect(() => {
    if (allowDrop && dragType == DragType.None) {
      setAllowDrop(false);
    }
  }, [dragType, allowDrop]);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.StopLoss) {
        if (price == stopLoss) {
          setAllowDrop(false);
        } else {
          if (posSize > 0) {
            // if(price > posEntryPrice) {
            //   setAllowDrop(false);
            //   return;
            // }
          } else {
            // if(price < posEntryPrice) {
            //   setAllowDrop(false);
            //   return;
            // }
          }

          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
          setAllowDrop(true);
        }
      } else if (dragType == DragType.TakeProfit) {
        if (price == takeProfit) {
          setAllowDrop(false);
        } else {
          if (posSize > 0) {
            // if(price < posEntryPrice) {
            //   setAllowDrop(false);
            //   return;
            // }
          } else {
            // if(price > posEntryPrice) {
            //   setAllowDrop(false);
            //   return;
            // }
          }

          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
          setAllowDrop(true);
        }
      } else {
        setAllowDrop(false);
        //  e.dataTransfer.dropEffect = 'none';
      }
    },
    [dragType, dragData]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      setAllowDrop(false);
    },
    [dragType]
  );

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    },
    [price, dragType]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setDragData();
      if (dragType == DragType.StopLoss) {
        editSltp(price, takeProfit);
      } else if (dragType == DragType.TakeProfit) {
        editSltp(stopLoss, price);
      }
    },
    [price, dragType]
  );

  const handleDragEnd = useCallback(() => {
    setDragData();
  }, []);

  const isPosEntry = posEntryPrice == price;

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={onDragEnter}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={classNames(styles.pnlCell, pnl < 0 ? styles.redHeader : styles.greenHeader, allowDrop && styles.allowDrop)}
    >
      <div>{pnl !== undefined && `$${formatPrice(pnl)}`}</div>
    </div>
  );
};

export default SltpDomOrder;
