import { logException } from '@/helpers/exceptionHelper';
import axios, { AxiosError } from 'axios';
import { isNumber } from 'lodash';

export const handleAxiosError = (error: AxiosError) => {
  if (error && error.code && error.code == 'ATTEMPT_ABORTED') {
    //ignore this error since it was caused by an abort
    return;
  }
  if (axios.isAxiosError(error)) {
    if (error.status === 0) {
      //not connected to internet
      console.info('Exception calling API. Not connected to internet', error);
    } else if (isNumber(error.status) && error.status != 401 && error.status != 403) {
      logException(error, 'API Status Exception: ' + error.status);
    } else {
      //nothing we need to do for 401/403 errors since they are handled by the auth service
    }
  } else {
    logException(error, 'Unhandled API Exception');
  }
};

export const handleAxiosErrorWithCallback = (error: AxiosError, additionalCallback: (error: AxiosError) => void) => {
  handleAxiosError(error);
  additionalCallback(error);
};
