import React, { useState, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Grid,
  Autocomplete,
  Collapse,
} from '@mui/material';
import { Country } from 'country-state-city';
import { useAuth } from '@contexts/AuthContext';
import { StyledButton, StyledLink } from '@components/styledComponents';
import Panel from '@components/topstep/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavRoutes } from 'src/data/NavRoutes';
import { useNavigate } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const AgreementsView: React.FC = () => {
  const { signAgreements, requiredAgreements } = useAuth();
  const [name, setName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Get all countries with proper structure
  const countries = useMemo(() => {
    return Country.getAllCountries().map(country => ({
      code: country.isoCode,
      label: country.name
    }));
  }, []);

  useEffect(() => {
    if (requiredAgreements.length === 0) {
      navigate(NavRoutes.Trade);
    }
  }, [requiredAgreements, navigate]);

  const agreement = useMemo(() => {
    if (requiredAgreements.length === 0) {
      return null;
    }
    return requiredAgreements[0];
  }, [requiredAgreements]);

  const agreementText = useMemo(() => {
    return (
      agreement?.customAcceptText ||
      'I have fully read, understand and declare my Market Data Self Certification'
    );
  }, [agreement]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (accepted && name.trim() !== '' && country && zipCode.trim() !== '') {
      setLoading(true);
      signAgreements([agreement.id], name, {
        streetAddress,
        city,
        state,
        zipCode,
        country: country.label,
        countryCode: country.code
      }).then(() => {
        setLoading(false);
        setAccepted(false);
      });
    }
  };

  const isStateRequired = useMemo(() => {
    return !!country;
  }, [country]);

  const isFormValid =
    name.trim() !== '' &&
    country &&
    (country ? (
      streetAddress.trim() !== '' &&
      city.trim() !== '' &&
      state.trim() !== '' &&
      zipCode.trim() !== ''
    ) : true) &&
    accepted;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Panel title="Sign Agreements">
        <Box sx={{ padding: '0.5em' }}>
          <p>
            Please read the following documents, and if you agree, fill out the
            form below, click accept and submit.
          </p>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {agreement && (
                <StyledLink
                  target="_blank"
                  sx={{ marginTop: '0.5em', marginLeft: '1em' }}
                  key={agreement.id}
                  href={agreement.url}
                >
                  {agreement.title}
                </StyledLink>
              )}

              <TextField
                label="Full Name"
                value={name}
                name="fullName"
                onChange={(event) => setName(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />

              <Autocomplete
                id="country-select"
                options={countries}
                value={country}
                onChange={(event, newValue) => setCountry(newValue)}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />

              <Collapse in={!!country} timeout={500} sx={{ width: '100%' }}>
                <Box sx={{ pt: 1 }}>
                  <TextField
                    label="Street Address"
                    value={streetAddress}
                    name="streetAddress"
                    onChange={(event) => setStreetAddress(event.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    required={!!country}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City"
                        value={city}
                        name="city"
                        onChange={(event) => setCity(event.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required={!!country}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="State/Province"
                        value={state}
                        name="state"
                        onChange={(event) => setState(event.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required={isStateRequired}
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    label="Zip/Postal Code"
                    value={zipCode}
                    name="zipCode"
                    onChange={(event) => setZipCode(event.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    required={!!country}
                  />
                </Box>
              </Collapse>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={accepted}
                    onChange={(event) => setAccepted(event.target.checked)}
                  />
                }
                label={agreementText}
              />
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid || loading}
              >
                {loading && <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '10px' }} />}
                Submit
              </StyledButton>
            </Box>
          </form>
        </Box>
      </Panel>
    </div>
  );
};

export default AgreementsView;
