import React from 'react';
import { Box } from '@mui/material';
import config from 'src/config';
export const Footer: React.FC = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  return (
    <Box display='flex' justifyContent={'space-between'}>
      <Box fontSize='0.9em' flex='1' style={{ whiteSpace: 'nowrap' }} color='#979797' bgcolor='darkBg'>
        Version: {config.version}
      </Box>
      <Box flex='1' style={{ whiteSpace: 'nowrap' }} fontSize='0.9em' display='flex' justifyContent='center' alignItems='center' color='#979797' bgcolor='darkBg'>
        Licensed to {config.company} & powered by{' '}
        <a href='https://www.projectx.com/' target='_blank' className='footer-link'>
          &nbsp;ProjectX
        </a>
        . Copyright 2024-{currentYear}
      </Box>
      <Box flex='1'>&nbsp;</Box>
    </Box>
  );
};
