import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCqg } from './CqgContext';
import { useSymbol } from './SymbolContext';
import SltpData from '../models/sltpData';
import { useApi } from './ApiContext';
import {
  IPositionModel,
  LinkOrderUserRequest,
  LinkOrderUserResponse,
  LinkedOrderModel,
  MarketStatus,
  OrderModel,
  OrderStatus,
  OrderType,
  PlaceOrderRequest,
  PlaceOrderResponse,
  PositionModel,
  StopLossTakeProfitModel,
  SymbolMetadata,
  TradeModel,
  UnlinkOrderUserRequest,
  UnlinkOrderUserResponse,
  UpdateStopLossRequest,
  UpdateStopLossResponse,
  UserContractModel
} from '../api/userApi';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';
import { useTradingAccount } from './TradingAccountContext';
import { useModal } from './ModalContext';
import { UpdateAction } from '@models/updateAction';
import { Id, TypeOptions, toast } from 'react-toastify';
import { orderTypeMap } from 'src/data/enumTypeMaps';
import { ToastContent, ToastIcon } from 'react-toastify/dist/types';
import { v4 as uuidv4 } from 'uuid';
import { SoundType, useSoundNotifications } from '@hooks/useSoundNotifications';
import { useSettings } from '@contexts/SettingsContext';
import { logException } from '@/helpers/exceptionHelper';
import * as Sentry from '@sentry/react';
import { formatContractPrice } from '@/helpers/formatter';
import ConfirmCancelOrderModal from '@/components/topstep/confirmCancelOrderModal';
import ConfirmCancelPositionModal from '@/components/topstep/confirmCancelPositionModal';
import ConfirmOrderModal from '@/components/topstep/confirmOrderModal';
import { handleAxiosError, handleAxiosErrorWithCallback } from '@/helpers/axiosHelper';
import { useMarketStatus } from './MarketStatusContext';
import { ExchangeStatus } from '@/api/dataApi';
import { Position } from 'react-rnd';

export enum OrderPromptType {
  Buy,
  Sell
}

export interface FEOrderModel {
  id?: number;
  symbol: string;
  orderType: OrderPromptType;
  type: OrderType;
  limitPrice?: number;
  stopPrice?: number;
  trailDistance?: number;
  amount: number;
}

export interface FeOrderResult {
  error: string;
  success: boolean;
}
export interface EditOrderModel {
  price: number;
  risk: number;
  profit: number;
}

export interface IOrdersContext {
  orders: OrderModel[];
  allPositions: PositionModel[];
  activePositions: PositionModel[];
  rawActivePositions: PositionModel[];
  trades: TradeModel[];
  sltpSettings: Map<string, SltpData>;
  linkedOrders: LinkedOrderModel[];
  linkOrders: (tradingAccountId: number, orderIds: number[]) => Promise<LinkOrderUserResponse>;
  unlinkOrders: (tradingAccountId: number, groupId: string) => Promise<UnlinkOrderUserResponse>;
  /**
   * There are no dependencies. Does not need to be cached.
   */
  placeOrderWithSymbol: (data: FEOrderModel) => Promise<PlaceOrderResponse>;
  cancelOrder: (order: OrderModel) => Promise<boolean>;
  cancelOrders: (orders: OrderModel[], skipConfirmation: boolean) => Promise<boolean>;
  changeOrderPrice: (id: number, price: number) => Promise<boolean>;
  closePosition: (position: IPositionModel) => Promise<boolean>;
  reversePosition: (symbol: string) => Promise<void>;
  editSltpSetting: (positionId: number, stopLoss?: number, takeProfit?: number) => Promise<UpdateStopLossResponse | null>;
  editRiskToMake: (positionId: number, risk?: number, toMake?: number) => Promise<StopLossTakeProfitModel | null>;
  /**
   * This function cancels all orders for the active account
   *
   * There are no dependencies. Does not need to be cached.
   */
  cancelAll: () => Promise<boolean>;
  /**
   * This closes all positions on the active account.
   *
   * There are no dependencies. Does not need to be cached.
   */
  closeAllPositions: () => Promise<void>;
  nonSltpOrders: OrderModel[];
  highestUnrealizedBalance: number;
  unrealizedBalance: number;
  unrealizedPnl: number;
}

export const calculatePnl = (posSize: number, entryPrice: number, price: number, multiplier: number) => {
  if (posSize < 0) {
    return posSize * (entryPrice - price) * multiplier * -1;
  } else if (posSize > 0) {
    return posSize * (price - entryPrice) * multiplier;
  } else {
    return 0;
  }
};
const enum ToastEvent {
  Show = 0,
  Clear = 1,
  DidMount = 2,
  WillUnmount = 3,
  Change = 4,
  ClearWaitingQueue = 5
}
interface MyToastUpdate {
  type: TypeOptions;
  icon: ToastIcon;
  data: any;
  pauseOnFocusLoss: boolean;
  hideProgressBar: boolean;
}

export const placedOrderStatusMap = {
  [OrderStatus.Cancelled]: 'Cancelled',
  [OrderStatus.Expired]: 'Expired',
  [OrderStatus.Filled]: 'Filled',
  [OrderStatus.Open]: 'Placed',
  [OrderStatus.Rejected]: 'Rejected',
  [OrderStatus.Pending]: 'Pending'
};
export const placedOrderStatusTypeMap = {
  [OrderStatus.Cancelled]: 'warning',
  [OrderStatus.Expired]: 'warning',
  [OrderStatus.Filled]: 'success',
  [OrderStatus.Open]: 'info',
  [OrderStatus.Rejected]: 'error',
  [OrderStatus.Pending]: 'info'
};
export const placedOrderStatusIconMap = {
  [OrderStatus.Cancelled]: '❌',
  [OrderStatus.Expired]: '❌',
  [OrderStatus.Filled]: '✅',
  [OrderStatus.Open]: '✅',
  [OrderStatus.Rejected]: '❌',
  [OrderStatus.Pending]: '⏳'
};
const wnd = window as any;
export const OrdersContext = React.createContext<IOrdersContext>({} as any);
export const useOrders = () => React.useContext<IOrdersContext>(OrdersContext);

function OrderContextProvider({ children }: any) {
  const { contracts } = useSymbol();
  const { activeTradingAccount, refreshTradingAccounts, tradingAccounts, setActiveTradingAccount } = useTradingAccount();

  const { subscribeQuotesForSymbol, unsubscribeQuotesForSymbol } = useCqg();
  const { orderApi, positionApi, metadataApi, tradeApi, linkedOrderApi, retryApiCall } = useApi();
  const { userId } = useAuth();
  const { showModal, hideModal } = useModal();
  const { customSettings, showConfirmationsRef } = useSettings();
  const { getContractByProductName, getContractByProductId } = useSymbol();

  const symbolsMetadataRef = useRef<Map<string, UserContractModel>>(new Map());

  useEffect(() => {
    symbolsMetadataRef.current.clear();
    for (const symbol of contracts) {
      symbolsMetadataRef.current.set(symbol.productId, symbol);
    }
  }, [contracts]);

  const { subscribeOrders, unsubscribeOrders, subscribePositions, unsubscribePositions, subscribeTrades, unsubscribeTrades, subscribeOnUserReconnect, unsubscribeOnUserReconnect, subscribeLinkedOrders, unsubscribeLinkedOrders } = useCqg();

  const { play } = useSoundNotifications();
  const toasts = useRef<Map<string, Id>>(new Map());
  const [sltpSettings, setSltpSettings] = useState<Map<string, SltpData>>(new Map());
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [trades, setTrades] = useState<TradeModel[]>([]);
  const [linkedOrders, setLinkedOrders] = useState<LinkedOrderModel[]>([]);
  const [sltpOrders, setSltpOrders] = useState<number[]>([]);
  const [positions, setPositions] = useState<PositionModel[]>([]);
  //These are constantly updated with the latest PNL and cause a lot of mutations / re-renders
  const [positionsWithPnl, setPositionsWithPnl] = useState<PositionModel[]>([]);
  const activeAccountIdRef = useRef(0);
  const contextDisposeSignal = useRef(new AbortController());
  const userChangedSignal = useRef(new AbortController());
  const { getStatus } = useMarketStatus();

  const metadataRef = useRef<Map<string, SymbolMetadata>>(new Map());
  const visualAlerts = useRef(customSettings.supressAlerts !== true);
  const [accountIds, setAccountIds] = useState<number[]>([]);

  const activeAccountId = useMemo(() => {
    const active = activeTradingAccount?.accountId || 0;
    activeAccountIdRef.current = active;
    return active;
  }, [activeTradingAccount]);

  // Update the visual alerts setting
  useEffect(() => {
    visualAlerts.current = customSettings.supressAlerts !== true;
  }, [customSettings.supressAlerts]);

  useEffect(() => {
    const signal = contextDisposeSignal.current;

    retryApiCall(() => metadataApi.get(), signal)
      .then((data) => {
        //ignore API call if component is disposed
        if (signal.signal.aborted) {
          console.log('Aborted metadata call');
          return;
        }

        for (const metadata of data) {
          metadataRef.current.set(metadata.symbol, metadata);
        }
      })
      .catch((err) => {
        if (signal.signal.aborted) {
          console.log('Aborted metadata call');
          return;
        }
        handleAxiosError(err);
      });

    return () => {
      contextDisposeSignal.current.abort();
    };
  }, []);

  useEffect(() => {
    const signal = userChangedSignal.current;

    const refresh = () => {
      retryApiCall(() => linkedOrderApi.getLinkedOrders(activeAccountId), signal)
        .then((data) => {
          //ignore result if user changes
          if (signal.signal.aborted) {
            console.log('Aborted linked orders call');
            return;
          }
          setLinkedOrders(data.linkedOrders);
        })
        .catch((e) => {
          if (signal.signal.aborted) {
            console.log('Aborted linked orders call');
            return;
          }
          handleAxiosError(e);
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeLinkedOrders(activeAccountId, (update) => {
      //ignore updates if user changes
      if (signal.signal.aborted) return;

      setLinkedOrders((existingData) => {
        if (update.action == UpdateAction.Deleted) {
          return existingData.filter((o) => o.groupId != update.data.groupId);
        } else if (update.action == UpdateAction.Created) {
          return [...existingData, update.data];
        } else {
          logException(new Error('Unknown update action'), 'Unknown update action on linked orders');
        }
      });
    });

    return () => {
      //reset the user changed signal so that the next time anything uses the user change signal, they will abort properly
      userChangedSignal.current.abort();
      userChangedSignal.current = new AbortController();

      unsubscribeLinkedOrders(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  // Refresh and re-subscribe to all user trades updates when the user changes
  useEffect(() => {
    const signal = userChangedSignal.current;
    const refresh = () => {
      retryApiCall(() => tradeApi.get(activeAccountId), signal)
        .then((data) => {
          //ignore result if user changes
          if (signal.signal.aborted) return;
          setTrades(data);
        })
        .catch((e) => {
          if (signal.signal.aborted) {
            console.log('Aborted trades call');
            return;
          }
          handleAxiosError(e);
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeTrades(activeAccountId, (update) => {
      //ignore updates if user changes
      if (signal.signal.aborted) return;

      setTrades((existingData) => {
        var data = update.data;
        const newData = existingData.filter((o) => o.id !== data.id);
        if (data.createdAt) data.createdAt = dayjs(data.createdAt);
        if (data.exitedAt) data.exitedAt = dayjs(data.exitedAt);
        if (data.tradeDay) data.tradeDay = dayjs(data.tradeDay);
        newData.push(data);
        return newData;
      });
    });

    return () => {
      unsubscribeTrades(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  // Refresh and re-subscribe to all user order updates when the user changes
  useEffect(() => {
    const signal = userChangedSignal.current;

    const refresh = () => {
      retryApiCall(() => orderApi.get(activeAccountId), signal)
        .then((res) => {
          //ignore result if user changes
          if (signal.signal.aborted) return;
          setOrders(res);
        })
        .catch((e) => {
          if (signal.signal.aborted) {
            console.log('Aborted orders call');
            return;
          }
          handleAxiosError(e);
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeOrders(activeAccountId, (update) => {
      //ignore updates if user changes
      if (signal.signal.aborted) return;

      setOrders((existingData) => {
        var data = update.data;
        const newOrders = existingData.filter((o) => o.id !== data.id);
        const oldOrder = existingData.find((o) => o.id === data.id);
        if (data.cancelledAt) data.cancelledAt = dayjs(data.cancelledAt);
        if (data.createdAt) data.createdAt = dayjs(data.createdAt);
        if (data.filledAt) data.filledAt = dayjs(data.filledAt);
        if (!oldOrder || oldOrder.status != data.status) updateToast(data);
        newOrders.push(data);
        return newOrders;
      });
    });

    return () => {
      unsubscribeOrders(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  useEffect(() => {
    toast.onChange((toast) => {
      if (toast.status == 'removed') {
        const orderId = toast.data + '';
        if (orderId && toasts.current.has(orderId)) {
          toasts.current.delete(orderId);
        }
      }
    });
  }, []);

  const updateToast = useCallback((order: OrderModel) => {
    if (order.positionSize == 0) return;
    const symbolMetadata = symbolsMetadataRef.current.get(order.symbolId);

    switch (order.status) {
      case OrderStatus.Filled:
        play(SoundType.OrderFilled);
        break;
      case OrderStatus.Rejected:
        play(SoundType.OrderRejected);
        break;
      // case OrderStatus.Open:
      //   play(SoundType.OrderPlaced);
      //   break;
      default:
        break;
    }

    if (visualAlerts.current) {
      const baseMessage = `${order.positionSize > 0 ? '+' + order.positionSize : order.positionSize} ${symbolMetadata.productName} ${orderTypeMap[order.type]} ${
        order.type == OrderType.Limit && order.limitPrice ? ' @ ' + formatContractPrice(order.limitPrice, symbolMetadata) : ''
      }`;

      const content = (
        <div>
          <span style={{ fontWeight: 'bold' }}>{`${baseMessage} ${placedOrderStatusMap[order.status]}!`}</span>
          {order.rejectionReason && (
            <div>
              <span>{order.rejectionReason}</span>
            </div>
          )}
        </div>
      );
      const toastKey = order.customTag || order.id + '';
      const toastId = toasts.current.get(toastKey) || null;
      updateToastContent(toastId, content, {
        icon: placedOrderStatusIconMap[order.status],
        type: placedOrderStatusTypeMap[order.status],
        data: toastKey,
        pauseOnFocusLoss: false,
        hideProgressBar: true
      });
    }
  }, []);

  const updateToastContent = useCallback((toastId: Id, content: ToastContent<any>, options: MyToastUpdate) => {
    if (toastId) {
      toast.update(toastId, { ...options, render: content, pauseOnFocusLoss: false, hideProgressBar: true });
    } else {
      const newToastId = toast(content, options);
      const toastKey = options.data + '';

      toasts.current.set(toastKey, newToastId);
      wnd.updateMap = toasts.current;
    }
  }, []);

  //Filter account IDs that are no longer in the list
  useEffect(() => {
    const ids = tradingAccounts.map((x) => x.accountId);
    setAccountIds((prev) => {
      for (const id of ids) {
        if (!prev.includes(id)) {
          return ids;
        }
      }

      for (const id of prev) {
        if (!ids.includes(id)) {
          return ids;
        }
      }

      return prev;
    });
  }, [tradingAccounts]);

  // Refresh and re-subscribe to all user positions updates when the user changes
  useEffect(() => {
    const contextDisposeSignalRef = contextDisposeSignal.current;

    const refresh = () => {
      retryApiCall(() => positionApi.getAllByUser(userId), contextDisposeSignalRef)
        .then((data) => {
          //ignore result if user changes
          if (contextDisposeSignalRef.signal.aborted) return;

          setPositions(data);
          const sltpOrderIds = data
            .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
            .flat()
            .filter((x) => x);
          setSltpOrders(sltpOrderIds);
        })
        .catch((e) => {
          if (contextDisposeSignalRef.signal.aborted) {
            console.log('Aborted positions call');
            return;
          }
          handleAxiosError(e);
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subscriptions = accountIds.map((y) => {
      const subId = subscribePositions(y, (update) => {
        //ignore updates if user changes
        if (contextDisposeSignalRef.signal.aborted) return;

        setPositions((prev) => {
          var position = update.data;

          const existing = prev.find((p) => p.id == position.id);

          if (update.action == UpdateAction.Deleted) {
            const newData = prev.filter((p) => p.id != position.id);
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          }

          position.entryTime = dayjs(position.entryTime);

          if (!existing) {
            const newData = [...prev, position];
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          } else {
            existing.positionSize = position.positionSize;
            existing.averagePrice = position.averagePrice;
            existing.entryTime = position.entryTime;
            existing.profitAndLoss = position.profitAndLoss;
            existing.takeProfit = position.takeProfit;
            existing.stopLoss = position.stopLoss;
            existing.risk = position.risk;
            existing.toMake = position.toMake;
            existing.stopLossOrderId = position.stopLossOrderId;
            existing.takeProfitOrderId = position.takeProfitOrderId;

            const newData = [...prev];
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          }
        });
      });

      return { subId, accountId: y };
    });

    return () => {
      subscriptions.forEach((x) => unsubscribePositions(x.accountId, x.subId));
      unsubscribeOnUserReconnect(refresh);
    };
  }, [accountIds, userId]);

  useEffect(() => {
    const contextSignalRef = contextDisposeSignal.current;

    setPositionsWithPnl(positions);
    const symbolsToWatch = [...new Set(positions.filter((y) => y.positionSize != 0).map((x) => x.symbolId))];

    const subs = symbolsToWatch.map((symbol) => {
      const id = subscribeQuotesForSymbol(symbol, (quote) => {
        if (contextSignalRef.signal.aborted) return;

        if (quote.lastPrice) {
          setPositionsWithPnl((prev) => {
            const positions = prev.filter((x) => x.symbolId == symbol);
            if (!positions.length) return prev;
            const metadata = metadataRef.current.get(symbol);
            if (!metadata) return prev;

            for (const pos of positions) {
              const lastPrice = quote.lastPrice;
              var pnl = calculatePnl(pos.positionSize, pos.averagePrice, lastPrice, metadata.contractCost);
              pos.profitAndLoss = pnl;
            }

            return [...prev];
          });
        }
      });

      return { symbol, id };
    });

    return () => {
      subs.forEach((x) => unsubscribeQuotesForSymbol(x.symbol, x.id));
    };
  }, [positions]);

  const reversePosition = useCallback(async (symbol: string) => {
    const signalRef = contextDisposeSignal.current;

    try {
      //Only cancel the call if the component disposes
      await retryApiCall(() => positionApi.reverse(activeAccountIdRef.current, symbol), signalRef);
    } catch (e) {
      if (signalRef.signal.aborted) {
        console.log('Aborted reverse position call');
        return;
      }
      handleAxiosError(e);
    }
  }, []);

  const cancelAll = useCallback(async () => {
    const signalRef = contextDisposeSignal.current;

    try {
      //Only cancel the call if the component disposes
      const res = await retryApiCall(() => orderApi.cancelAllAccountOrders(activeAccountIdRef.current), signalRef);
      return res;
    } catch (e) {
      if (signalRef.signal.aborted) {
        console.log('Aborted cancel all call');
        return;
      }
      handleAxiosError(e);
    }
  }, []);

  const editRiskToMake = useCallback(async (positionId: number, risk?: number, toMake?: number) => {
    const contextDisposedSignalRef = contextDisposeSignal.current;
    try {
      //Only cancel the call if the component disposes
      const res = await retryApiCall(() => positionApi.putRiskToMake(positionId, risk, toMake), contextDisposedSignalRef);
      return res;
    } catch (e) {
      if (contextDisposedSignalRef.signal.aborted) {
        console.log('Aborted edit risk to make call');
        return;
      }
      handleAxiosError(e);
      return null;
    }
  }, []);

  const editSltpSetting = useCallback(async (positionId: number, stopLoss?: number, takeProfit?: number): Promise<UpdateStopLossResponse | null> => {
    const contextDisposedSignalRef = contextDisposeSignal.current;

    //Only cancel the call if the component disposes
    try {
      return await retryApiCall(() => orderApi.updateStopLoss(new UpdateStopLossRequest({ positionId, stopLoss, takeProfit })), contextDisposedSignalRef);
    } catch (e) {
      if (contextDisposedSignalRef.signal.aborted) {
        console.log('Aborted edit SLTP call');
        return { errorMessage: "API Call was aborted." } as UpdateStopLossResponse;
      }
      handleAxiosError(e);
      return { errorMessage: "An unknown error occured." } as UpdateStopLossResponse;
    }
  }, []);

  const cancelOrder = useCallback(async (order: OrderModel) => {
    const contextDisposedSignalRef = contextDisposeSignal.current;


    if (showConfirmationsRef.current) {
      const contract = getContractByProductName(order.symbolName);
      if (!contract) {
        console.error('Contract not found');
        return false;
      }

      const promptType = order.positionSize < 0 ? OrderPromptType.Sell : OrderPromptType.Buy;

      showModal(
        <ConfirmCancelOrderModal
          type={order.type}
          side={promptType}
          trailDistance={order.trailDistance}
          stopPrice={order.stopPrice}
          orderAmount={order.positionSize}
          contract={contract}
          limitPrice={order.limitPrice}
          onConfirm={async () => {
            hideModal();
            try {
              //Only cancel the call if the component disposes
              await retryApiCall(() => orderApi.cancelAccountOrderById(activeAccountIdRef.current, order.id), contextDisposedSignalRef);
            } catch (e) {
              if (contextDisposedSignalRef.signal.aborted) {
                console.log('Aborted cancel order call');
                return;
              }

              handleAxiosError(e);
            }
          }}
      />)

    } else {
      try {
        //Only cancel the call if the component disposes
        await retryApiCall(() => orderApi.cancelAccountOrderById(activeAccountIdRef.current, order.id), contextDisposedSignalRef);
      } catch (e) {
        if (contextDisposedSignalRef.signal.aborted) {
          console.log('Aborted cancel order call');
          return;
        }

        handleAxiosError(e);
      }
    }

    return true;
  }, [orders]);

  const cancelOrders = useCallback(async (orders: OrderModel[], skipConfirmation: boolean = false) => {

    if (!skipConfirmation && showConfirmationsRef.current) {
      const exOrder = orders[0];

      let totalPositionSize = 0;
      for (const order of orders) {
        totalPositionSize += order.positionSize
      }

      const contract = getContractByProductName(exOrder.symbolName);
      if (!contract) {
        console.error('Contract not found');
        return false;
      }

      const promptType = exOrder.positionSize < 0 ? OrderPromptType.Sell : OrderPromptType.Buy;

      showModal(
        <ConfirmCancelOrderModal
          type={exOrder.type}
          side={promptType}
          trailDistance={exOrder.trailDistance}
          stopPrice={exOrder.stopPrice}
          numOrders={orders.length}
          orderAmount={totalPositionSize}
          contract={contract}
          limitPrice={exOrder.limitPrice}
          onConfirm={async () => {
            hideModal();
            for (const order of orders) {
              orderApi.cancelAccountOrderById(activeAccountIdRef.current, order.id).catch((e) => {
                logException(e, 'Failed to cancel order');
              });
            }
          }}
      />)
    } else {
      try {
        for (const order of orders) {
          orderApi.cancelAccountOrderById(activeAccountIdRef.current, order.id).catch((e) => {
            logException(e, 'Failed to cancel order');
          });
        }
      } catch (e) {
        logException(e, 'Failed to cancel order');
      }
    }

    return true;
  }, []);

  const closeAllPositions = useCallback(async () => {
    const signalRef = contextDisposeSignal.current;
    try {
      //Only cancel the call if the component disposes
      await retryApiCall(() => positionApi.closeAll(activeAccountIdRef.current), signalRef);
    } catch (e) {
      if (signalRef.signal.aborted) {
        console.log('Aborted close all positions call');
        return;
      }

      handleAxiosError(e);
    }
  }, []);


  const closePosition = useCallback(async (position: PositionModel) => {
    const signalRef = contextDisposeSignal.current;

    const contract = getContractByProductId(position.symbolId);
    if (!contract) {
      console.error('Contract not found');
    }

    if (showConfirmationsRef.current) {
    showModal(
      <ConfirmCancelPositionModal
        contract={contract}
        amount={position.positionSize}
        onConfirm={async () => {
          hideModal();
          try {
            //Only cancel the call if the component disposes
            await retryApiCall(() => positionApi.close(activeAccountIdRef.current, position.symbolId), signalRef);
            return true;
          } catch (e) {
            logException(e, 'Failed to close position');
            handleAxiosError(e);
            return false;
          }
        }}
      />)
    } else {
      try {
        //Only cancel the call if the component disposes
        await retryApiCall(() => positionApi.close(activeAccountIdRef.current, position.symbolId), signalRef);
        return true;
      } catch (e) {
        logException(e, 'Failed to close position');
        handleAxiosError(e);
        return false;
      }
    }

  }, []);

  const placeOrderWithSymbolInternal = useCallback(
    async (data: FEOrderModel): Promise<PlaceOrderResponse> => {

      const tradingAccount = tradingAccounts.find((x) => x.accountId == activeAccountIdRef.current);

      if (tradingAccount?.lockoutExpiration) {
        if (visualAlerts.current) {
          toast(
            <>
              <p>Account has {tradingAccount.lockoutReason ?? ''} Lockout!</p>
              <p>Please review your personal risk settings.</p>
            </>,
            { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true }
          );
        }
      }

      const symbolMetadata = symbolsMetadataRef.current.get(data.symbol);

      const status = getStatus(symbolMetadata.productId);

      /*
      if (status && status.status === ExchangeStatus.PreOpen) {
        if (visualAlerts.current) {
          toast(
            <>
              <span>Order Rejected.</span>
              <br />
              <span>Market in Pre-Open status.</span>
            </>,
            { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true }
          );
        }

        return null;
      }
      */

      const tag = uuidv4();
      var order = new PlaceOrderRequest({
        accountId: activeAccountIdRef.current,
        positionSize: data.orderType === OrderPromptType.Buy ? data.amount : -data.amount,
        symbolId: data.symbol,
        type: data.type,
        trailDistance: data.trailDistance,
        limitPrice: data.type == OrderType.Limit || data.type == OrderType.StopLimit ? data.limitPrice : null,
        customTag: tag,
        stopPrice: data.type == OrderType.Stop || data.type == OrderType.StopLimit ? data.stopPrice : null
      });

      if (order.type == OrderType.Stop || order.type == OrderType.StopLimit) {
        if (!order.stopPrice) {
          if (visualAlerts.current) {
            toast('Stop Price is required for Stop Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
          }

          return;
        }
      }

      if (order.type == OrderType.Limit || order.type == OrderType.StopLimit) {
        if (!order.limitPrice && visualAlerts.current) {
          toast('Limit Price is required for Limit Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
          return;
        }
      }

      const baseMessage = `${order.positionSize > 0 ? '+' + order.positionSize : order.positionSize} ${symbolMetadata.productName} ${orderTypeMap[data.type]} ${data.type == OrderType.Limit ? ' @ ' + formatContractPrice(data.limitPrice, symbolMetadata) : ''}`;
      let toastId = null;

      if (visualAlerts.current) {
        toastId = toast(
          <div>
            <span style={{ fontWeight: 'bold' }}>{`Placing ${baseMessage}`}</span>
          </div>,
          {
            type: 'info',
            icon: () => '⏳',
            data: tag,
            pauseOnFocusLoss: false,
            hideProgressBar: true
          }
        );

        toasts.current.set(tag, toastId);
      }

      try {
        //Only cancel the call if the component disposes
        const res = await retryApiCall(async () => await orderApi.post(order), contextDisposeSignal.current);

        if (res.errorMessage) {
          if (visualAlerts.current && toastId) {
            toast.dismiss(toastId);
            toast.error(res.errorMessage);
          }
        }

        if (order.type == OrderType.Limit || order.type == OrderType.StopLimit) {
          if (!order.limitPrice && visualAlerts.current) {
            toast('Limit Price is required for Limit Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
            return;
          }
        }
      } catch (e) {
        logException(e, 'Failed to place order');

        const baseMessage = `${order.positionSize > 0 ? '+' + order.positionSize : order.positionSize} ${symbolMetadata.productName} ${orderTypeMap[data.type]} ${data.type == OrderType.Limit ? ' @ ' + formatContractPrice(data.limitPrice, symbolMetadata) : ''}`;
        let toastId = null;
        if (visualAlerts.current) {
          if (toastId) toast.dismiss(toastId);

          toast(
            <div>
              <span style={{ fontWeight: 'bold' }}>{`${baseMessage} Failed`}</span>
              <br />
              API Error.
            </div>,
            {
              icon: () => '❌',
              type: 'error',
              pauseOnFocusLoss: false,
              hideProgressBar: true
            }
          );
        }
      }
    },
    [activeTradingAccount]
  );

  const placeOrderWithSymbol = useCallback(
    async (data: FEOrderModel): Promise<PlaceOrderResponse> => {
      if (showConfirmationsRef.current) {
        const contract = getContractByProductId(data.symbol);

        return new Promise<PlaceOrderResponse>((resolve, reject) => {
          // Create a wrapper for the buyCallback
          const handleConfirm = async () => {
            try {
              const response = await placeOrderWithSymbolInternal(data);
              resolve(response);
            } catch (error) {
              reject(error);
            }
          };

          hideModal();

          showModal(
            <ConfirmOrderModal
              contract={contract}
              limitPrice={data.limitPrice}
              stopPrice={data.stopPrice}
              trailDistance={data.trailDistance}
              orderAmount={data.amount}
              type={data.type}
              side={data.orderType}
              onConfirm={handleConfirm}
            />
          );
        });
      } else {
        return await placeOrderWithSymbolInternal(data);
      }
    },
    [placeOrderWithSymbolInternal]
  );


  const changeOrderPrice = useCallback(async (id: number, price: number) => {
    //Only cancel the call if the component disposes
    return await retryApiCall(() => orderApi.editOrder(id, price), contextDisposeSignal.current);
  }, []);

  const editOrder = useCallback(async (id: number, data: FEOrderModel) => {
    // res = await api.post<FeOrderResult>(`/trades/pending/edit/${id}`, {});
    //   return res.data;

    const ret: FeOrderResult = {
      error: 'NOT IMPLEMENTED',
      success: false
    };
    return ret;
  }, []);

  const filteredOrders = useMemo(() => {
    if (!orders || !orders.length) return [];
    if (!sltpOrders.length) return orders;
    const filteredOrders = orders.filter((o) => !sltpOrders.includes(o.id));
    return filteredOrders;
  }, [orders, sltpOrders]);

  const activePositionWithPnl = useMemo(() => {
    return positionsWithPnl.filter((p) => p.accountId == activeTradingAccount.accountId);
  }, [positionsWithPnl, activeTradingAccount]);

  const rawActivePositions = useMemo(() => {
    return positions.filter((p) => p.accountId == activeTradingAccount.accountId);
  }, [positions, activeTradingAccount]);

  const linkOrders = useCallback((tradingAccountId: number, orderIds: number[]) => {
    //Only cancel the call if the component disposes
    return retryApiCall(() => linkedOrderApi.linkOrders(new LinkOrderUserRequest({ tradingAccountId, orderIds })), contextDisposeSignal.current);
  }, []);

  const unlinkOrders = useCallback((tradingAccountId: number, orderGroupId: string) => {
    //Only cancel the call if the component disposes
    return retryApiCall(() => linkedOrderApi.unlinkOrders(new UnlinkOrderUserRequest({ groupId: orderGroupId, tradingAccountId })), contextDisposeSignal.current);
  }, []);

  const unrealizedPnl = useMemo(() => {
    let totalPnl = 0;
    for (const pos of activePositionWithPnl) {
      totalPnl += pos.profitAndLoss;
    }

    return totalPnl;
  }, [activePositionWithPnl]);

  const unrealizedBalance = useMemo(() => {
    if (!activeTradingAccount) return 0;

    return activeTradingAccount.balance + unrealizedPnl;
  }, [activeTradingAccount, unrealizedPnl]);

  const highestUnrealizedBalance = useMemo(() => {
    return Math.max(unrealizedBalance, activeTradingAccount.highestUnrealizedBalance);
  }, [activeTradingAccount.highestUnrealizedBalance, unrealizedBalance]);

  useEffect(() => {
    if (!activeTradingAccount) return;
    activeTradingAccount.highestUnrealizedBalance = highestUnrealizedBalance;
  }, [unrealizedBalance, highestUnrealizedBalance]);

  useEffect(() => {
    if (!activeTradingAccount) return;
    activeTradingAccount.openPnl = unrealizedPnl;
  }, [activeTradingAccount, unrealizedPnl]);

  const values = useMemo<IOrdersContext>(() => {
    return {
      sltpSettings,
      cancelOrder,
      cancelOrders,
      editRiskToMake,
      placeOrderWithSymbol,
      changeOrderPrice,
      closePosition,
      editOrder,
      editSltpSetting,
      cancelAll,
      orders: orders,
      nonSltpOrders: filteredOrders,
      activePositions: activePositionWithPnl,
      rawActivePositions: rawActivePositions,
      allPositions: positionsWithPnl,
      reversePosition,
      trades,
      closeAllPositions,
      linkedOrders,
      unlinkOrders,
      linkOrders,
      highestUnrealizedBalance,
      unrealizedBalance,
      unrealizedPnl
    };
  }, [unrealizedPnl, highestUnrealizedBalance, unrealizedBalance, sltpSettings, orders, positions, sltpOrders, activeTradingAccount, filteredOrders, activePositionWithPnl, positionsWithPnl, rawActivePositions, trades, linkedOrders]);

  return <OrdersContext.Provider value={values}>{children}</OrdersContext.Provider>;
}

export default OrderContextProvider;
