import { Box, Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import {
  ExpandLess,
  ExpandMore,
  Payments,
  TableView,
  ReceiptLong,
  Person,
  Info,
  Assessment,
  RequestQuote,
  Shuffle,
  CopyAll,
  PrivacyTip,
  Logout,
  PeopleAlt} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MobileAccountPages, MobileSettingsPages, MobileTradingPages, NavRoutes } from '@/data/NavRoutes';
import { useAuth } from '@/contexts/AuthContext';
import { config } from '@/config';
import './mobileTopbar.css';
import styles from './UserLayout.module.scss';

const ITEM_HEIGHT = 48;

const DrawerComponent: React.FC<{ closeDrawer: () => void }> = ({ closeDrawer }) => {
  const nav = useNavigate();
  const { logOut } = useAuth();
  const currentYear = new Date().getFullYear();

  const [drawerOpen, setDrawerOpen] = React.useState(true);

  // Get current page (end of url)
  const [page, setPage] = React.useState(window.location.pathname.split('/').pop());

  useEffect(() => {
    setPage(window.location.pathname.split('/').pop());
  }, [window.location.pathname]);

  const checkIfInCategory = (checkPage: string, category: NavRoutes[]) => {
    for (let i = 0; i < category.length; i++) {
      if (checkPage === category[i]) {
        return true;
      }
    }

    return false;
  };

  const checkIfCurrentCategory = (category: NavRoutes[]) => {
    return checkIfInCategory(page, category);
  };

  const checkIfCurrentPage = (checkPage: NavRoutes) => {
    return page === checkPage;
  };

  const getNavColor = (checkPage: NavRoutes) => {
    return checkIfCurrentPage(checkPage) ? 'rgb(235, 188, 77)' : 'white';
  };

  const [tradingOpen, setTradingOpen] = React.useState(checkIfCurrentCategory(MobileTradingPages));
  const [accountOpen, setAccountOpen] = React.useState(checkIfCurrentCategory(MobileAccountPages));
  const [settingsOpen, setSettingsOpen] = React.useState(checkIfCurrentCategory(MobileSettingsPages));

  const handleClose = () => {
    setDrawerOpen(false);
    closeDrawer()
  };

  const handleTradingClick = () => {
    setTradingOpen(!tradingOpen);
  };

  const handleAccountClick = () => {
    setAccountOpen(!accountOpen);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleNav = (route: string) => {
    nav(route);
    const newPage = route.split('/').pop();
    setPage(newPage);

    // Determine what category the page belongs to
    const inTrade = checkIfInCategory(newPage, MobileTradingPages);
    const inAccount = checkIfInCategory(newPage, MobileAccountPages);
    const inSettings = checkIfInCategory(newPage, MobileSettingsPages);

    // If it's in a category, set local storage for that category to the page
    if (inTrade) {
      localStorage.setItem('mobileTradePage', newPage);
    } else if (inAccount) {
      localStorage.setItem('mobileAccountPage', newPage);
    } else if (inSettings) {
      localStorage.setItem('mobileSettingsPage', newPage);
    }

    // Set last mobile path
    localStorage.setItem('lastMobilePath', route);

    handleClose();
  };



  useEffect(() => {
    setTradingOpen(checkIfCurrentCategory(MobileTradingPages));
    setAccountOpen(checkIfCurrentCategory(MobileAccountPages));
    setSettingsOpen(checkIfCurrentCategory(MobileSettingsPages));
  }, [page]);

  const getNavButton = (title: string, category: NavRoutes, page: NavRoutes, icon: ReactNode, shift: number = 4) => {
    const isCurrentPage = checkIfCurrentPage(page);

    const color = isCurrentPage ? 'rgb(235, 188, 77)' : 'white';
    const bgColor = isCurrentPage ? 'rgb(255, 255, 255, 0.1)' : 'transparent';

    return (
      <MenuItem sx={{ backgroundColor: bgColor }} onClick={() => handleNav('/' + category + '/' + page)}>
        <ListItemIcon sx={{ pl: shift, color: color }}>{icon}</ListItemIcon>
        <ListItemText sx={{ pl: 3 }} primaryTypographyProps={{ fontSize: '1.3em', marginTop: '0.1em', color: color }} primary={title} />
      </MenuItem>
    );
  };

  return (
    <Box sx={{ width: '100vw', background: '#1c1e23' }} flexDirection='row' display='flex'>
      <Drawer open={drawerOpen} onClose={handleClose} sx={{ zIndex: 99999999999 }}>
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubheader component='div' id='nested-list-subheader'>
              <img src={config.platformLoginLogo} style={{ height: 20 }} />
            </ListSubheader>
          }
          sx={{ width: '70vw' }}
        >
          <ListItemButton onClick={handleAccountClick}>
            <ListItemIcon>
              <Person fontSize='large' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '1.5em' }} primary='Account' />
            {accountOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={accountOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {getNavButton('Info', NavRoutes.MobileAccount, NavRoutes.MobileAccountInfo, <Info fontSize='large' />)}
              {getNavButton('All Accounts', NavRoutes.MobileAccount, NavRoutes.MobileAccounts, <PeopleAlt fontSize='large' />)}
              {getNavButton('Positions', NavRoutes.MobileAccount, NavRoutes.MobilePositions, <Assessment fontSize='large' />)}
              {getNavButton('Orders', NavRoutes.MobileAccount, NavRoutes.MobileOrders, <RequestQuote fontSize='large' />)}
              {getNavButton('Trades', NavRoutes.MobileAccount, NavRoutes.MobileTrades, <Shuffle fontSize='large' />)}
            </List>
          </Collapse>
          <ListItemButton onClick={handleTradingClick}>
            <ListItemIcon>
              <Payments fontSize='large' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '1.5em' }} primary='Trading' />
            {tradingOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={tradingOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {getNavButton('DOM', NavRoutes.MobileTrading, NavRoutes.MobileDom, <TableView fontSize='large' />)}
              {getNavButton('Order Card', NavRoutes.MobileTrading, NavRoutes.MobileOrder, <ReceiptLong fontSize='large' />)}
              {getNavButton('Copy Trading', NavRoutes.MobileSettings, NavRoutes.MobileCopyTrading, <CopyAll fontSize='large' />)}
              {getNavButton('Risk Settings', NavRoutes.MobileSettings, NavRoutes.MobileRiskSettings, <PrivacyTip fontSize='large' />)}
              {/*getNavButton('Tilt', NavRoutes.MobileTrading, NavRoutes.MobileTilt, <CrisisAlert fontSize='large' />)*/}
              {/*getNavButton('Quotes', NavRoutes.MobileTrading, NavRoutes.MobileQuotes, <PriceCheck fontSize='large' />)*/}
              {/*getNavButton('Time & Sales', NavRoutes.MobileTrading, NavRoutes.MobileTimeAndSales, <WorkHistory fontSize='large' />)*/}
            </List>
          </Collapse>
          {/*
          <ListItemButton onClick={handleSettingsClick}>
            <ListItemIcon>
              <Settings fontSize='large' />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: '1.5em' }} primary='Settings' />
            {settingsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={settingsOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {getNavButton('DOM', NavRoutes.MobileSettings, NavRoutes.MobileDomSettings, <TableView fontSize='large' />)}
              {getNavButton('Chart', NavRoutes.MobileSettings, NavRoutes.MobileChartSettings, <StackedLineChart fontSize='large' />)}
              {getNavButton('Risk', NavRoutes.MobileSettings, NavRoutes.MobileRiskSettings, <PrivacyTip fontSize='large' />)}
              {getNavButton('Copy Trading', NavRoutes.MobileSettings, NavRoutes.MobileCopyTrading, <CopyAll fontSize='large' />)}
              {getNavButton('Misc', NavRoutes.MobileSettings, NavRoutes.MobileMiscSettings, <MiscellaneousServices fontSize='large' />)}
            </List>
          </Collapse>
          */}
          <MenuItem onClick={logOut}>
            <ListItemIcon>
              <Logout fontSize='large' />
            </ListItemIcon>
            <ListItemText sx={{ pl: 3 }} primaryTypographyProps={{ fontSize: '1.3em', marginTop: '0.1em' }} primary='Log Out' />
          </MenuItem>
        </List>
        <div className={styles.drawerFooterText}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            Licensed to {config.company} & Powered by ProjectX.
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            Copyright 2024-{currentYear} Sim2FundedSolutions, LLC</div>
        </div>
      </Drawer>
    </Box>
  );
};

export default DrawerComponent;
