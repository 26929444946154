import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useOrders } from '../../contexts/OrdersContext';
import { Tooltip } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { DefaultUPnLNegative, DefaultUPnLPositive } from './settingsDefault';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const UnrealizedPnl: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { activePositions } = useOrders();
  const { customSettings } = useSettings();
  const { isMobile } = useDeviceContext();
  const totalPnl = useMemo(() => {
    return activePositions
      .map((y) => y.profitAndLoss)
      .reduce((acc, pos) => {
        return acc + pos;
      }, 0);
  }, [activePositions]);

  const walletBalanceStyle = useMemo(() => {
    if (totalPnl > 0) {
      return { backgroundColor: customSettings.UPnLPositiveColor || DefaultUPnLPositive};
    } else if (totalPnl < 0) {
      return { backgroundColor: customSettings.UPnLNegativeColor || DefaultUPnLNegative};
    } else {
      return {};
    }
  }, [totalPnl, customSettings.UPnLPositiveColor, customSettings.UPnLNegativeColor]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  return useMemo(
    () => (
      <div
        className={classNames(
          isMobile ? styles.balanceMobile : styles.balance,
          totalPnl === 0 && (props.variant === 'dark' ? styles.dark : styles.light)
        )}
        style={{ ...walletBalanceStyle, padding: props.padding }}
      >
        <Tooltip title='Unrealized P&L: The potential profit or loss on your open positions, based on the current market price.'>
          <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
            <span>UP&L: </span>
            <span>{customSettings.streamerModeUPL ? '******' : `$${formatPrice(totalPnl)}`}</span>
          </div>
        </Tooltip>
      </div>
    ),
    [totalPnl, customSettings.streamerModeUPL, customSettings.topNavTextSize, customSettings.UPnLPositiveColor, customSettings.UPnLNegativeColor, isMobile]
  );
};

export default React.memo(UnrealizedPnl);
