import React, { useMemo } from 'react';
import { NotificationModalWithTip } from '@/components/topstep/notificationModal';
import { openInNewTab } from '@/helpers/urlHelper';
import CoachTip from '@/views/trader/components/notifications/CoachTip';
import { ActiveViolationModel, ViolationDuration } from '@/api/userApi';
import { Typography } from '@mui/material';
import { StyledLink } from '@/components/styledComponents';
import { TradingPlatforms as TradingPlatform, config } from '@/config';
import { formatPrice } from '@/helpers/formatter';
interface DailyLossLimitModalProps {
  violation: ActiveViolationModel;
  accountName: string;
  onHide: () => void;
}
const DailyLossLimitModal: React.FC<DailyLossLimitModalProps> = (props) => {
  const link = useMemo(() => {
    switch (config.platform) {
      case TradingPlatform.TopstepX:
        return (
          <StyledLink target='_blank' href=' https://intercom.help/topstep-llc/en/articles/8284207-what-is-the-daily-loss-limit-and-what-happens-if-i-exceed-it'>
            Daily Loss Limit
          </StyledLink>
        );
      default:
        return 'Daily Loss Limit';
    }
  }, [config.platform]);

  const resources = useMemo(() => {
    switch (config.platform) {
      case TradingPlatform.TopstepX:
        return (
          <>
            <b>Free resources</b>: We're here for you every step of your trading journey. Join us for{' '}
            <StyledLink target='_blank' href='https://www.topstep.tv/'>
              TopstepTV™
            </StyledLink>{' '}
            Monday-Friday, from 8:00am - 3:00pm CT, and join our{' '}
            <StyledLink target='_blank' href='https://discord.com/invite/topstep'>
              Discord
            </StyledLink>{' '}
            channel to find a trading buddy or chat with a Topstep coach!
          </>
        );
      default:
        return '';
    }
  }, [config.platform]);

  const upnlMessage = useMemo(() => {
    if (!props.violation) return <></>;
    if (props.violation.violationTotalDayPnl < 0) {
      return (
        <span>
          Your UP&L was{' '}
          <Typography
            sx={(theme) => {
              return { display: 'inline', color: theme.palette.error.main, fontSize: '1em', fontWeight: 'bold' };
            }}
          >
            ${formatPrice(props.violation.violationTotalDayPnl)}
          </Typography>{' '}
          at time of the liquidation trigger (<strong>{props.violation.triggeredAt.local().format('DD/MM/YYYY h:mm:ss A')}</strong>). Note: Actual liquidation value may differ from the trigger value
        </span>
      );
    }
    return <></>;
  }, [props.violation.violationTotalDayPnl, props.violation.triggeredAt]);

  return useMemo(() => {
    var children = <div></div>;
    var header = '';
    if (props.violation.duration === ViolationDuration.Permanent) {
      header = 'Account ' + props.accountName + ': Ineligible for Funding';
      children = (
        <div>
          <p>
            {config.platform === TradingPlatform.TopstepX ? (
              <>
                Your account is now <b>ineligible for funding</b> due to violating our 1 Rule,{' '}
                <StyledLink target='_blank' href='https://help.topstep.com/en/articles/8284204-what-is-the-maximum-loss-limit'>
                  Daily Loss Limit
                </StyledLink>
                . Restart your funding evaluation journey by beginning a new Trading Combine on your Topstep Dashboard.
              </>
            ) : (
              <>
                Your account is now <b>ineligible for funding</b> due to violating the Daily Loss Limit. We have closed all open positions & working orders.
              </>
            )}
          </p>
          <p>{upnlMessage}</p>
          {resources}
        </div>
      );
    } else {
      header = 'Account ' + props.accountName + ': Temporarily Deactivated';
      children = (
        <div>
          <p>
            Your account has been <b>temporarily deactivated</b> for exceeding the {link}. {upnlMessage}
          </p>
          <p> This blocks any further trading for the remainder of the day. Don't worry, you'll be able to resume trading at the start of the next day.</p>
          {resources}
        </div>
      );
    }

    return (
      <NotificationModalWithTip
        footer={<CoachTip />}
        header={header}
        onClickOutline={props.onHide}
        onClickBlue={() => openInNewTab('https://app.topsteptrader.com/dashboard')}
        buttonLabelOutline={'DISMISS THIS NOTIFICATION'}
        buttonLabelBlue={'VISIT MY TOPSTEP TRADING DASHBOARD'}
        platform={config.platform}
      >
        {children}
      </NotificationModalWithTip>
    );
  }, [props.accountName, props.onHide, upnlMessage]);
};

export default React.memo(DailyLossLimitModal);
