import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SessionValidationResult, SignAgreementRequest, UserAgreementModel, UserModel } from '../api/userApi';
import { useApi } from './ApiContext';
import * as Sentry from '@sentry/react';
import { logException } from '@/helpers/exceptionHelper';

export type AddressInfo = {
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  countryCode: string;
};

import { handleAxiosErrorWithCallback } from '@/helpers/axiosHelper';
export interface IAuthContext {
  loggedIn: boolean;
  logOut: () => void;
  refresh: () => void;
  requiredAgreements: UserAgreementModel[];
  signAgreements: (agreements: number[], name: string, address: AddressInfo) => Promise<void>;
  userId?: number;
  userInfo: UserModel;
  token: string;
  sessionId?: string;
}

export const AuthContext = React.createContext<IAuthContext>({} as any);
export const useAuth = () => React.useContext<IAuthContext>(AuthContext);

function AuthContextProvider({ children }: any) {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserModel>(null);
  const { userApi, agreementsApi, token, setToken, sessionApi, axiosInstance, retryApiCall } = useApi();
  const [requiredAgreements, setRequiredAgreements] = useState<UserAgreementModel[]>([]);
  const [lastValidation, setLastValidation] = useState<number>(0);
  const [validated, setValidated] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>(null);

  const componentDisposedSignal = useRef(new AbortController());

  useEffect(() => {
    return () => {
      componentDisposedSignal.current.abort();
    };
  }, []);

  const signAgreements = useCallback(
    (agreements: number[], name: string, addressInfo: AddressInfo) => {
      return agreementsApi.sign(new SignAgreementRequest({
        agreementIds: agreements,
        name,
        streetAddress: addressInfo.streetAddress,
        city: addressInfo.city,
        state: addressInfo.state,
        zipCode: addressInfo.zipCode,
        country: addressInfo.country,
        countryCode: addressInfo.countryCode
      })).then((data) => {
        setRequiredAgreements(data);
      });
    },
    [agreementsApi]
  );

  const refresh = useCallback(() => {
    const signalRef = componentDisposedSignal.current;
    retryApiCall(() => agreementsApi.getRequired(), signalRef)
      .then((data) => {
        if (signalRef.signal.aborted) return;
        setRequiredAgreements(data);
      })
      .catch((err) => {
        logException(err, 'Unable to get agreements');
        console.warn('Unable to get agreements');
      });

    retryApiCall(() => userApi.get(), signalRef)
      .then((data) => {
        if (signalRef.signal.aborted) return;

        setUserInfo(data);
        setLoggedIn(true);
        setLoaded(true);
      })
      .catch((err) => {
        logException(err, 'Unable to get user info');
        setLoggedIn(false);
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        //if we get a 401 or 403, try to refresh the token - the initial call could have failed because of an expired token
        if (error.response?.status === 401 || error.response?.status === 403) {
          retryApiCall(() => sessionApi.validate())
            .then((data) => {
              if (data.result == SessionValidationResult.Success) {
                console.log('Token Refreshed successfully');
                setValidated(true);
                setToken(data.token);
                setSessionId(data.sessionId);
              } else {
                console.log('Token Refresh failed, logging out.');
                logOut();
              }
            })
            .catch((e) =>
              handleAxiosErrorWithCallback(e, (ex) => {
                Promise.reject(ex);
              })
            );
        }
        return Promise.reject(error);
      }
    );
  }, [axiosInstance]);

  useEffect(() => {
    const signal = componentDisposedSignal.current;

    if (!token) {
      setValidated(false);
      return;
    }

    if (validated) {
      return;
    }

    retryApiCall(() => sessionApi.validate(), signal)
      .then((data) => {
        if (signal.signal.aborted) return;

        if (data.result == SessionValidationResult.Success) {
          setValidated(true);
          setToken(data.token);
          setSessionId(data.sessionId);
        } else {
          logOut();
        }
      })
      .catch((e) => {
        console.log('Unable to validate session', e);
        handleAxiosErrorWithCallback(e, () => {
          logOut();
        });
      });
  }, [token, validated]);

  useEffect(() => {
    if (!validated) return;
    const signal = componentDisposedSignal.current;

    //revalidate once an hour
    const timeout = setTimeout(
      () => {
        retryApiCall(() => sessionApi.validate(), signal)
          .then((data) => {
            if (signal.signal.aborted) return;

            if (data.result == SessionValidationResult.Success) {
              setValidated(true);
              setToken(data.token);
              setSessionId(data.sessionId);
            } else {
              logOut();
            }
          })
          .catch((e) => {
            console.log('Unable to revalidate session', e);
            handleAxiosErrorWithCallback(e, (ex) => {
              logOut();
            });
          });
      },
      1000 * 60 * 60
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [token, validated]);

  useEffect(() => {
    if (token) {
      if (!validated) return;

      const signal = componentDisposedSignal.current;

      Promise.all([retryApiCall(() => agreementsApi.getRequired(), signal), retryApiCall(() => userApi.get(), signal), retryApiCall(() => userApi.getIntercomHash(), signal)])
        .then(([agreements, user, intercomHash]) => {
          if (signal.signal.aborted) return;

          setRequiredAgreements(agreements);
          setUserInfo(user);
          setLoggedIn(true);
          setLoaded(true);
          Sentry.setUser({
            email: user.email,
            username: user.userName,
            id: user.userId + ''
          });

          var wnd = window as any;
          if (wnd.Intercom) {
            //update intercom email;
            wnd.Intercom('update', {
              name: user.firstName + ' ' + user.lastName,
              email: user.email,
              user_id: user.userId,
              user_hash: intercomHash
            });
          }
        })
        .catch((err) => {
          if (signal.signal.aborted) return;
          console.log('Unable to get user info, logging out', err);
          Sentry.setUser(null);
          setLoggedIn(false);
          setLoaded(true);
        });
    } else {
      setLoaded(true);
      return () => setLoaded(false);
    }
  }, [token, validated]);

  const logOut = useCallback(() => {
    console.log('Logging out');
    setToken(null);
    setLoggedIn(false);
    Sentry.setUser(null);
  }, []);

  const values = useMemo(() => {
    return {
      loggedIn,
      logOut,
      token,
      refresh,
      userId: userInfo?.userId,
      userInfo,
      requiredAgreements,
      signAgreements,
      sessionId
    };
  }, [token, userInfo, loggedIn, requiredAgreements, sessionId]);

  return <AuthContext.Provider value={values}>{loaded && children}</AuthContext.Provider>;
}

export default AuthContextProvider;
