import React, { useMemo } from 'react';
import { NotificationModalWithTip } from '@/components/topstep/notificationModal';
import CoachTip from '@/views/trader/components/notifications/CoachTip';
import { ActiveViolationModel } from '@/api/userApi';
import { useNavigate } from 'react-router-dom';
import { NavRoutes } from '@/data/NavRoutes';
import { StyledLink } from '@/components/styledComponents';
import { TradingPlatforms as TradingPlatform, config } from '@/config';
import { Typography } from '@mui/material';
import { formatPrice } from '@/helpers/formatter';

interface PersonalDailyLossLimitModalProps {
  violation: ActiveViolationModel;
  accountName: string;
  onHide: () => void;
}
const PersonalDailyProfitTargetModal: React.FC<PersonalDailyLossLimitModalProps> = (props) => {
  const navigate = useNavigate();

  const upnlMessage = useMemo(() => {
    if (!props.violation) return <></>;
    if (props.violation.violationTotalDayPnl < 0) {
      return (
        <span>
          Your UP&L was{' '}
          <Typography
            sx={(theme) => {
              return { display: 'inline', color: theme.palette.error.main, fontSize: '1em', fontWeight: 'bold' };
            }}
          >
            ${formatPrice(props.violation.violationTotalDayPnl)}
          </Typography>{' '}
          at time of the liquidation trigger (<strong>{props.violation.triggeredAt.local().format('DD/MM/YYYY h:mm:ss A')}</strong>). Note: Actual liquidation value may differ from the trigger value
        </span>
      );
    }
    return <></>;
  }, [props.violation.violationTotalDayPnl, props.violation.triggeredAt]);

  return useMemo(() => {
    const header = 'Account ' + props.accountName + ': Temporarily Deactivated (Personal Daily Profit Target)';
    const buttonLabelOutline = 'DISMISS THIS NOTIFICATION';
    const buttonLabelBlue = 'PERSONAL DAILY PROFIT TARGET SETTINGS';
    return (
      <NotificationModalWithTip
        header={header}
        footer={<CoachTip />}
        onClickBlue={() => {
          navigate(NavRoutes.Settings);
          props.onHide();
        }}
        onClickOutline={props.onHide}
        buttonLabelOutline={buttonLabelOutline}
        buttonLabelBlue={buttonLabelBlue}
      >
        <div>
          <p>
            Congrats on hitting your <b>Personal Daily Profit Target</b>! Your open positions have been closed and and your working orders have been canceled. See you the next trading day!
          </p>
          <p>{upnlMessage}</p>
          {config.platform === TradingPlatform.TopstepX && (
            <p>
              <b>Free resources</b>: We're here for you every step of your trading journey. Join us for{' '}
              <StyledLink target='_blank' href='https://www.topstep.tv/'>
                TopstepTV™
              </StyledLink>{' '}
              Monday-Friday, from 8:00am - 3:00pm CT, and join our{' '}
              <StyledLink target='_blank' href='https://discord.com/invite/topstep'>
                Discord
              </StyledLink>{' '}
              channel to find a trading buddy or chat with a Topstep coach!
            </p>
          )}
        </div>
      </NotificationModalWithTip>
    );
  }, [props.accountName, props.onHide, upnlMessage]);
};

export default React.memo(PersonalDailyProfitTargetModal);
