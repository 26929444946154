import React, { useMemo } from 'react';
import { NotificationModalWithTip } from '@/components/topstep/notificationModal';
import { openInNewTab } from '@/helpers/urlHelper';
import CoachTip from '@/views/trader/components/notifications/CoachTip';
import { ActiveViolationModel, ITradingAccountModel, TradingAccountType } from '@/api/userApi';
import { StyledLink } from '@/components/styledComponents';
import { TradingPlatforms as TradingPlatform, config } from '@/config';
import { Typography } from '@mui/material';
import { formatPrice } from '@/helpers/formatter';

interface MaximumLossLimitModalProps {
  violation: ActiveViolationModel;
  account: ITradingAccountModel;
  onHide: () => void;
}

const getAccountName = (account: ITradingAccountModel): string => {
  if (account) {
    if (account.nickname) {
      return account.nickname + ' (' + account.accountName + ')';
    } else {
      return account.accountName;
    }
  } else {
    return 'Unknown Account';
  }
};

const MaximumLossLimitModal: React.FC<MaximumLossLimitModalProps> = (props) => {
  const upnlMessage = useMemo(() => {
    if (!props.violation) return <></>;
    if (props.violation.violationTotalDayPnl < 0) {
      return (
        <span>
          Your UP&L was{' '}
          <Typography
            sx={(theme) => {
              return { display: 'inline', color: theme.palette.error.main, fontSize: '1em', fontWeight: 'bold' };
            }}
          >
            ${formatPrice(props.violation.violationTotalDayPnl)}
          </Typography>{' '}
          at time of the liquidation trigger (<strong>{props.violation.triggeredAt.local().format('DD/MM/YYYY h:mm:ss A')}</strong>). Note: Actual liquidation value may differ from the trigger value
        </span>
      );
    }
    return <></>;
  }, [props.violation.violationTotalDayPnl, props.violation.triggeredAt]);

  return useMemo(() => {
    var children = <div></div>;
    var header = '';
    if (props.account.type == TradingAccountType.Express) {
      header = 'Account ' + getAccountName(props.account) + ': Rule Violation';
      children = (
        <div>
          <p>
            {config.platform === TradingPlatform.TopstepX ? (
              <>
                Your account is now <b>closed</b> due to violating our 1 Rule,{' '}
                <StyledLink target='_blank' href='https://help.topstep.com/en/articles/8284204-what-is-the-maximum-loss-limit'>
                  Maximum Loss Limit
                </StyledLink>
                . Restart your funding evaluation journey by beginning a new Trading Combine on your Topstep Dashboard.
              </>
            ) : (
              <>
                Your account is now <b>closed</b> due to violating the Maximum Loss Limit.
              </>
            )}
          </p>
          <p>{upnlMessage}</p>
          {config.platform === TradingPlatform.TopstepX && (
            <p>
              <b>Free resources</b>: We're here for you every step of your trading journey. Join us for{' '}
              <StyledLink target='_blank' href='https://www.topstep.tv/'>
                TopstepTV™
              </StyledLink>{' '}
              Monday-Friday, from 8:00am - 3:00pm CT, and join our{' '}
              <StyledLink target='_blank' href='https://discord.com/invite/topstep'>
                Discord
              </StyledLink>{' '}
              channel to find a trading buddy or chat with a Topstep coach!
            </p>
          )}
        </div>
      );
    } else {
      header = 'Account ' + getAccountName(props.account) + ': Ineligible for Funding';
      children = (
        <div>
          <p>
            {config.platform === TradingPlatform.TopstepX ? (
              <>
                Your account is now <b>ineligible for funding</b> due to violating our 1 Rule,{' '}
                <StyledLink target='_blank' href='https://help.topstep.com/en/articles/8284204-what-is-the-maximum-loss-limit'>
                  Maximum Loss Limit
                </StyledLink>
                . We have closed all open positions & working orders. Your account is ineligible until it is Reset.
              </>
            ) : (
              <>
                <>
                  Your account is now <b>ineligible for funding</b> due to violating the Maximum Loss Limit. We have closed all open positions & working orders.
                </>
              </>
            )}
          </p>
          <p>{upnlMessage}</p>
          {config.platform === TradingPlatform.TopstepX && (
            <p>
              <b>Free resources</b>: We're here for you every step of your trading journey. Join us for{' '}
              <StyledLink target='_blank' href='https://www.topstep.tv/'>
                TopstepTV™
              </StyledLink>{' '}
              Monday-Friday, from 8:00am - 3:00pm CT, and join our{' '}
              <StyledLink target='_blank' href='https://discord.com/invite/topstep'>
                Discord
              </StyledLink>{' '}
              channel to find a trading buddy or chat with a Topstep coach!
            </p>
          )}
        </div>
      );
    }
    return (
      <NotificationModalWithTip
        header={header}
        footer={<CoachTip />}
        onClickOutline={props.onHide}
        onClickBlue={() => openInNewTab('https://app.topsteptrader.com/dashboard')}
        buttonLabelOutline={'DISMISS THIS NOTIFICATION'}
        buttonLabelBlue={'VISIT MY TOPSTEP TRADING DASHBOARD'}
        platform={config.platform}
      >
        {children}
      </NotificationModalWithTip>
    );
  }, [props.account, props.onHide, upnlMessage]);
};

export default React.memo(MaximumLossLimitModal);
