import dayjs from 'dayjs';
import { HistoryClient, TradeLogData } from '@/api/dataApi';
import { logMessage } from 'datafeeds2/lib/helpers';
import { getLastBarTime, toTradeDay } from './MarketHelper';
import { log, time } from 'console';
import { IChartingLibraryWidget } from '@/datafeed/dataFeedTypes';
import { MutableRefObject } from 'react';
import { Settings } from '@/contexts/SettingsContext';
import { networkSpeed } from '@/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { IChartWidgetApi } from '@/charting_library/charting_library';

interface SubscriptionRecord {
  lastBar: any;
  lastBarTime: any;
  listener: (data: any) => void;
  resolution: string;
  symbolInfo: any;
  callbackId?: number;
  tradeCallbackId?: number;
  isNewBar: boolean;
  guid: string;
  tickVolume: number;
  unsubscribed: boolean;

  publishTimeout: any;
}

interface ChartBarData {
  time?: number;
  close?: number;
  open?: number;
  high?: number;
  low?: number;
  volume?: number;
  tickVolume?: number;
  success: boolean;
}

const logChart = (msg: string, ...params) => {
  console.log(`[${new Date().toISOString()}][ChartPulse] ` + msg, params);
};

const getSpeed = () => {
  switch (Settings.customSettings.networkSpeedNew) {
    case networkSpeed.Low:
      return 750;
    case networkSpeed.Medium:
      return 250;
    case networkSpeed.High:
      return 100;
  }

  return 250;
};
export class DataPulseProvider {
  private _historyClient: HistoryClient;
  private _chartDatasource: string;
  private _subscribers: { [guid: string]: SubscriptionRecord };
  private _tradeSubs: any;
  private _subscribeFunc: (symbolInfo: number, resolution: string, guid: string, callback: (data: any) => void) => number;
  private _unsubscribeFunc: (guid: string, callbackId: number) => void;
  private _subscribeTrades: (cqgSymbolName: string, callback: (data: TradeLogData[]) => void) => number;
  private _unsubscribeTrades: (cqgSymbolName: string, callbackId: number) => void;
  private _subscribeChartDataSource: (callback: (data: string) => void) => number;
  private _unsubscribeChartDataSource: (callbackId: number) => void;
  private _widget: MutableRefObject<IChartingLibraryWidget>;
  private _live: boolean;
  private _isMobile: boolean;

  constructor(
    widget: MutableRefObject<IChartingLibraryWidget>,
    chartDatasource: string,
    subscribeBars: (symbolInfo, resolution, guid, callback: (data: any) => void) => number,
    unsubscribeBars: (guid: string, callbackId: number) => void,
    subscribeTrades: (cqgSymbolName: string, callback: (data: TradeLogData[]) => void) => number,
    unsubscribeTrades: (cqgSymbolName: string, callbackId: number) => void,
    subscribeChartDataSource: (callback: (data: string) => void) => number,
    unsubscribeChartDataSource: (callbackId: number) => void,
    historyClient: HistoryClient,
    mobile: boolean,
    live: boolean
  ) {
    logMessage('Setting up DataPulseProvider with datasource: ' + chartDatasource);
    this._widget = widget;
    this._chartDatasource = chartDatasource;
    this._subscribeFunc = subscribeBars;
    this._unsubscribeFunc = unsubscribeBars;
    this._subscribeTrades = subscribeTrades;
    this._unsubscribeTrades = unsubscribeTrades;
    this._subscribeChartDataSource = subscribeChartDataSource;
    this._unsubscribeChartDataSource = unsubscribeChartDataSource;
    this._isMobile = mobile;
    this._subscribers = {};
    this._historyClient = historyClient;
    this._live = live;

    // subscribe to data source changes
    this._subscribeChartDataSource((data) => {
      // clone subscribers
      const oldSubscribers = { ...this._subscribers };
      // unsubscribe all subscribers
      for (const listenerGuid in this._subscribers) {
        this.unsubscribeBars(listenerGuid);
      }

      this._chartDatasource = data;
      logMessage('Data source changed to: ' + data);

      this._subscribers = {};

      // re-subscribe all subscribers
      for (const listenerGuid in oldSubscribers) {
        const subscriber = oldSubscribers[listenerGuid];
        this.subscribeBars(subscriber.symbolInfo, subscriber.resolution, subscriber.listener, listenerGuid);
      }
    });
  }

  getChartForSymbolAndResolution(symbol: string, resolution: string) {
    const widget = this._widget.current;
    const totalCharts = widget.chartsCount();
    for (let i = 0; i < totalCharts; i++) {
      const chart = widget.chart(i);
      if (chart.symbol() === symbol && chart.resolution() === resolution) {
        console.log('Found chart', chart.symbol(), chart.resolution());
        return chart;
      }
    }

    console.log('Could not find chart', symbol, resolution);
    return null;
  }

  async getBarDataFromChart(subscriber: SubscriptionRecord): Promise<ChartBarData> {
    try {
      logChart('Getting bars from chart', subscriber.guid);
      var widget = this._widget.current;



      //check to make sure the active chart is the same as the subscriber
      // if (activeChart.resolution() !== subscriber.resolution || activeChart.symbol() !== subscriber.symbolInfo.name) {
      //   logChart('Active chart is not the same as subscriber', subscriber.guid, activeChart.resolution(), activeChart.symbol(), subscriber.resolution, subscriber.symbolInfo.name);
      //   var totalCharts = widget.chartsCount();
      //   let foundGoodChart = false;
      //   for (var i = 0; i < totalCharts; i++) {
      //     var currentChart = widget.chart(i);
      //     logChart('Checking chart', currentChart.resolution(), currentChart.symbol());
      //     if (currentChart.resolution() === subscriber.resolution && currentChart.symbol() === subscriber.symbolInfo.name) {
      //       logChart('Found the correct chart', subscriber.guid);
      //       activeChart = currentChart;
      //       foundGoodChart = true;
      //       break;
      //     }
      //   }

      //   if (!foundGoodChart) {
      //     logChart('Could not find the correct chart, returning false success', subscriber.guid);
      //     return { success: false };
      //   }
      // }

      for (var i = 0; i < 5; i++) {

        try {
          var activeChart = this.getChartForSymbolAndResolution(subscriber.symbolInfo.name, subscriber.resolution);
          if (!activeChart) {
            logChart('Could not find the correct chart,  retrying. Attempt: ' + i, subscriber.guid);
            await new Promise((resolve) => setTimeout(resolve, 500 * i));
            continue;
          }
          logChart(`Attempt #${i + i} exporting data from chart`, subscriber.guid);
          const res = await activeChart.exportData({ includeSeries: true });
          var indexOfVolume = res.schema.findIndex((x) => (x as any).sourceTitle === 'Volume');
          if (res.data.length > 0) {
            logChart('Found a last bar', res.data[res.data.length - 1], subscriber.guid);
            const newest = res.data[res.data.length - 1];
            const lastBar = {
              time: newest[0] * 1000,
              close: newest[4],
              open: newest[1],
              high: newest[2],
              low: newest[3],
              volume: indexOfVolume >= 0 ? newest[indexOfVolume] : 0,
              tickVolume: 1
            };

            return { ...lastBar, success: true };
          } else {
            logChart('No last bar', subscriber.guid);
            return { success: false };
          }
        } catch (e) {
          logException(e, `Failed to get bars from chart for guid ${subscriber.guid}`);
          await new Promise((resolve) => setTimeout(resolve, 200 * i));
        }
      }
    } catch (e) {
      return { success: false };
    }
  }

  async getBarDataFromBackend(symbolInfo, resolution: string, listenerGuid: string): Promise<ChartBarData> {
    for (let i = 0; i < 5; i++) {
      logChart(`Attempt #${i + 1} getting bars from backend`, listenerGuid);
      const time = dayjs();
      const from = time.unix();
      const to = time.add(2, 'day').unix();

      try {
        const response = await this._historyClient.getBarsV2(symbolInfo.name, resolution, 1, from, to, symbolInfo.subsession_id, this._live);

        if (response.bars.length > 0) {
          logChart('Got bars from backend', response.bars[0], listenerGuid);
          return {
            time: response.bars[0].t,
            close: response.bars[0].c,
            open: response.bars[0].o,
            high: response.bars[0].h,
            low: response.bars[0].l,
            volume: response.bars[0].v,
            tickVolume: response.bars[0].tv,
            success: true
          };
        } else {
          logChart('No bars from backend', { respCode: response.code, guid: listenerGuid, from, to, session: symbolInfo.subsession_id, live: this._live });
          return { success: false };
        }
      } catch (e) {
        logChart('Failed to get bars from backend', { guid: listenerGuid, from, to, session: symbolInfo.subsession_id, live: this._live });
        logException(e, `Failed to get bars from backend for guid ${listenerGuid}`);
        await new Promise((resolve) => setTimeout(resolve, 200 * i));
      }
    }

    return { success: false };
  }

  async subscribeRealTimeBars(symbolInfo, resolution: string, newDataCallback, subscriber: SubscriptionRecord) {
    if(subscriber.callbackId != null) {
      console.warn("Subscriber already has a subscription", {...subscriber});
    }

    const listenerGuid = subscriber.guid;
    let useHistoryClient = resolution.includes('T');
    if (!useHistoryClient) {
      const latestBar = await this.getBarDataFromChart(subscriber);
      //if we are successful, or fail and the res is 1 month / 1 week, still subscribe
      if (latestBar && (latestBar.success || resolution == '1M' || resolution == '1W')) {
        //just in case the subscriber tried ot unsubscribe
        if (subscriber.unsubscribed) return;

        if (latestBar.success) {
          subscriber.lastBar = latestBar;
          subscriber.lastBarTime = dayjs(latestBar.time);
          subscriber.tickVolume = latestBar.tickVolume;

          if (resolution == '1M' || resolution == '1W') {
            subscriber.lastBarTime = toTradeDay(dayjs(latestBar.time));
          }
        } else {
          if (resolution != '1M' && resolution != '1W') {
            logChart('Failed to get bars from chart, falling back to history client', listenerGuid);
            useHistoryClient = true;
          } else {
            logChart('Failed to get bars from chart for 1M / 1W, subscribing without using history client', listenerGuid);
          }
        }

        if (!useHistoryClient) {
          logChart('Subscribing to trades from chart bar data', listenerGuid);
          subscriber.tradeCallbackId = this._subscribeTrades(symbolInfo.symbolId, (data) => {
            this._updateDataForSubscriber(listenerGuid, data, true);
          });
          logChart('Subscribed to trades', subscriber.callbackId, listenerGuid);
        }
      } else {
        useHistoryClient = true;
      }
    }

    //we may need to revert back to history client
    if (!useHistoryClient) return;

    const latestBackendBar = await this.getBarDataFromBackend(symbolInfo, resolution, listenerGuid);
    if (!latestBackendBar.success) {
      logChart('Failed to get bars from backend, falling back to trades', listenerGuid);
    } else {
      logChart('Got bars from backend', latestBackendBar, listenerGuid);
      subscriber.lastBar = latestBackendBar;
      subscriber.lastBarTime = dayjs(latestBackendBar.time);
      subscriber.tickVolume = latestBackendBar.tickVolume;
    }

    logChart('Subscribing to trades from backend bar data', listenerGuid);
    subscriber.tradeCallbackId = this._subscribeTrades(symbolInfo.symbolId, (data) => {
      this._updateDataForSubscriber(listenerGuid, data, true);
    });
    logChart('Subscribed to trades', subscriber.callbackId, listenerGuid);
  }

  subscribeBars(symbolInfo, resolution, newDataCallback, listenerGuid) {
    if (this._subscribers.hasOwnProperty(listenerGuid)) {
      console.error(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
      logMessage(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
      return;
    }

    if (!symbolInfo) {
      logChart('No symbol info', listenerGuid);
      return;
    }

    const subscriber = {
      lastBarTime: null,
      listener: newDataCallback,
      resolution: resolution,
      symbolInfo: symbolInfo,
      callbackId: null,
      lastBar: null,
      tradeCallbackId: null,
      isNewBar: false,
      guid: listenerGuid,
      tickVolume: 0,
      unsubscribed: false,
      publishTimeout: null
    };
    this._subscribers[listenerGuid] = subscriber;

    logMessage(`DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${resolution}}`);

    if (this._chartDatasource.toLowerCase() == 'realtime') {
      logChart('Subscribing to realtime bars', listenerGuid);
      this.subscribeRealTimeBars(symbolInfo, resolution, newDataCallback, subscriber);
    } else {
      logChart('Subscribing to history client', listenerGuid);
      subscriber.callbackId = this._subscribeFunc(symbolInfo.symbolId, resolution, listenerGuid, (data) => {
        this._updateDataForSubscriber(listenerGuid, data);
      });
    }
  }
  unsubscribeBars(listenerGuid) {
    if (this._subscribers.hasOwnProperty(listenerGuid)) {
      const subInfo = this._subscribers[listenerGuid];
      subInfo.unsubscribed = true;

      delete this._subscribers[listenerGuid];

      if (subInfo.tradeCallbackId != null && subInfo.tradeCallbackId >= 0) {
        logChart('Unsubscribing from trades', subInfo.tradeCallbackId, listenerGuid);
        this._unsubscribeTrades(subInfo.symbolInfo.symbolId, subInfo.tradeCallbackId);
        subInfo.tradeCallbackId = null;
      }

      if (subInfo.callbackId != null && subInfo.callbackId >= 0) {
        logChart('Unsubscribing from bars', subInfo.callbackId, listenerGuid);
        this._unsubscribeFunc(listenerGuid, subInfo.callbackId);
        subInfo.callbackId = null;
      }
      logChart(`DataPulseProvider: unsubscribed`, listenerGuid);
      logMessage(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
    } else {
      logChart(`DataPulseProvider: tried to unsubscribe for non-existing subscription`, listenerGuid);
      logMessage(`DataPulseProvider: tried to unsubscribe for non-existing subscription #${listenerGuid}`);
    }
  }

  randomValue(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  _updateDataForSubscriber(listenerGuid, data, isTrade = false) {
    const subscriptionRecord: SubscriptionRecord = this._subscribers[listenerGuid];
    if (!subscriptionRecord) return;

    if (isTrade) {
      if (!data || data.length === 0) {
        return;
      }

      const interval = subscriptionRecord.resolution;
      let publish = true;

      if (data.length > 0) {
        let lastTime = data[0].timestamp;

        for (let i = 0; i < data.length; i++) {
          const trade = data[i];
          const price = trade.price;

          if (lastTime > trade.timestamp) {
            console.warn('Trade out of order', trade);
          }

          let tickCount: number | null = null;
          let barTime: dayjs.Dayjs | null = null;

          // First check if ticks
          tickCount = getBarTickCount(interval);

          if (!tickCount) {
            barTime = getLastBarTime(trade.timestamp, interval);
          }

          // check for new bar, whether time or ticks
          if (isNewBar(barTime, tickCount, subscriptionRecord)) {
            const barValue = {
              time: trade.timestamp.valueOf(),
              close: price,
              open: price,
              high: price,
              low: price,
              volume: trade.volume,
              tickVolume: 1
            };

            if (subscriptionRecord.lastBar) {
              // Close the old bar
              subscriptionRecord.lastBar.isClosed = true;

              // Publish the old bar and clear timeout
              clearTimeout(subscriptionRecord.publishTimeout);
              subscriptionRecord.publishTimeout = null;

              subscriptionRecord.listener(subscriptionRecord.lastBar);
            }

            subscriptionRecord.lastBar = barValue;

            if (!tickCount) {
              subscriptionRecord.lastBarTime = barTime;
            }

            //immediatly publish the new bar
            subscriptionRecord.listener(subscriptionRecord.lastBar);
          } else {
            // Note: lastBar is always available here
            let barValue = subscriptionRecord.lastBar;

            // Update current bar values
            barValue.high = Math.max(barValue.high, price);
            barValue.low = Math.min(barValue.low, price);
            barValue.close = price;
            barValue.volume += trade.volume;
            // Apply changes to lastBar
            subscriptionRecord.lastBar.high = barValue.high;
            subscriptionRecord.lastBar.low = barValue.low;
            subscriptionRecord.lastBar.volume = barValue.volume;
            subscriptionRecord.lastBar.tickVolume += 1;

            // Publish the updated bar
            if (!subscriptionRecord.publishTimeout) {
              subscriptionRecord.publishTimeout = setTimeout(() => {
                subscriptionRecord.publishTimeout = null;
                subscriptionRecord.listener(subscriptionRecord.lastBar);
              }, getSpeed());
            }
          }
        }
      }

      return;
    }

    const barData = data;

    const barValue = {
      time: dayjs(data.timestamp).unix() * 1000,
      close: barData.close,
      open: barData.open,
      high: barData.high,
      low: barData.low,
      volume: barData.volume,
      isClosed: barData.isClosed || false
    };

    if (data.isQuote && !subscriptionRecord.lastBarTime) {
      return;
    }

    // if(this._isMobile !== true && !data.isQuote && subscriptionRecord.lastBarTime && !barValue.isClosed) {
    //   barValue.close = subscriptionRecord.lastBar.close;
    // }

    const isQuoteUpdate = data.isQuote === true && subscriptionRecord.lastBarTime && subscriptionRecord.lastBar;

    if (isQuoteUpdate) {
      barValue.time = subscriptionRecord.lastBarTime;
      barValue.open = subscriptionRecord.lastBar.open;
      barValue.high = Math.max(barValue.close, subscriptionRecord.lastBar.high);
      barValue.low = Math.min(barValue.close, subscriptionRecord.lastBar.low);
      barValue.close = barValue.close;
      barValue.volume = subscriptionRecord.lastBar.volume;
    }

    if (barValue.time < subscriptionRecord.lastBarTime) {
      return; // ignore this bar, it's older than the last one in the subscriptionRecord
    }

    subscriptionRecord.lastBar = barValue;

    if (subscriptionRecord.lastBarTime === barValue.time) {
      if (subscriptionRecord.isNewBar) {
        if (barValue.open) {
          subscriptionRecord.isNewBar = false;
          subscriptionRecord.listener(barValue);
        }
      } else {
        subscriptionRecord.listener(barValue);
      }
    } else {
      subscriptionRecord.isNewBar = true;
      subscriptionRecord.lastBarTime = barValue.time;
      if (barValue.open) {
        subscriptionRecord.listener(barValue);
        subscriptionRecord.isNewBar = false;
      }
    }

    if (barValue.isClosed) {
      subscriptionRecord.lastBarTime = null;
    }
  }
}

function isNewBar(time: dayjs.Dayjs | null, ticks: number | null, record: any): boolean {
  if (!record.lastBar) {
    // True for either
    return true;
  }

  if (ticks) {
    // Check if the tick count has been reached
    return record.lastBar.tickVolume >= ticks;
  } else {
    if (!record.lastBarTime) {
      return true;
    }

    // Check if the bar time has changed
    return !time.isSame(record.lastBarTime);
  }
}

function getBarTickCount(duration: string): number | null {
  // Match the duration to see if it's tick-based
  const match = duration.match(/^(\d+)(T)$/);
  if (!match) {
    return null;
  }

  const tickCount = parseInt(match[1], 10);
  return tickCount;
}
