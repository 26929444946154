import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';
import {
  ForgotPasswordClient,
  LayoutsClient,
  LoginClient,
  MetadataClient,
  OrderClient,
  PositionClient,
  RegisterClient,
  TradeClient,
  TradingAccountClient,
  UserAgreementClient,
  UserClient,
  UserSettingsClient,
  MarketStatusClient,
  StatisticsClient,
  SessionClient,
  TiltClient,
  UserNotificationClient,
  ChartsClient,
  ChartTemplatesClient,
  StudyTemplatesClient,
  DrawingTemplatesClient,
  LineToolsClient,
  ViolationsClient,
  LinkedOrderClient,
  AccountTemplateClient,
  AccountTemplateRuleClient,
  TradingRuleClient,
  JournalLogClient,
  UserDataUploadClient,
  UserContractClient,
  PersonalLockoutClient,
  TradeLimitClient,
  RiskSettingsLockoutClient,
  TradeClockClient
} from '../api/userApi';
import axios, { AxiosError, AxiosInstance } from 'axios';
import config from '../config';
import { ConfigClient, HistoryClient, MarksClient, QuotesClient, SearchClient, StrapiClient, SymbolsClient, TimeClient, TradeLogClient } from '../api/dataApi';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { retry } from '@lifeomic/attempt';
// uuid for browser id
import { v4 as uuidv4 } from 'uuid';
import { useDeviceContext } from './DeviceContext';
import { logException } from '@/helpers/exceptionHelper';

export const AuthorizationHeaders = {
  Authorization: localStorage.getItem('token') ?? undefined
};

export interface IApiContext {
  userApi: UserClient;
  agreementsApi: UserAgreementClient;
  userSettingsApi: UserSettingsClient;
  tradingAccountApi: TradingAccountClient;
  orderApi: OrderClient;
  positionApi: PositionClient;
  metadataApi: MetadataClient;
  contractApi: UserContractClient;
  tradeLogApi: TradeLogClient;
  loginApi: LoginClient;
  layoutApi: LayoutsClient;
  tradeApi: TradeClient;
  registerApi: RegisterClient;
  forgotPasswordApi: ForgotPasswordClient;
  marketStatusApi: MarketStatusClient;
  statisticsApi: StatisticsClient;
  sessionApi: SessionClient;
  tiltApi: TiltClient;
  userNotificationApi: UserNotificationClient;
  chartsApi: ChartsClient;
  chartTemplatesApi: ChartTemplatesClient;
  studyTemplatesApi: StudyTemplatesClient;
  drawingTemplatesApi: DrawingTemplatesClient;
  lineToolsApi: LineToolsClient;
  violationsApi: ViolationsClient;
  linkedOrderApi: LinkedOrderClient;
  accountTemplatesApi: AccountTemplateClient;
  accountTemplateRulesApi: AccountTemplateRuleClient;
  tradeRulesApi: TradingRuleClient;
  journalLogApi: JournalLogClient;
  token: string | null;
  strapiApi: StrapiClient;
  userDataUploadApi: UserDataUploadClient;
  quotesApi: QuotesClient;
  historyApi: HistoryClient;
  timeApi: TimeClient;
  marksApi: MarksClient;
  searchApi: SearchClient;
  configApi: ConfigClient;
  symbolsApi: SymbolsClient;
  lockoutApi: PersonalLockoutClient;
  tradeLimitApi: TradeLimitClient;
  riskSettingsLockoutApi: RiskSettingsLockoutClient;
  tradeClockApi: TradeClockClient;
  axiosInstance: AxiosInstance;
  retryApiCall: <T>(fn: () => Promise<T>, abort?: AbortController) => Promise<T>;
  setToken: (token: string) => void;
}

export const ApiContext = React.createContext<IApiContext>({} as IApiContext);
export const useApi = () => React.useContext(ApiContext);

export default function ApiContextProvider({ children }: any) {
  const [token, setToken] = useState<string | null>(new URLSearchParams(window.location.search).get('token') ?? localStorage.getItem('token'));
  const deviceContext = useDeviceContext();

  const abortController = useRef(new AbortController());

  const retryApiCall = useCallback(<T,>(fn: () => Promise<T>, abort?: AbortController) => {
    return retry(fn, {
      maxAttempts: 6,
      delay: 100,
      maxDelay: 10000,
      factor: 2,
      beforeAttempt: (ctx) => {
        var wasAborted = abort && abort.signal.aborted;
        var wasAbortedController = abortController.current.signal.aborted;

        if (ctx.attemptNum > 0 || wasAborted || wasAbortedController) {
          console.log(`Retrying API call. Failed Calls: ${ctx.attemptNum}. Remaining Attempts: ${ctx.attemptsRemaining}. Was Aborted: ${wasAborted}. Was Aborted From Context: ${wasAbortedController}`, fn);
        }

        if (wasAborted || wasAbortedController) {
          ctx.abort();
        }
      },
      handleError: (e, ctx) => {
        console.log('API call failed', e);
        if (e instanceof AxiosError && e.response?.status === 500) {
          console.log('500 error on retrying. Aborting', e);
          ctx.abort();
        }
      }
    });
  }, []);

  const createAxiosInstance = useCallback(() => {
    let deviceId = '';
    try {
      // Try to get browser id from local storage
      deviceId = localStorage.getItem('browserId');
      if (!deviceId) {
        // generate new uuid
        const newDeviceId = uuidv4();
        localStorage.setItem('browserId', newDeviceId);
        deviceId = newDeviceId;
      }
    } catch (e) {
      console.error('Browser id error', e);
    }

    let toeprint = '';
    try {
      toeprint = localStorage.getItem('toeprint');
    } catch (e) {}

    const defaultHeaders = {
      'x-app-version': config.version,
      'x-app-type': deviceContext.isMobile ? 'px-mobile' : 'px-desktop',
      'x-browser-id': deviceId,
      'x-toeprint': toeprint
    };

    if (!!token) {
      defaultHeaders['Authorization'] = `Bearer ${token}`;
    }

    const connection = axios.create({
      timeout: 60000,
      headers: defaultHeaders
    });

    try {
      if (!toeprint) {
        FingerprintJS.load()
          .then((fp) => {
            fp.get().then((result) => {
              connection.defaults.headers['x-toeprint'] = result.visitorId;
              localStorage.setItem('toeprint', result.visitorId);
            });
          })
          .catch((e) => {
            console.error('Toeprint error', e);
          });
      }
    } catch (e) {
      console.error('Toeprint error', e);
    }

    return connection;
  }, []);

  const axiosRef = useRef<AxiosInstance>(createAxiosInstance());

  // if token is present in query string, set it
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      // Remove token from query string
      urlParams.delete('token');
      localStorage.setItem('token', token);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    // check if accountid is also present
    const accountId = urlParams.get('account');
    if (accountId) {
      // Remove accountid from query string
      urlParams.delete('account');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

      // set accountid in local storage
      localStorage.setItem('activeAccount:sim', accountId);
    }
  }, []);

  const updateToken = useCallback((token: string) => {
    try {
      const toeprint = localStorage.getItem('toeprint');
      if (!toeprint) {
        FingerprintJS.load()
          .then((fp) => {
            fp.get().then((result) => {
              axiosRef.current.defaults.headers['x-toeprint'] = result.visitorId;
              localStorage.setItem('toeprint', result.visitorId);
            });
          })
          .catch((e) => {
            console.error('Toeprint error', e);
          });
      } else {
        axiosRef.current.defaults.headers['x-toeprint'] = toeprint;
      }
    } catch (e) {
      console.error('Toeprint error', e);
    }

    try {
      // Try to get browser id from local storage
      const deviceId = localStorage.getItem('browserId');
      if (deviceId) {
        axiosRef.current.defaults.headers['x-browser-id'] = deviceId;
      } else {
        // generate new uuid
        const newDeviceId = uuidv4();
        localStorage.setItem('browserId', newDeviceId);
        axiosRef.current.defaults.headers['x-browser-id'] = newDeviceId;
      }
    } catch (e) {
      console.error('Browser id error', e);
    }

    try {
      axiosRef.current.defaults.headers['x-app-version'] = config.version;
      axiosRef.current.defaults.headers['x-app-type'] = deviceContext.isMobile ? 'px-mobile' : 'px-desktop';
    } catch (e) {
      logException(e, 'Error setting headers');
    }

    if (!token) {
      axiosRef.current.defaults.headers.Authorization = undefined;
      AuthorizationHeaders.Authorization = undefined;
      localStorage.removeItem('token');
      abortController.current.abort();
    } else {
      abortController.current = new AbortController();
      axiosRef.current.defaults.headers.Authorization = `Bearer ${token}`;
      AuthorizationHeaders.Authorization = `Bearer ${token}`;
      localStorage.setItem('token', token);
    }

    setToken(token);
  }, []);

  const services = useMemo<IApiContext>(() => {
    const axiosInstance = axiosRef.current;
    const data: IApiContext = {
      userApi: new UserClient(config.userApi, axiosInstance),
      strapiApi: new StrapiClient(config.chartApi, axiosInstance),
      positionApi: new PositionClient(config.userApi, axiosInstance),
      orderApi: new OrderClient(config.userApi, axiosInstance),
      metadataApi: new MetadataClient(config.userApi, axiosInstance),
      tradeLogApi: new TradeLogClient(config.chartApi, axiosInstance),
      loginApi: new LoginClient(config.userApi, axiosInstance),
      layoutApi: new LayoutsClient(config.userApi, axiosInstance),
      tradingAccountApi: new TradingAccountClient(config.userApi, axiosInstance),
      registerApi: new RegisterClient(config.userApi, axiosInstance),
      forgotPasswordApi: new ForgotPasswordClient(config.userApi, axiosInstance),
      tradeApi: new TradeClient(config.userApi, axiosInstance),
      userSettingsApi: new UserSettingsClient(config.userApi, axiosInstance),
      marketStatusApi: new MarketStatusClient(config.userApi, axiosInstance),
      agreementsApi: new UserAgreementClient(config.userApi, axiosInstance),
      statisticsApi: new StatisticsClient(config.userApi, axiosInstance),
      sessionApi: new SessionClient(config.userApi, axiosInstance),
      tiltApi: new TiltClient(config.userApi, axiosInstance),
      userNotificationApi: new UserNotificationClient(config.userApi, axiosInstance),
      chartsApi: new ChartsClient(config.userApi, axiosInstance),
      chartTemplatesApi: new ChartTemplatesClient(config.userApi, axiosInstance),
      studyTemplatesApi: new StudyTemplatesClient(config.userApi, axiosInstance),
      drawingTemplatesApi: new DrawingTemplatesClient(config.userApi, axiosInstance),
      lineToolsApi: new LineToolsClient(config.userApi, axiosInstance),
      violationsApi: new ViolationsClient(config.userApi, axiosInstance),
      linkedOrderApi: new LinkedOrderClient(config.userApi, axiosInstance),
      accountTemplatesApi: new AccountTemplateClient(config.userApi, axiosInstance),
      accountTemplateRulesApi: new AccountTemplateRuleClient(config.userApi, axiosInstance),
      tradeRulesApi: new TradingRuleClient(config.userApi, axiosInstance),
      journalLogApi: new JournalLogClient(config.userApi, axiosInstance),
      userDataUploadApi: new UserDataUploadClient(config.userApi, axiosInstance),
      contractApi: new UserContractClient(config.userApi, axiosInstance),
      quotesApi: new QuotesClient(config.chartApi, axiosInstance),
      historyApi: new HistoryClient(config.chartApi, axiosInstance),
      timeApi: new TimeClient(config.chartApi, axiosInstance),
      marksApi: new MarksClient(config.chartApi, axiosInstance),
      searchApi: new SearchClient(config.chartApi, axiosInstance),
      configApi: new ConfigClient(config.chartApi, axiosInstance),
      symbolsApi: new SymbolsClient(config.chartApi, axiosInstance),
      lockoutApi: new PersonalLockoutClient(config.userApi, axiosInstance),
      tradeLimitApi: new TradeLimitClient(config.userApi, axiosInstance),
      riskSettingsLockoutApi: new RiskSettingsLockoutClient(config.userApi, axiosInstance),
      tradeClockApi: new TradeClockClient(config.userApi, axiosInstance),
      axiosInstance,
      token,
      retryApiCall,
      setToken: updateToken
    };

    return data;
  }, [token]);
  return <ApiContext.Provider value={services}>{children}</ApiContext.Provider>;
}
