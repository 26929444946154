import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useModal } from '@/contexts/ModalContext';
import { OrderPromptType, useOrders, calculatePnl } from '@/contexts/OrdersContext';
import { useSymbol } from '@/contexts/SymbolContext';
import styles from './dom.module.scss';
import Loading from '../../../../components/Loading';
import { Box, Tooltip } from '@mui/material';
import { OrderType, TradingAccountStatus, UserContractModel } from '../../../../api/userApi';
import { useSettings } from '@contexts/SettingsContext';
import DomRow from './domRow';
import DomContextProvider, { useDom } from '@contexts/DomContext';
import Decimal from 'decimal.js';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import ContractSelector from '@/components/topstep/contractSelector';
import { LinkedColor } from '@/contexts/LinkedContext';
import { domCellPadding } from '@/data/enumTypeMaps';
import { ReactComponent as Recenter } from '../../../../assets/images/recenter.svg';
import { ReactComponent as AutoRecenterOff } from '../../../../assets/images/autorecenteroff.svg';
import { ReactComponent as AutoRecenterOn } from '../../../../assets/images/autorecenteron.svg';
import { Settings, Link } from '@mui/icons-material';
import { TsModal } from '@components/modal';
import DomHeaderSettings from '@components/domHeaderSettings';
import CommonOrderOptions from '@/components/topstep/commonOrderOptions';
import AccountSelector from '../../../../components/topstep/accountSelector';
import { ContractBoundary, DebouncedButton } from '@/components/styledComponents';
import { ContractPriceSpan } from '@/components/numbers';
import { roundToNearestTick } from '@/helpers/decimalHelper';

import { config } from '@/config';
import { useDeviceContext } from '@/contexts/DeviceContext';
import ConfirmOrderModal from '@/components/topstep/confirmOrderModal';
import KeyboardListener from '@/components/topstep/keyboardListener';
import { toast } from 'react-toastify';
import { HotkeyActions } from '@/components/topstep/hotkeySettings';
import { useMobile } from '@/contexts/MobileContext';
interface DomProps {
  changed: () => void;
  showLinkedOrders: () => void;

  tabData: {
    symbol: string;
    linkedColor?: LinkedColor;
    orderAmount?: number;
    autoCenter?: boolean;
    callbacks: {
      onLinkedColorChange: (color: LinkedColor) => void;
      onHotkeyEnabledChange?: (enabled: boolean) => void;
    };

    hotkeysEnabled?: boolean;
  };
}
interface InnerDomProps extends DomProps {
  contract: UserContractModel;
  shouldCenter: boolean;
  autoCenter: boolean;
  setShouldCenter: (center: boolean) => void;
  onUpdateOrderAmount?: (amount: number) => void;
  hotkeysEnabled: boolean;
}

const Dom: React.FC<DomProps> = (props): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { getDefaultContract, getContractByProductId } = useSymbol();
  const { mobileContract, setMobileContract } = useMobile();
  const [contract, setContract] = useState<UserContractModel>(isMobile ? mobileContract : getContractByProductId(props.tabData?.symbol) || getDefaultContract());
  const [linkedColor, setLinkedColor] = useState<LinkedColor>(props.tabData.linkedColor);
  const [shouldCenter, setShouldCenter] = useState<boolean>(false);
  const [autoCenter, setAutoCenter] = useState<boolean>(props.tabData.autoCenter ?? false);
  const [domSettingsOpen, setDomSettingsOpen] = useState<boolean>(false);
  const [hotkeysEnabled, setHotkeysEnabled] = useState<boolean>(props.tabData.hotkeysEnabled ?? false);
  const { customSettings, saveCustomSettings } = useSettings();

  const handleSetOrderAmount = (amount: number) => {
    if (isMobile) return;

    props.tabData.orderAmount = amount;
    props.changed();
  };

  useEffect(() => {
    if (props.tabData.autoCenter !== autoCenter) {
      props.tabData.autoCenter = autoCenter;
      props.changed();
    }
  }, [autoCenter]);

  useEffect(() => {
    if (!isMobile) return;
    setContract(mobileContract);
  }, [mobileContract, isMobile]);

  const updateContract = useCallback(
    (contract: UserContractModel) => {
      setContract(contract);
      if (isMobile) {
        setMobileContract(contract);
      }
    },
    [isMobile, setMobileContract]
  );

  const toggleAutoCenter = useCallback(() => {
    setAutoCenter((prev) => {
      const newAutoCenter = !prev;

      if (newAutoCenter) {
        setShouldCenter(true);
      }

      return newAutoCenter;
    });
  }, []);

  const toggleDomSettings = useCallback(() => {
    setDomSettingsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    console.log('Dom TabData linked color changed to ', props.tabData.linkedColor);
    setLinkedColor(props.tabData.linkedColor);
  }, [props.tabData.linkedColor]);

  useEffect(() => {
    if (!props.tabData.callbacks) {
      props.tabData.callbacks = {
        onLinkedColorChange: setLinkedColor,
        onHotkeyEnabledChange: setHotkeysEnabled
      };
    } else {
      props.tabData.callbacks.onLinkedColorChange = setLinkedColor;
      props.tabData.callbacks.onHotkeyEnabledChange = setHotkeysEnabled;
    }
  }, [props.tabData.callbacks]);

  useEffect(() => {
    if (props.tabData.symbol !== contract?.productId) {
      props.tabData.symbol = contract?.productId;
      props.changed && props.changed();
    }
  }, [contract]);

  const onHideVolProfile = useCallback(() => {
    customSettings.domHeaderVolProfileHide = !customSettings.domHeaderVolProfileHide;
    saveCustomSettings({ domHeaderVolProfileHide: customSettings.domHeaderVolProfileHide });
  }, [customSettings.domHeaderVolProfileHide]);

  const domBindings = useMemo(() => {
    return [
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.HideVolProfileColumn], onKeyboard: onHideVolProfile },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.CenterOnLastPrice], onKeyboard: () => setShouldCenter(true) }
    ];
  }, [customSettings.hotkeyBindings, onHideVolProfile]);

  return (
    <Box sx={{ height: '100%', width: '100%', overflow: 'hidden', maxHeight: '100%', display: 'flex', flexDirection: 'column', background: isMobile && '#1c1e23' }}>
      <Box
        sx={(theme) => {
          return {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0.1em 0',
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          };
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ContractSelector contract={contract} setContract={updateContract} linkedColor={linkedColor} />
        </Box>
        <Box>
          {config.features.oco === true && (
            <Tooltip title='Link Orders'>
              <div style={{ cursor: 'pointer' }} onClick={props.showLinkedOrders}>
                <Link />
              </div>
            </Tooltip>
          )}
        </Box>
        <Box>
          <Tooltip title='DOM Settings'>
            <div style={{ cursor: 'pointer' }} onClick={toggleDomSettings}>
              <Settings sx={{ marginLeft: '0.2em' }} />
            </div>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title='Auto-Center on Last Price'>
            <Box sx={{ cursor: 'pointer' }} onClick={toggleAutoCenter}>
              {!autoCenter && <AutoRecenterOff style={{ width: 30, height: 30 }} />}
              {autoCenter && <AutoRecenterOn style={{ width: 30, height: 30 }} />}
            </Box>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title='Center on Last Price'>
            <Recenter style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => setShouldCenter(true)} />
          </Tooltip>
        </Box>
      </Box>
      <ContractBoundary contract={contract}>
        <DomContextProvider contract={contract} initialOrderSize={props.tabData.orderAmount || 1} handleSetOrderAmount={handleSetOrderAmount}>
          <DomInner
            onUpdateOrderAmount={handleSetOrderAmount}
            showLinkedOrders={props.showLinkedOrders}
            contract={contract}
            tabData={props.tabData}
            shouldCenter={shouldCenter}
            setShouldCenter={setShouldCenter}
            autoCenter={autoCenter}
            changed={props.changed}
            hotkeysEnabled={hotkeysEnabled}
          ></DomInner>
        </DomContextProvider>
      </ContractBoundary>
      <TsModal open={domSettingsOpen} onClose={() => setDomSettingsOpen(false)}>
        <DomHeaderSettings onSaved={() => setDomSettingsOpen(false)} onCancelled={() => setDomSettingsOpen(false)}></DomHeaderSettings>
      </TsModal>
      <KeyboardListener enabled={hotkeysEnabled} bindings={domBindings} />
    </Box>
  );
};

const MaxPrices = 1000;

const DomInner: React.FC<InnerDomProps> = ({ contract, tabData, setShouldCenter, shouldCenter, autoCenter, onUpdateOrderAmount, hotkeysEnabled }): JSX.Element => {
  const { placeOrderWithSymbol, cancelAll, closeAllPositions, editSltpSetting, sltpSettings } = useOrders();
  const { activeTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();

  const { showConfirmations: confirmation, customSettings } = useSettings();
  const listRef = useRef<FixedSizeList>();

  const [centeredPrice, setCenteredPrice] = useState(0);

  const [loadedAmount, setLoadedAmount] = useState(false);

  const { showModal, hideModal } = useModal();

  const { lastPrice, tick, hasAskOrders, hasBidOrders, position, orderAmount, updateOrderAmount, orderList, setAutoCenter, recenter } = useDom();

  const tickDecimal = useMemo(() => {
    return new Decimal(tick);
  }, [tick]);

  const handleUpdateOrderAmount = useCallback((amount: number) => {
    updateOrderAmount(amount);
    if (onUpdateOrderAmount) onUpdateOrderAmount(amount);
  }, []);

  const onKeyboardIncreaseOrderAmount = useCallback(() => {
    handleUpdateOrderAmount(orderAmount + 1);
  }, [orderAmount]);

  const onKeyboardDecreaseOrderAmount = useCallback(() => {
    if (orderAmount === 1) return;
    handleUpdateOrderAmount(orderAmount - 1);
  }, [orderAmount]);

  // Retrieve order amount from local storage
  useEffect(() => {
    if (!isMobile) return;

    const savedOrderAmount = localStorage.getItem('mobile-order-amount');
    if (savedOrderAmount) {
      updateOrderAmount(parseInt(savedOrderAmount));
    }
  }, [isMobile]);

  // Set order amount to local storage for persistence
  useEffect(() => {
    if (!isMobile) return;

    localStorage.setItem('mobile-order-amount', orderAmount.toString());
  }, [orderAmount, isMobile]);

  useEffect(() => {
    if (isMobile) return;

    if (orderAmount < 1) {
      updateOrderAmount(1);
    }

    tabData.orderAmount = orderAmount;
  }, [orderAmount, isMobile]);

  useEffect(() => {
    if (isMobile) return;

    if (tabData.orderAmount) {
      updateOrderAmount(tabData.orderAmount);
    }
  }, [tabData.orderAmount, isMobile]);

  useEffect(() => {
    setCenteredPrice(0);
  }, [contract]);

  useEffect(() => {
    setAutoCenter(autoCenter);
  }, [autoCenter]);

  useEffect(() => {
    if (!centeredPrice && lastPrice && listRef.current) {
      setCenteredPrice(lastPrice);
      listRef.current.scrollToItem(MaxPrices + 1, 'center');
    }

    if (autoCenter && lastPrice && centeredPrice != lastPrice && autoCenterTimeout.current == null) {
      setCenteredPrice(lastPrice);
    }
  }, [lastPrice, centeredPrice, autoCenter, contract, listRef.current]);

  useEffect(() => {
    if (shouldCenter && lastPrice && listRef.current) {
      setCenteredPrice(lastPrice);
      recenter();
      listRef.current.scrollToItem(MaxPrices + 1, 'center');
      setShouldCenter(false);
    }
  }, [shouldCenter, lastPrice]);

  const marketOrder = useMemo(
    () => async (side: OrderPromptType) => {
      await placeOrderWithSymbol({
        symbol: contract.productId,
        orderType: side,
        amount: orderAmount,
        type: OrderType.Market
      });
    },
    [orderAmount, contract]
  );

  // useEffect(() => {
  //   if (listRef.current) {
  //     listRef.current.scrollToItem(MaxPrices + 1, 'center');
  //   }
  // }, [listRef.current]);

  useEffect(() => {
    if (lastPrice && listRef.current && autoCenter && !autoCenterTimeout.current) {
      listRef.current.scrollToItem(MaxPrices + 1, 'center');
    }
  }, [lastPrice, listRef, autoCenter]);

  const getItem = useCallback(({ index, style }) => {
    const priceIndex = (index - MaxPrices) * -1;

    const price = new Decimal(centeredPrice).add(tickDecimal.mul(priceIndex)).toNumber();
    return <DomRow center={() => setShouldCenter(true)} key={price} domIndex={index} style={style} />;
  }, []);

  const domSize = useMemo(() => {
    const padding = customSettings.domCellPadding ?? domCellPadding.Small;
    if (padding === domCellPadding.Small) {
      return 15;
    } else if (padding === domCellPadding.Large) {
      return 20;
    } else if (padding === domCellPadding.XLarge) {
      return 30;
    }
  }, [customSettings.domCellPadding]);

  const domList = useCallback(
    (size: Size) => {
      return (
        <FixedSizeList
          key={'domList'}
          ref={listRef}
          height={size.height}
          itemCount={MaxPrices * 2 + 1}
          initialScrollOffset={(MaxPrices + 1) * domSize - size.height / 2}
          itemSize={domSize}
          width={size.width}
          itemKey={(index, item) => index}
          onScroll={handleScroll}
        >
          {getItem}
        </FixedSizeList>
      );
    },
    [getItem, domSize]
  );

  const hasViolation = useMemo(() => {
    return !(activeTradingAccount.status === TradingAccountStatus.Active || activeTradingAccount.status === TradingAccountStatus.Ineligible);
  }, [activeTradingAccount.status]);

  const canTrade = useMemo(() => {
    return activeTradingAccount.isFollower !== true && !hasViolation;
  }, [activeTradingAccount.isFollower, hasViolation]);

  const canTradePosition = useMemo(() => {
    return canTrade && !!position;
  }, [canTrade, position]);

  const canCancelOrders = useMemo(() => {
    return canTrade && (hasAskOrders || hasBidOrders);
  }, [canTrade, hasAskOrders, hasBidOrders]);

  const autoCenterTimeout = useRef<NodeJS.Timeout | null>(null);
  const autoCenterRef = useRef(autoCenter);
  useEffect(() => {
    autoCenterRef.current = autoCenter;
  }, [autoCenter]);

  // if autoCenter is enabled, we will auto center the DOM after 2 seconds of no scrolling
  const handleScroll = useCallback(() => {
    if (autoCenterTimeout.current) {
      clearTimeout(autoCenterTimeout.current);
      autoCenterTimeout.current = null;
    }
    autoCenterTimeout.current = setTimeout(() => {
      autoCenterTimeout.current = null;

      if (autoCenterRef.current) {
        setShouldCenter(true);
      }
    }, 2000); // 2 seconds
  }, []);

  useEffect(() => {
    if (!autoCenter) {
      if (autoCenterTimeout.current) {
        clearTimeout(autoCenterTimeout.current);
        autoCenterTimeout.current = null;
      }
    } else {
      return () => {
        if (autoCenterTimeout.current) {
          clearTimeout(autoCenterTimeout.current);
          autoCenterTimeout.current = null;
        }
      };
    }
  }, [autoCenter]);

  const dom = useMemo(() => {
    return <AutoSizer key='auto-size'>{domList}</AutoSizer>;
  }, [domList]);

  const customFontSize = useMemo(() => {
    if (customSettings.domFontSize == 0) {
      return { fontSize: 10 };
    } else if (customSettings.domFontSize == 1) {
      return { fontSize: 12 };
    } else if (customSettings.domFontSize == 2) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 12 };
    }
  }, [customSettings.domFontSize]);

  const hasPosition = useMemo(() => {
    return !!position && position.positionSize != 0;
  }, [position, position?.positionSize]);

  const positionEntryPrice = useMemo(() => {
    if (!position) return 0;
    return roundToNearestTick(position.averagePrice, contract);
  }, [position?.averagePrice, contract]);

  const breakEven = useCallback(() => {
    if (hasPosition) {
      editSltpSetting(position.id, positionEntryPrice, position.takeProfit);
    } else {
      toast.error('No active position');
    }
  }, [hasPosition, positionEntryPrice, position?.id, position?.takeProfit]);

  const commonOrderOptions = useMemo(() => {
    return (
      <CommonOrderOptions
        contract={contract}
        onBuy={() => marketOrder(OrderPromptType.Buy)}
        onSell={() => marketOrder(OrderPromptType.Sell)}
        size={orderAmount}
        setSize={handleUpdateOrderAmount}
        disableBuySell={false}
        hideBuySellButton={customSettings.domHeaderBuySellButtonHide}
        hideCancelAll={customSettings.domHeaderCancelAllButtonHide}
        hideCancelOrders={customSettings.domHeaderCancelOrdersButtonHide}
        hideClosePosition={customSettings.domHeaderClosePositionButtonHide}
        hideFlattenAll={customSettings.domHeaderFlattenAllButtonHide}
        hideJoinButton={customSettings.domHeaderJoinButtonHide}
        hideReversePosition={customSettings.domHeaderReversePositionButtonHide}
        hotkeysEnabled={hotkeysEnabled}
      />
    );
  }, [customSettings, orderAmount, loadedAmount, marketOrder, hotkeysEnabled]);

  const domHeader = useMemo(() => {
    return (
      <div style={{ height: 'auto', width: '100%', border: 'none' }} className={classNames(styles.domRow)}>
        {(hasBidOrders || customSettings.domHeaderShowMyBidAlways !== false) && <div className={styles.domHeader}>MY BID</div>}
        <div className={styles.domHeader}>BID SIZE</div>
        <div className={styles.domHeader}>PRICE</div>
        <div className={styles.domHeader}>ASK SIZE</div>
        {(hasAskOrders || customSettings.domHeaderShowMyAskAlways !== false) && <div className={styles.domHeader}>MY ASK</div>}
        {hasPosition && customSettings.domHeaderHidePNLColumn !== true && <div className={styles.domHeader}>P&L</div>}
        {customSettings.domHeaderVolProfileHide !== true && <div className={styles.domHeader}>VOL PRO.</div>}
      </div>
    );
  }, [customSettings, hasBidOrders, hasAskOrders, hasPosition]);

  const positionOverlay = useMemo(() => {
    return (
      <>
        {!canTrade && hasPosition && !activeTradingAccount.isFollower && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DebouncedButton
              color='neutral'
              onClick={() => {
                closeAllPositions();
                cancelAll();
              }}
              sx={{ marginLeft: '1em' }}
            >
              Flatten & Cancel
            </DebouncedButton>
          </div>
        )}
      </>
    );
  }, [canTrade, hasPosition, activeTradingAccount.isFollower]);

  const showPnl = useMemo<number | null>(() => {
    if (!position || !lastPrice) return null;
    return calculatePnl(position.positionSize, position.averagePrice, lastPrice, contract.pointValue);
  }, [position?.positionSize, position?.averagePrice, lastPrice, contract, hasPosition]);

  const handleKeyboardBreakEven = useCallback(() => {
    if (showPnl < 0 || !canTrade) {
      toast.error(
        <span>
          <p>
            {position.positionSize < 0 ? '+' : '-'}
            {Math.abs(position.positionSize)} {contract.productName} Stop Market Rejected!
          </p>
          <p>Breakeven order not available</p>
        </span>
      );
      return;
    }

    breakEven();
  }, [showPnl, canTrade, orderAmount, contract.productName]);

  const rejectHotkeyFollower = useCallback(() => {
    toast.error(
      <span>
        <p>
          <b>Hotkey Rejected!</b>
          <br />
          No trading on Trade Copier Follower Account
        </p>
      </span>
    );
  }, []);

  const bindings = useMemo(() => {
    return [
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.BuyMarket], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : () => marketOrder(OrderPromptType.Buy) },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.SellMarket], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : () => marketOrder(OrderPromptType.Sell) },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.BreakEven], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : handleKeyboardBreakEven },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.ContractSizeUp], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardIncreaseOrderAmount },
      { keys: customSettings.hotkeyBindings?.[HotkeyActions.ContractSizeDown], onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardDecreaseOrderAmount }
    ];
  }, [customSettings.hotkeyBindings, orderAmount, marketOrder, handleKeyboardBreakEven, onKeyboardIncreaseOrderAmount, onKeyboardDecreaseOrderAmount, activeTradingAccount.isFollower]);

  const positionBlock = useMemo(() => {
    return (
      <Box
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          fontSize: customFontSize.fontSize,
          height: '3em'
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'block',
            flexWrap: 'wrap',
            alignItems: 'center',
            textAlign: 'center',
            margin: '0.25em 0'
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              display: 'block',
              flexWrap: 'wrap',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {position && position.positionSize != 0 && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginRight: 10 }}>
                  {position.positionSize > 0 ? '+' + position.positionSize : position.positionSize} @ <ContractPriceSpan contract={contract}>{position.averagePrice}</ContractPriceSpan>
                </div>
                <div style={{ marginRight: 10 }}>{showPnl !== null && <span style={{ color: showPnl < 0 ? '#bd3f3b' : '#43984d' }}>UP&L ${showPnl.toFixed(2)}</span>}</div>
                <div style={{ marginRight: 10 }}>
                  <DebouncedButton color='neutral' disabled={showPnl < 0 || !canTrade} onClick={breakEven} sx={{ padding: '2px 4px', margin: '4px', fontSize: '.9em', minWidth: 20 }}>
                    B/E
                  </DebouncedButton>
                </div>
              </div>
            )}
            {(!position || position.positionSize == 0) && <div>No Active Position</div>}
          </div>
        </div>
        <KeyboardListener enabled={hotkeysEnabled} bindings={bindings} />
      </Box>
    );
  }, [customFontSize.fontSize, lastPrice, position?.positionSize, position?.averagePrice, contract, hasPosition, breakEven, hotkeysEnabled, bindings]);

  const accountSelect = useMemo(() => {
    if (isMobile) return null;
    return (
      <div className={styles.accountCont}>
        <AccountSelector style={{ maxWidth: '22em' }} />
      </div>
    );
  }, [isMobile]);

  const ret = useMemo(() => {
    return (
      <>
        <Box sx={{ maxHeight: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', fontSize: customFontSize.fontSize }}>
          {domHeader}
          <div style={{ height: '100%' }}>{lastPrice ? dom : <Loading></Loading>}</div>
          <div className={classNames(isMobile && styles.buttonContainerMobile)}>
            {accountSelect}
            {positionBlock}
            {positionOverlay}
            {commonOrderOptions}
          </div>
        </Box>
      </>
    );
  }, [domHeader, positionBlock, commonOrderOptions, lastPrice, customFontSize.fontSize, dom, positionOverlay, accountSelect, isMobile, loadedAmount]);

  if (lastPrice == 0) {
    return <Loading />;
  }
  return ret;
};

export default Dom;
