import { useApi } from '@contexts/ApiContext';
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { IUserSettingsModel, JsonDocument, PersonalDailyAction, PersonalDailyLimitsRequest, RootElement, TradingAccountType } from '@api/userApi';
import { networkSpeed, chartPlotSide, topNavTextSize } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import styles from './settings.module.scss';
import { StyledButton } from '@/components/styledComponents';
import config from '@/config';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import { DefaultRPnLNegative, DefaultRPnLPositive, DefaultUPnLNegative, DefaultUPnLPositive } from './settingsDefault';

interface MiscSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const MiscSettings: React.FC<MiscSettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const { tradingAccountApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();

  const [supressAlerts, setSupressAlerts] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [soundNotifications, setSoundNotifications] = useState<boolean>(true);
  const [topNavText, setTopNavText] = useState<topNavTextSize>(settings.customSettings.topNavTextSize);
  const [selectedAudioPack, setSelectedAudioPack] = useState<string>(config.audioPacks[0]?.name || '');
  const [useDistanceMLL, setUseDistanceMLL] = useState<boolean>(settings.customSettings.useDistanceMLL ?? false);
  const [RPnLPositiveColor, setRPnLPositiveColor] = useState<string>(settings.customSettings.RPnLPositiveColor ?? DefaultRPnLPositive);
  const [RPnLNegativeColor, setRPnLNegativeColor] = useState<string>(settings.customSettings.RPnLNegativeColor ?? DefaultRPnLNegative);
  const [UPnLPositiveColor, setUPnLPositiveColor] = useState<string>(settings.customSettings.UPnLPositiveColor ?? DefaultUPnLPositive);
  const [UPnLNegativeColor, setUPnLNegativeColor] = useState<string>(settings.customSettings.UPnLNegativeColor ?? DefaultUPnLNegative);
  const [showRPnLPositiveColorPicker, setShowRPnLPositiveColorPicker] = useState<boolean>(false);
  const [showRPnLNegativeColorPicker, setShowRPnLNegativeColorPicker] = useState<boolean>(false);
  const [showUPnLPositiveColorPicker, setShowUPnLPositiveColorPicker] = useState<boolean>(false);
  const [showUPnLNegativeColorPicker, setShowUPnLNegativeColorPicker] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
  };

  useEffect(() => {
    setSoundNotifications(settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts);
    setSupressAlerts(settings.customSettings.supressAlerts);
    setTopNavText(settings.customSettings.topNavTextSize);
    setSelectedAudioPack(settings.customSettings.audioPack || config.audioPacks[0]?.name || '');
    setUseDistanceMLL(settings.customSettings.useDistanceMLL ?? false);
    setRPnLPositiveColor(settings.customSettings.RPnLPositiveColor ?? DefaultRPnLPositive);
    setRPnLNegativeColor(settings.customSettings.RPnLNegativeColor ?? DefaultRPnLNegative);
    setUPnLPositiveColor(settings.customSettings.UPnLPositiveColor ?? DefaultUPnLPositive);
    setUPnLNegativeColor(settings.customSettings.UPnLNegativeColor ?? DefaultUPnLNegative);
  }, [settings]);

  const cancel = useCallback(() => {
    setSoundNotifications(settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts);
    setSupressAlerts(settings.customSettings.supressAlerts);
    setTopNavText(settings.customSettings.topNavTextSize);
    setSelectedAudioPack(settings.customSettings.audioPack || config.audioPacks[0]?.name || '');
    setUseDistanceMLL(settings.customSettings.useDistanceMLL ?? false);
    setRPnLPositiveColor(settings.customSettings.RPnLPositiveColor ?? DefaultRPnLPositive);
    setRPnLNegativeColor(settings.customSettings.RPnLNegativeColor ?? DefaultRPnLNegative);
    setUPnLPositiveColor(settings.customSettings.UPnLPositiveColor ?? DefaultUPnLPositive);
    setUPnLNegativeColor(settings.customSettings.UPnLNegativeColor ?? DefaultUPnLNegative);
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {

    settings.customSettings.supressAlerts = supressAlerts;
    settings.customSettings.topNavTextSize = topNavText;
    settings.customSettings.audioAlerts = soundNotifications;
    settings.customSettings.audioPack = selectedAudioPack;
    settings.customSettings.useDistanceMLL = useDistanceMLL;
    settings.customSettings.RPnLPositiveColor = RPnLPositiveColor;
    settings.customSettings.RPnLNegativeColor = RPnLNegativeColor;
    settings.customSettings.UPnLPositiveColor = UPnLPositiveColor;
    settings.customSettings.UPnLNegativeColor = UPnLNegativeColor;

    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)])
      .then(() => {
        setIsLoading(false);
        onSaved && onSaved();
        toast('Settings saved', { type: 'success', hideProgressBar: true });
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });

  }, [activeTradingAccount.accountId, soundNotifications, supressAlerts, topNavText, settings.saveCustomSettings, selectedAudioPack, useDistanceMLL, RPnLPositiveColor, RPnLNegativeColor, UPnLPositiveColor, UPnLNegativeColor]);

  const updateTopNavText = useCallback((input: string) => {
    setTopNavText(parseInt(input) as topNavTextSize);
  }, []);

  const isChanged = useMemo(() => {

    const currentSoundSetting = settings.customSettings.audioAlerts === undefined ? settings.soundNotifications : settings.customSettings.audioAlerts;
    const currentAudioPack = settings.customSettings.audioPack || config.audioPacks[0]?.name || '';
    const currentDistanceMLL = settings.customSettings.useDistanceMLL ?? false;
    const currentRPnLPositiveColor = settings.customSettings.RPnLPositiveColor ?? DefaultRPnLPositive;
    const currentRPnLNegativeColor = settings.customSettings.RPnLNegativeColor ?? DefaultRPnLNegative;
    const currentUPnLPositiveColor = settings.customSettings.UPnLPositiveColor ?? DefaultUPnLPositive;
    const currentUPnLNegativeColor = settings.customSettings.UPnLNegativeColor ?? DefaultUPnLNegative;

    return soundNotifications !== currentSoundSetting ||
            supressAlerts !== settings.customSettings.supressAlerts ||
            topNavText !== settings.customSettings.topNavTextSize ||
            useDistanceMLL !== currentDistanceMLL ||
            currentAudioPack != selectedAudioPack ||
            RPnLPositiveColor !== currentRPnLPositiveColor ||
            RPnLNegativeColor !== currentRPnLNegativeColor ||
            UPnLPositiveColor !== currentUPnLPositiveColor ||
            UPnLNegativeColor !== currentUPnLNegativeColor;
  }, [
    soundNotifications,
    useDistanceMLL,
    supressAlerts,
    settings,
    settings.customSettings,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    selectedAudioPack,
    topNavText,
    RPnLPositiveColor,
    RPnLNegativeColor,
    UPnLPositiveColor,
    UPnLNegativeColor
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        maxHeight: '100%'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Alerts
            <HelpMessage message='Alerts for Orders, Trades, Closures, etc' />
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={soundNotifications == null || soundNotifications == undefined ? true : soundNotifications}
                onChange={() => setSoundNotifications(soundNotifications == null || soundNotifications == undefined ? false : !soundNotifications)}
              />
            }
            label='Enable Sound Alerts'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={supressAlerts == null || supressAlerts == undefined ? true : supressAlerts !== true}
                onChange={() => setSupressAlerts(supressAlerts == null || supressAlerts == undefined ? true : !supressAlerts)}
              />
            }
            label='Enable Visual Alerts'
          />
        </FormGroup>

        {config.audioPacks.length > 1 && ( //show dropdown only if more than 1 audiopack avail
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Audio Pack
            <HelpMessage message='Select the audio pack for notifications' />
          </FormLabel>
          <Select
            variant='outlined'
            value={selectedAudioPack}
            onChange={(event) => setSelectedAudioPack(event.target.value)}
            style={{ margin: '1em 0' }}
          >
            {config.audioPacks.map(pack => (
              <MenuItem key={pack.name} value={pack.name}>
                {pack.title || pack.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        )}

        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Balances Font Size
            <HelpMessage message='The font size for the balances area (BAL, MLL, RP&L, etc)' />
          </FormLabel>
          <Select
            variant='outlined'
            type='number'
            value={topNavText === undefined ? topNavTextSize.Regular : topNavText}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateTopNavText(t.target.value + '')}
          >
            <MenuItem value={topNavTextSize.Regular} key={topNavTextSize.Regular}>
              Regular
            </MenuItem>
            <MenuItem value={topNavTextSize.Large} key={topNavTextSize.Large}>
              Medium
            </MenuItem>
            <MenuItem value={topNavTextSize.XLarge} key={topNavTextSize.XLarge}>
              Large
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            RP&L and UP&L Background Colors
            <HelpMessage message='Customize the colors of the RP&L and UP&L boxes on the platform header.' />
          </FormLabel>
          <Box sx={{ padding: '1em', border: '1px solid #ccc', borderRadius: '4px', marginTop: "1em", marginBottom: '1em' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2em' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel sx={{ color: 'white' }}>RP&L Positive Color</FormLabel>
                <Box
                  sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: RPnLPositiveColor }}
                  onClick={() => setShowRPnLPositiveColorPicker((prev) => !prev)}
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel sx={{ color: 'white' }}>RP&L Negative Color</FormLabel>
                <Box
                  sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: RPnLNegativeColor }}
                  onClick={() => setShowRPnLNegativeColorPicker((prev) => !prev)}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: '1em', border: '1px solid #ccc', borderRadius: '4px' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2em' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel sx={{ color: 'white' }}>UP&L Positive Color</FormLabel>
                <Box
                  sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: UPnLPositiveColor }}
                  onClick={() => setShowUPnLPositiveColorPicker((prev) => !prev)}
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel sx={{ color: 'white' }}>UP&L Negative Color</FormLabel>
                <Box
                  sx={{ cursor: 'pointer', marginLeft: '10px', width: '40px', height: '20px', borderRadius: '4px', background: UPnLNegativeColor }}
                  onClick={() => setShowUPnLNegativeColorPicker((prev) => !prev)}
                />
              </Box>
            </Box>
          </Box>
        </FormGroup>

        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Distance to MLL
            <HelpMessage message='Check this to enable Distance to MLL which will show you how far you are from reaching your Maximum Loss Limit.' />
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={useDistanceMLL}
                onChange={() => setUseDistanceMLL((prev) => !prev)}
              />
            }
            label='Use Distance to MLL'
          />
        </FormGroup>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons) && (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} style={{ margin: '1em' }}>
            Cancel
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} style={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
      {showRPnLPositiveColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowRPnLPositiveColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={RPnLPositiveColor} onChange={setRPnLPositiveColor} />
            <HexColorInput color={RPnLPositiveColor} onChange={setRPnLPositiveColor} />
            <Tooltip title="Reset to Default Color">
              <IconButton onClick={() => setRPnLPositiveColor(DefaultRPnLPositive)} >
                <RestartAltRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      {showRPnLNegativeColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowRPnLNegativeColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={RPnLNegativeColor} onChange={setRPnLNegativeColor} />
            <HexColorInput color={RPnLNegativeColor} onChange={setRPnLNegativeColor} />
            <Tooltip title="Reset to Default Color">
              <IconButton onClick={() => setRPnLNegativeColor(DefaultRPnLNegative)} >
                <RestartAltRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      {showUPnLPositiveColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowUPnLPositiveColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={UPnLPositiveColor} onChange={setUPnLPositiveColor} />
            <HexColorInput color={UPnLPositiveColor} onChange={setUPnLPositiveColor} />
            <Tooltip title="Reset to Default Color">
              <IconButton onClick={() => setUPnLPositiveColor(DefaultUPnLPositive)} >
                <RestartAltRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      {showUPnLNegativeColorPicker && (
        <div className={styles.colorPickerOverlayBg} onClick={() => setShowUPnLNegativeColorPicker((prev) => !prev)}>
          <div onClick={handleClick}>
            <HexColorPicker color={UPnLNegativeColor} onChange={setUPnLNegativeColor} />
            <HexColorInput color={UPnLNegativeColor} onChange={setUPnLNegativeColor} />
            <Tooltip title="Reset to Default Color">
              <IconButton onClick={() => setUPnLNegativeColor(DefaultUPnLNegative)} >
                <RestartAltRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </Box>

  );
};

export default MiscSettings;
