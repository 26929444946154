import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ordercard.module.scss';
import { useSymbol } from '../../../../contexts/SymbolContext';
import { OrderPromptType, useOrders, calculatePnl } from '../../../../contexts/OrdersContext';
import { Box, MenuItem, TextField } from '@mui/material';
import { OrderType, UserContractModel, TradingAccountStatus } from '../../../../api/userApi';
import { roundToNearestTick, roundToTickSize } from 'src/helpers/decimalHelper';
import ContractSelector from '@/components/topstep/contractSelector';
import { LinkedColor } from '@/contexts/LinkedContext';
import CommonOrderOptions from '@/components/topstep/commonOrderOptions';
import { QuoteData } from '@/models/quoteData';
import { useCqg } from '@/contexts/CqgContext';
import { useSettings } from '@contexts/SettingsContext';
import { Settings } from '@mui/icons-material';
import { TsModal } from '@components/modal';
import DomHeaderSettings from '@components/domHeaderSettings';
import AccountSelector from '../../../../components/topstep/accountSelector';
import { ContractBoundary, DebouncedButton } from '@/components/styledComponents';
import { ContractPriceSpan } from '@/components/numbers';
import { formatContractPrice } from '@/helpers/formatter';
import { useDeviceContext } from '@/contexts/DeviceContext';
import FractionPriceInput from '@/views/trader/components/ordercard/fractionPriceInput';
import KeyboardListener from '@/components/topstep/keyboardListener';
import { toast } from 'react-toastify';
import { HotkeyActions } from '@/components/topstep/hotkeySettings';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { useMobile } from '@/contexts/MobileContext';

interface OrderCardProps {
  changed: () => void;
  tabData: {
    symbol: string;
    linkedColor?: LinkedColor;
    orderQuantity?: number;
    orderType?: OrderType;
    callbacks?: {
      onLinkedColorChange?: (color: LinkedColor) => void;
      onHotkeyEnabledChange?: (enabled: boolean) => void;
    };
    hotkeysEnabled?: boolean;
  };
}

const Styles = {
  contractStyle: {
    marginLeft: '0',
    marginRight: '0'
  }
};

const OrderCard: React.FC<OrderCardProps> = ({ changed, tabData }): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { placeOrderWithSymbol, activePositions, editSltpSetting } = useOrders();
  const { getContractByProductId, getDefaultContract } = useSymbol();
  const { mobileContract, setMobileContract } = useMobile();
  const { subscribeQuotesForSymbol, unsubscribeQuotesForSymbol } = useCqg();
  const { customSettings } = useSettings();
  const { activeTradingAccount } = useTradingAccount();

  const [contract, setContract] = useState<UserContractModel | null>(isMobile ? mobileContract : getContractByProductId(tabData?.symbol) || getDefaultContract());
  const [linkedColor, setLinkedColor] = useState<LinkedColor>(tabData.linkedColor);
  const [limitPrice, setLimitPrice] = useState<number | null>(null);
  const [stopPrice, setStopPrice] = useState<number | null>(null);
  const [fixedLimitPrice, setFixedLimitPrice] = useState<number | null>(null);
  const [fixedStopPrice, setFixedStopPrice] = useState<number | null>(null);
  const [trailDistance, setTrailDistance] = useState<number | null>(null);
  const [orderType, setOrderType] = useState<OrderType>(tabData.orderType || OrderType.Market);
  const [orderAmount, setOrderAmount] = useState<number>(tabData.orderQuantity || 1);
  const [quoteData, setQuoteData] = useState<QuoteData>(undefined);
  const [prevLastPrice, setPrevLastPrice] = useState<number | null>(null);
  const [showFlash, setShowFlash] = useState<string>('');
  const [domSettingsOpen, setDomSettingsOpen] = useState<boolean>(false);
  const [hotkeysEnabled, setHotkeysEnabled] = useState<boolean>(tabData.hotkeysEnabled ?? false);

  const hasViolation = useMemo(() => {
    return !(activeTradingAccount.status === TradingAccountStatus.Active || activeTradingAccount.status === TradingAccountStatus.Ineligible);
  }, [activeTradingAccount.status]);

  const canTrade = useMemo(() => {
    return activeTradingAccount.isFollower !== true && !hasViolation;
  }, [activeTradingAccount.isFollower, hasViolation]);

  const handleSetOrderAmount = useCallback(
    (amount: number) => {
      setOrderAmount(amount);

      if (isMobile) return;
      tabData.orderQuantity = amount;
      changed();
    },
    [isMobile, tabData, changed]
  );

  useEffect(() => {
    if (!contract) return;

    let initialQuote = true;
    const id = subscribeQuotesForSymbol(contract.productId, (quote) => {
      setQuoteData((prev) => {
        if (initialQuote && quote.lastPrice) {
          setLimitPrice(quote.lastPrice);
          setStopPrice(quote.lastPrice);
          setFixedLimitPrice(quote.lastPrice);
          setFixedStopPrice(quote.lastPrice);
          initialQuote = false;
        }
        return { ...prev, ...quote };
      });
    });

    return () => {
      setQuoteData(undefined);
      unsubscribeQuotesForSymbol(contract.productId, id);
    };
  }, [contract, subscribeQuotesForSymbol, unsubscribeQuotesForSymbol]);

  useEffect(() => {
    if (!tabData.callbacks) {
      tabData.callbacks = {
        onLinkedColorChange: setLinkedColor,
        onHotkeyEnabledChange: setHotkeysEnabled
      };
    } else {
      tabData.callbacks.onLinkedColorChange = setLinkedColor;
      tabData.callbacks.onHotkeyEnabledChange = setHotkeysEnabled;
    }

    setOrderAmount(tabData.orderQuantity || 1);
    setOrderType(tabData.orderType || OrderType.Market);
  }, [tabData]);

  useEffect(() => {
    if (tabData.symbol !== contract?.productId) {
      tabData.symbol = contract?.productId;
      changed();
    }
  }, [contract, tabData, changed]);

  useEffect(() => {
    tabData.linkedColor = linkedColor;
  }, [linkedColor, tabData]);

  useEffect(() => {
    tabData.orderType = orderType;
  }, [orderType, tabData]);

  useEffect(() => {
    if (orderAmount < 1) {
      setOrderAmount(1);
    }
    tabData.orderQuantity = orderAmount;
  }, [orderAmount, tabData]);

  useEffect(() => {
    if (isMobile && contract) {
      setMobileContract(contract);
    }
  }, [contract, isMobile, setMobileContract]);

  useEffect(() => {
    if (!isMobile) return;
    const savedOrderAmount = localStorage.getItem('mobile-order-amount');
    if (savedOrderAmount) {
      setOrderAmount(parseInt(savedOrderAmount));
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    localStorage.setItem('mobile-order-amount', orderAmount.toString());
  }, [orderAmount, isMobile]);

  useEffect(() => {
    setLinkedColor(tabData.linkedColor);
  }, [tabData.linkedColor]);

  useEffect(() => {
    setHotkeysEnabled(tabData.hotkeysEnabled);
  }, [tabData.hotkeysEnabled]);

  const activePosition = useMemo(() => {
    if (!contract) return null;
    const activePosList = activePositions.filter((y) => y.symbolId === contract.productId);
    return activePosList.length > 0 ? activePosList[0] : null;
  }, [activePositions, contract]);

  const positionEntryPrice = useMemo(() => {
    if (!activePosition || !contract) return 0;
    return roundToNearestTick(activePosition.averagePrice, contract);
  }, [activePosition, contract]);

  const updateTrailDistance = useCallback((distance?: number) => {
    if (distance == null) {
      setTrailDistance(null);
      return;
    }

    const clampedDistance = Math.max(1, Math.min(1000, distance));
    setTrailDistance(clampedDistance);
  }, []);

  const updateFixedLimitPrice = useCallback(() => {
    if (!contract) return;

    if (limitPrice === null) {
      setFixedLimitPrice(null);
      setLimitPrice(null);
      return;
    }

    const roundedPrice = roundToTickSize(limitPrice, contract.tickSize);
    setLimitPrice(roundedPrice);
    setFixedLimitPrice(roundedPrice);
  }, [contract, limitPrice]);

  const updateFixedStopPrice = useCallback(() => {
    if (!contract) return;

    if (stopPrice === null) {
      setFixedStopPrice(null);
      setStopPrice(null);
      return;
    }

    const roundedPrice = roundToTickSize(stopPrice, contract.tickSize);
    setStopPrice(roundedPrice);
    setFixedStopPrice(roundedPrice);
  }, [contract, stopPrice]);

  const breakEven = useCallback(() => {
    if (activePosition) {
      editSltpSetting(activePosition.id, positionEntryPrice, activePosition.takeProfit);
    } else {
      toast.error('No active position');
    }
  }, [activePosition, positionEntryPrice, editSltpSetting]);

  const handleSell = useCallback(() => {
    if (!contract) return;

    return placeOrderWithSymbol({
      amount: orderAmount,
      symbol: contract.productId,
      orderType: OrderPromptType.Sell,
      limitPrice: fixedLimitPrice,
      stopPrice: fixedStopPrice,
      trailDistance: trailDistance,
      type: orderType
    });
  }, [orderAmount, contract, fixedLimitPrice, fixedStopPrice, trailDistance, orderType, placeOrderWithSymbol]);

  const handleBuy = useCallback(() => {
    if (!contract) return;

    return placeOrderWithSymbol({
      amount: orderAmount,
      symbol: contract.productId,
      orderType: OrderPromptType.Buy,
      limitPrice: fixedLimitPrice,
      stopPrice: fixedStopPrice,
      trailDistance: trailDistance,
      type: orderType
    });
  }, [orderAmount, contract, fixedLimitPrice, fixedStopPrice, trailDistance, orderType, placeOrderWithSymbol]);

  const onKeyboardMarketBuy = useCallback(() => {
    setOrderType(OrderType.Market);
    handleBuy();
  }, [handleBuy]);

  const onKeyboardMarketSell = useCallback(() => {
    setOrderType(OrderType.Market);
    handleSell();
  }, [handleSell]);

  const onKeyboardIncreaseOrderAmount = useCallback(() => {
    handleSetOrderAmount(orderAmount + 1);
  }, [orderAmount, handleSetOrderAmount]);

  const onKeyboardDecreaseOrderAmount = useCallback(() => {
    if (orderAmount === 1) return;
    handleSetOrderAmount(orderAmount - 1);
  }, [orderAmount, handleSetOrderAmount]);

  const showPnl = useMemo(() => {
    if (!quoteData?.lastPrice || !activePosition || !contract) return null;
    return calculatePnl(activePosition.positionSize, activePosition.averagePrice, quoteData.lastPrice, contract.pointValue);
  }, [activePosition, quoteData?.lastPrice, contract]);

  const handleKeyboardBreakEven = useCallback(() => {
    if (!contract) {
      toast.error('No contract selected');
      return;
    }

    if (!activePosition) {
      toast.error('No active position');
      return;
    }

    if (showPnl < 0) {
      toast.error(
        <span>
          <p>
            {activePosition.positionSize < 0 ? '+' : '-'}
            {Math.abs(activePosition.positionSize)} {contract.productName} Stop Market Rejected!
          </p>
          <p>Breakeven order not available</p>
        </span>
      );
      return;
    }

    breakEven();
  }, [contract, activePosition, showPnl, breakEven]);

  const rejectHotkeyFollower = useCallback(() => {
    toast.error(
      <span>
        <p>
          <b>Hotkey Rejected!</b>
          <br />
          No trading on Trade Copier Follower Account
        </p>
      </span>
    );
  }, []);

  useEffect(() => {
    if (prevLastPrice === null || !quoteData?.lastPrice) return;

    let timerId;

    if (quoteData.lastPrice > prevLastPrice) {
      timerId = setTimeout(() => {
        setShowFlash('#43984d');
        setTimeout(() => setShowFlash(''), 1000);
      }, 0);
    } else if (quoteData.lastPrice < prevLastPrice) {
      timerId = setTimeout(() => {
        setShowFlash('#bd3f3b');
        setTimeout(() => setShowFlash(''), 1000);
      }, 0);
    }

    setPrevLastPrice(quoteData.lastPrice);

    return () => clearTimeout(timerId);
  }, [quoteData?.lastPrice, prevLastPrice]);

  const canPlaceOrder = useMemo(() => {
    if (orderType === OrderType.Limit && limitPrice === null) return false;
    if (orderType === OrderType.Stop && stopPrice === null) return false;
    if (orderType === OrderType.TrailingStop && trailDistance === null) return false;
    return true;
  }, [orderType, limitPrice, stopPrice, trailDistance]);

  const priceText = useMemo(() => {
    if (!contract) return '';

    let price = 0;
    switch (orderType) {
      case OrderType.Market:
        return '@ Market';
      case OrderType.Limit:
        if (limitPrice === null) return '@ ???';
        price = roundToTickSize(limitPrice, contract.tickSize);
        break;
      case OrderType.Stop:
        if (stopPrice === null) return '@ ???';
        price = roundToTickSize(stopPrice, contract.tickSize);
        break;
      case OrderType.TrailingStop:
        return 'Trailing Stop';
      default:
        price = quoteData?.lastPrice ?? 0;
    }

    return '@ ' + formatContractPrice(price, contract);
  }, [orderType, limitPrice, stopPrice, quoteData, contract]);

  const toggleDomSettings = useCallback(() => {
    setDomSettingsOpen((prev) => !prev);
  }, []);

  const customFontSize = useMemo(() => {
    const sizes = {
      0: 10,
      1: 12,
      2: 14
    };
    return { fontSize: sizes[customSettings.domFontSize] || 12 };
  }, [customSettings.domFontSize]);

  const bindings = useMemo(
    () => [
      {
        keys: customSettings.hotkeyBindings?.[HotkeyActions.BuyMarket],
        onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardMarketBuy
      },
      {
        keys: customSettings.hotkeyBindings?.[HotkeyActions.SellMarket],
        onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardMarketSell
      },
      {
        keys: customSettings.hotkeyBindings?.[HotkeyActions.ContractSizeUp],
        onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardIncreaseOrderAmount
      },
      {
        keys: customSettings.hotkeyBindings?.[HotkeyActions.ContractSizeDown],
        onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : onKeyboardDecreaseOrderAmount
      },
      {
        keys: customSettings.hotkeyBindings?.[HotkeyActions.BreakEven],
        onKeyboard: activeTradingAccount.isFollower ? rejectHotkeyFollower : handleKeyboardBreakEven
      }
    ],
    [customSettings.hotkeyBindings, activeTradingAccount.isFollower, rejectHotkeyFollower, onKeyboardMarketBuy, onKeyboardMarketSell, onKeyboardIncreaseOrderAmount, onKeyboardDecreaseOrderAmount, handleKeyboardBreakEven]
  );

  const accountSelect = useMemo(
    () => (
      <div className={styles.accountCont}>
        <AccountSelector style={{ maxWidth: '23.5em' }} />
      </div>
    ),
    []
  );

  const tickSize = contract?.tickSize ?? 1;
  const fractionalPrice = contract?.fractionalPrice ?? false;

  const flashStyle = {
    transition: 'color 0.1s ease-in-out'
  };

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.order} style={{ background: isMobile && '#1c1e23' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0.1em 0',
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flex: 1 }}>
            <ContractSelector contract={contract} style={Styles.contractStyle} setContract={setContract} linkedColor={linkedColor} />
          </Box>
          <Box>
            <div style={{ cursor: 'pointer' }} onClick={toggleDomSettings} data-intercom-target='dom-settings'>
              <Settings style={{ marginLeft: '.3em' }} />
            </div>
          </Box>
        </div>
        <ContractBoundary contract={contract}>
          <Box>
            <TextField size='small' fullWidth onChange={(e) => setOrderType(parseInt(e.target.value) as OrderType)} value={orderType} select label='Order Type'>
              <MenuItem value={OrderType.Market} key={OrderType.Market} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Market
              </MenuItem>
              <MenuItem value={OrderType.Limit} key={OrderType.Limit} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Limit
              </MenuItem>
              <MenuItem value={OrderType.Stop} key={OrderType.Stop} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Stop Market
              </MenuItem>
              <MenuItem value={OrderType.TrailingStop} key={OrderType.TrailingStop} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Trailing Stop
              </MenuItem>
            </TextField>
          </Box>

          {orderType === OrderType.Limit && (
            <Box sx={{ marginTop: '1em' }}>
              {fractionalPrice ? (
                <FractionPriceInput contract={contract} value={limitPrice} onChange={setLimitPrice} onBlur={updateFixedLimitPrice} />
              ) : (
                <TextField
                  label='Limit Price'
                  fullWidth
                  required
                  variant='outlined'
                  size='small'
                  value={limitPrice || ''}
                  type='number'
                  InputProps={{ inputProps: { min: 0, step: tickSize + '' } }}
                  onChange={(e) => setLimitPrice(e.target.value ? parseFloat(e.target.value) : null)}
                  onBlur={updateFixedLimitPrice}
                />
              )}
            </Box>
          )}

          {orderType === OrderType.Stop && (
            <Box sx={{ marginTop: '1em' }}>
              {fractionalPrice ? (
                <FractionPriceInput contract={contract} value={stopPrice} onChange={setStopPrice} onBlur={updateFixedStopPrice} />
              ) : (
                <TextField
                  label='Stop Price'
                  fullWidth
                  required
                  variant='outlined'
                  size='small'
                  value={stopPrice || ''}
                  type='number'
                  InputProps={{ inputProps: { min: 0, step: tickSize + '' } }}
                  onChange={(e) => setStopPrice(e.target.value ? parseFloat(e.target.value) : null)}
                  onBlur={updateFixedStopPrice}
                />
              )}
            </Box>
          )}

          {orderType === OrderType.TrailingStop && (
            <Box sx={{ marginTop: '1em' }}>
              <TextField
                label='Trail Distance (Ticks)'
                fullWidth
                required
                size='small'
                variant='outlined'
                value={trailDistance || ''}
                type='number'
                InputProps={{ inputProps: { min: 1, max: 1000, step: 1 } }}
                onChange={(e) => updateTrailDistance(e.target.value ? parseInt(e.target.value) : null)}
              />
            </Box>
          )}

          <Box style={{ marginTop: '1em' }}>
            <TextField label='# of Contracts' fullWidth variant='outlined' size='small' value={orderAmount} type='number' InputProps={{ inputProps: { min: 1, step: '1' } }} onChange={(v) => setOrderAmount(parseInt(v.target.value) || 1)} />
          </Box>

          {orderType === OrderType.Market && (
            <Box style={{ fontSize: customFontSize.fontSize }}>
              <div className={styles.marketOrderPriceContainer}>
                <div className={styles.marketOrderPriceContainerBB}>
                  Bid: <ContractPriceSpan contract={contract}>{quoteData?.bestBid ?? 0}</ContractPriceSpan>
                </div>
                <div style={{ display: 'block', marginLeft: 5, marginRight: 5 }}>
                  <div className={styles.marketOrderPriceContainerLP} style={{ color: showFlash, ...flashStyle }}>
                    <ContractPriceSpan contract={contract}>{quoteData?.lastPrice ?? 0}</ContractPriceSpan>
                  </div>
                  <div className={styles.marketOrderPriceContainerSpread}>
                    <ContractPriceSpan contract={contract}>{(quoteData?.bestAsk ?? 0) - (quoteData?.bestBid ?? 0)}</ContractPriceSpan>
                  </div>
                </div>
                <div className={styles.marketOrderPriceContainerBA}>
                  Ask: <ContractPriceSpan contract={contract}>{quoteData?.bestAsk ?? 0}</ContractPriceSpan>
                </div>
              </div>
            </Box>
          )}

          {!isMobile && accountSelect}

          <Box
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              fontSize: customFontSize.fontSize,
              height: '3em'
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                display: 'block',
                flexWrap: 'wrap',
                alignItems: 'center',
                textAlign: 'center',
                margin: '0.25em 0'
              }}
            >
              {activePosition && activePosition.positionSize !== 0 ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ marginRight: 10 }}>
                    {activePosition.positionSize > 0 ? '+' + activePosition.positionSize : activePosition.positionSize} @ <ContractPriceSpan contract={contract}>{activePosition.averagePrice}</ContractPriceSpan>
                  </div>
                  <div style={{ marginRight: 10 }}>{showPnl !== null && <span style={{ color: showPnl < 0 ? '#bd3f3b' : '#43984d' }}>UP&L ${showPnl.toFixed(2)}</span>}</div>
                  <div style={{ marginRight: 10 }}>
                    <DebouncedButton color='neutral' disabled={showPnl < 0 || !canTrade} onClick={breakEven} sx={{ padding: '2px 4px', margin: '4px', fontSize: '.9em', minWidth: 20 }}>
                      B/E
                    </DebouncedButton>
                  </div>
                </div>
              ) : (
                <div>No Active Position</div>
              )}
            </div>
          </Box>

          <CommonOrderOptions
            hotkeysEnabled={hotkeysEnabled}
            contract={contract}
            onBuy={handleBuy}
            onSell={handleSell}
            setSize={handleSetOrderAmount}
            size={orderAmount}
            priceText={priceText}
            hideBuySellButton={customSettings.domHeaderBuySellButtonHide}
            hideJoinButton={customSettings.domHeaderJoinButtonHide}
            disableBuySell={!canPlaceOrder}
            hideCancelAll={customSettings.domHeaderCancelAllButtonHide}
            hideCancelOrders={customSettings.domHeaderCancelOrdersButtonHide}
            hideClosePosition={customSettings.domHeaderClosePositionButtonHide}
            hideFlattenAll={customSettings.domHeaderFlattenAllButtonHide}
            hideReversePosition={customSettings.domHeaderReversePositionButtonHide}
          />
        </ContractBoundary>

        <TsModal open={domSettingsOpen} onClose={() => setDomSettingsOpen(false)}>
          <DomHeaderSettings onSaved={() => setDomSettingsOpen(false)} onCancelled={() => setDomSettingsOpen(false)} />
        </TsModal>
      </div>

      <KeyboardListener enabled={hotkeysEnabled} bindings={bindings} />
    </div>
  );
};

export default React.memo(OrderCard);
